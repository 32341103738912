import React, { useContext, useEffect, useRef, useState } from 'react';
import cl from 'classnames';

import { useOutletContext } from 'react-router';
import { useAppSelector } from '@app/hooks/use-store';
import { useProcessSlideMutation } from '@app/store/api/slide.api';
import { useToastContext } from '@app/hooks/use-toast-provider';

import useSendOptionsBuild from '../../../hooks/use-send-options';
import { ONBOARDING_MESSAGE, ONBOARDING_TYPE } from '../../create/data/onboarding.data';
import { OnboardingMessage } from '../../../components/template';
import { svgIcons } from '../../create/data/svg-icons.data';
import t from '../../../..//lib/lng';
import { SlideEditContent } from '../project-id.page';

import SlideDraftEditor from './slide-draft-editor.block';
import SlideMenu from './slide-menu.block';
import { analytics } from '@app/app/analytics';

const LANG = {
  switch_to_advance_mode: 'Switch to advanced mode',
  limit_content_text: 'Character limit exceeded, please shorten your text',
  edit_text_with_ai: 'Edit text with AI ',
  design_this_slide: 'Design this slide',
  design_this_slide_tooltip: 'First add content to the slide',
  placeholder: 'Add a slide content',
  files_drag: 'Browse files or drag here',
  file_max_size: 'Up to three jpg, png or gif files, maximum 10 MB each',
};

const SlideDraftModeBlock = (props) => {
  const {
    slideIndex,
    addRef,
    draftInfo,
    isNewSlide = false,
    themeOption,
    projectId,
    toggleEditMode,
    noClone,
    noDelete,
    isFirstGeneration,
    setLastOperation,
  } = props;

  const attachedImages = { thumbs: [] };
  const { getToast } = useToastContext();
  const attachedVisuals = [];
  const maxTextLength = 1500;
  const showMaxTextAfter = 1000;
  const { presentationQuery, refetch: presRefetch } = useContext(SlideEditContent);
  const { onboardingMessage } = useOutletContext();
  const optionsBuilder = useSendOptionsBuild();
  const [currentOnboardingKey] = onboardingMessage(ONBOARDING_TYPE);
  const { isGlobalRequest } = useAppSelector((state) => state.commonState);

  const [processSlideMutation] = useProcessSlideMutation();

  const rawTextEditorDupRef = useRef(null);
  const rawTextEditorRef = useRef(null);
  const onboardingPermissionRef = useRef(null);

  const [isAdvanceMode, setAdvanceMode] = useState(true); //ai_text, advanced
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [slideText, setSlideText] = useState(draftInfo);

  useEffect(() => {
    setSlideText(draftInfo);
  }, [draftInfo]);

  // function designThisHandler(formatted) {
  //   if (!isGlobalRequest) {
  //     changeOnboardingKey({ freeze: 6, step: 6 });
  //     let text = '';
  //     if (formatted) {
  //       const markedText = {};
  //       let currentContentTitle = null;
  //       editorState.forEach((item) => {
  //         if (item.type !== 'unordered-list-item') currentContentTitle = null;
  //         if (item.type === 'header-one') markedText.title = item.text;
  //         if (item.type === 'header-two') markedText.subtitle = item.text;
  //         if (item.type === 'header-six') markedText.note = item.text;
  //         if (item.type === 'header-three') {
  //           if (!markedText.contenttitle) markedText.contenttitle = [];
  //           if (!markedText.group_bullet) markedText.group_bullet = [];
  //           markedText.contenttitle.push(item.text);
  //           currentContentTitle = markedText.contenttitle.length - 1;
  //           markedText.group_bullet[currentContentTitle] = [];
  //         }
  //         if (item.type === 'unordered-list-item') {
  //           if (currentContentTitle !== null) {
  //             markedText.group_bullet[currentContentTitle].push(item.text);
  //           } else {
  //             if (!markedText.bullet) markedText.bullet = [];
  //             markedText.bullet.push(item.text);
  //           }
  //         }
  //       });
  //       text = markedText;
  //     } else {
  //       text = slideText;
  //     }
  //     // TODO: create slide generation here // UPDATED
  //     //
  //     //dispatch(changeMode({ slideIndex: slideIdx, mode: 'wait' }));
  //     // props.generateOneSlide(text, slideIdx);
  //     // props.changeMode(slideIdx, 'result');
  //     // setEditorState([]);
  //   }
  // }

  const onAttachImgs = (e) => {
    const files = e.target.files;
    let validFiles = true;
    Array.from(files).forEach((file) => {
      if (!file.type.match(/jpeg|png/)) {
        getToast({
          type: 'error',
          msg: t.weSupportOnlyJpgPng,
        });
        validFiles = false;
      }
    });
    if (validFiles) {
      //props.addVisual('image', props.slideIdx, files);
      setShowImageUpload(false);
    }
  };
  function convertMarkedDraftText(markedText) {
    const convertListKeys = {
      'header-one': 'title',
      'header-two': 'subtitle',
      'header-six': 'note',
    };
    return Object.values(markedText).reduce((markedResult = {}, markedItem) => {
      if (convertListKeys?.[markedItem.type]) {
        markedResult[convertListKeys[markedItem.type]] = markedItem.text;
      } else {
        if (markedItem.type === 'header-three') {
          markedResult['contenttitle'] = markedResult?.contenttitle
            ? [...markedResult['contenttitle'], markedItem.text]
            : [markedItem.text];
          if (markedResult['contenttitle'].length === 1) {
            markedResult['group_bullet'] = [];
            markedResult['group_bullet'][0] = markedResult['bullet'] ?? [];
            delete markedResult['bullet'];
          } else if (markedResult['contenttitle'].length > 1) {
            markedResult['group_bullet'].push([]);
          }
        } else {
          if (markedResult?.group_bullet) {
            markedResult.group_bullet[markedResult.group_bullet.length - 1].push(markedItem.text);
          } else {
            if (!markedResult?.bullet) markedResult['bullet'] = [];
            markedResult.bullet.push(markedItem.text);
          }
        }
      }
      return markedResult;
    }, {});
  }
  function generateSlide() {
    if (isFirstGeneration) {
      analytics.emitEvent('scratch_generation_first_slide_start', {
        GTM: {
          processed_file_id: +projectId,
        },
        Amplitude: {
          'processed file id': +projectId,
        },
      });
    } else {
      analytics.emitEvent('generation_slide_start', {
        GTM: {
          slide_number: slideIndex + 1,
          processed_file_id: +projectId,
        },
        Amplitude: {
          'slide number': slideIndex + 1,
          'processed file id': +projectId,
          'interaction location': isAdvanceMode ? 'advanced mode' : 'raw mode',
        },
      });
    }

    const {
      font: { value: font },
      logo,
      color_value: color,
      shift,
      shape_value: shape,
    } = themeOption;
    const formData = optionsBuilder({
      slide: {
        text: isAdvanceMode
          ? convertMarkedDraftText(slideText?.marked_text || {})
          : (slideText?.source_text ?? ''),
        to: slideIndex,
      },
      setup: { font, logo, shift, color, shape },
    });
    processSlideMutation({ projectId, body: formData })
      .unwrap()
      .then((processSlideResponse) => {
        if (processSlideResponse.status) {
          presRefetch()
            .unwrap()
            .then((updatedPresentation) => {
              if (updatedPresentation.status && updatedPresentation.result) {
                const errorStatus =
                  Object.values(updatedPresentation.result.queue?.[slideIndex])?.reduce(
                    (resultStatus, currentError) => {
                      if (currentError?.error?.message) resultStatus = 'error_status_occurred';
                      return resultStatus;
                    },
                    'error_status_missing',
                  ) ?? 'error_status_missing';
                const warningStatus = updatedPresentation.result.warning[slideIndex].length
                  ? 'warning_status_occurred'
                  : 'warning_status_missing';
                if (isFirstGeneration) {
                  analytics.emitEvent('scratch_generation_first_slide_done', {
                    GTM: {
                      processed_file_id: +projectId,
                      slide_number: +slideIndex + 1,
                      // TODO: Check for valid payload when TS
                      generation_warning_status: warningStatus,
                      generation_error_status: errorStatus,
                    },
                    Amplitude: {
                      'processed file id': +projectId,
                      'interaction location': isAdvanceMode ? 'advanced mode' : 'raw mode',
                      'slide number': +slideIndex + 1,
                      // TODO: Check for valid payload when TS
                      'slide error names': updatedPresentation.result.errors,
                      'slide warning names': updatedPresentation.result.warning[slideIndex],
                      // TODO: Add
                      'interface tip': '',
                      'tip status': '',
                    },
                  });
                } else {
                  analytics.emitEvent('generation_slide_done', {
                    GTM: {
                      slide_number: slideIndex + 1,
                      generation_error_status: errorStatus,
                      generation_warning_status: warningStatus,
                      processed_file_id: +projectId,
                    },
                    Amplitude: {
                      'processed file id': +projectId,
                      'interaction location': isAdvanceMode ? 'advanced mode' : 'raw mode',
                      'slide number': +slideIndex + 1,
                      // Check for valid type when TS
                      'slide error names': updatedPresentation.result.errors,
                      'slide warning names': updatedPresentation.result.warning[slideIndex],
                    },
                  });
                }

                presentationQuery(processSlideResponse.result);
                setLastOperation('generate');
              } else {
                presentationQuery(processSlideResponse.result);
              }
              toggleEditMode?.();
            });
        } else {
          throw {
            error: processSlideResponse.errors,
            httpCode: '500',
          };
        }
      });

    // TODO: This code was commented a long time ago. Check!
    // if (setup.promptOnSlide.number && slideIndex === '0') {
    //     let promptOnSlide = {...setup.promptOnSlide};
    //     promptOnSlide.isChanged = isTextChanged(text, prompts[promptOnSlide.number - 1]);
    //     dispatchSetup({promptOnSlide});
    // }
  }
  function formatTextHandler(text, mode) {
    switch (mode) {
      case 'toAdvance': {
        const advanceText = text.split('\n').reduce(
          (result, stroke, strokeIndex) => {
            if (strokeIndex === 0) result['title'] = stroke;
            else result['bullet'].push(stroke);
            return result;
          },
          { title: '', bullet: [] },
        );
        setSlideText((prevState) => ({
          ...prevState,
          marked_text: advanceText,
          source_text: text,
        }));
        break;
      }
      case 'toPlain': {
        if (typeof text === 'object') {
          const plainText = Object.values(text).reduce((line, stroke, strokeIndex) => {
            return `${line} ${strokeIndex === 0 ? stroke.text : `\n ${stroke.text}`}`;
          }, '');
          setSlideText((prevState) => ({
            ...prevState,
            marked_text: text,
            source_text: plainText,
          }));
        }
        break;
      }
    }
  }
  return (
    <>
      <div
        className="create_slide"
        ref={(n) => {
          addRef(slideIndex, n);
        }}
      >
        <div className="create_slide_header">
          <div className="create_slide_number">
            {t.slide} {Number(slideIndex + 1)}
            <SlideMenu
              slideIndex={slideIndex}
              projectId={projectId}
              noDelete={draftInfo && noDelete}
              noClone={draftInfo && noClone}
            />
          </div>
          <div
            className="create_slide_switch about_plain_link"
            onClick={() => {
              setAdvanceMode((prevState) => !prevState);
              if (isNewSlide) {
                // TODO: No more Amplitude / Just leaving trigger here
                analytics.emitEvent('edit_slide_advanced_mode_switch', {});
              }
            }}
          >
            {isAdvanceMode ? LANG.edit_text_with_ai : LANG.switch_to_advance_mode}{' '}
            {svgIcons.arrowRight}
          </div>
          {draftInfo?.source_text?.length >= showMaxTextAfter && (
            <div
              className={cl(
                'create_slide_max_text',
                // slide.text.length >= maxTextLength ? ' error' : '',
              )}
            >
              {draftInfo?.source_text?.length}/{maxTextLength}
              {draftInfo?.source_text?.length >= maxTextLength && ' ' + LANG.limit_content_text}
            </div>
          )}
        </div>
        <div className="create_slide_inner" ref={onboardingPermissionRef}>
          {isAdvanceMode ? (
            <div className="create_slide_scroll_wrap">
              <SlideDraftEditor
                themeOption={themeOption}
                slideText={slideText?.marked_text || {}}
                onChangeText={formatTextHandler}
                onboardingPermissionRef={onboardingPermissionRef}
              />
            </div>
          ) : (
            <div className="create_slide_scroll_wrap">
              <textarea
                className="create_slide_text create_slide_text-d"
                value={slideText?.source_text ?? ''}
                ref={rawTextEditorDupRef}
                placeholder="Enter your slide content"
                readOnly={true}
              ></textarea>
              <textarea
                className="create_slide_text"
                value={slideText?.source_text ?? ''}
                autoFocus={true}
                placeholder="Enter your slide content"
                ref={rawTextEditorRef}
                onChange={(e) => {
                  let val = e.target.value;
                  if (val.length > maxTextLength) {
                    val = val.substring(0, maxTextLength);
                  }
                  formatTextHandler(val, 'toAdvance');
                }}
              ></textarea>
              {showImageUpload && (
                <label className="create_slide__image_upload squares_block">
                  <input
                    type="file"
                    className="gen_upload__input"
                    multiple={true}
                    onChange={onAttachImgs}
                    accept=".jpg,.png"
                    onDragLeave={() => {
                      props.onDragLeaveHandler();
                    }}
                    onDrop={() => {
                      props.onDragLeaveHandler();
                    }}
                  />
                  <div className="title">{LANG.files_drag}</div>
                  <div className="text">{LANG.file_max_size}</div>
                  <div className="squares_block__inner"></div>
                  <div
                    className="create_slide_img_del"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setShowImageUpload(false);
                    }}
                  >
                    {svgIcons.delete2}
                  </div>
                </label>
              )}
              {attachedImages.thumbs.length > 0 && (
                <div className="create_slide_imgs">
                  {attachedImages.thumbs.map((image, i) => (
                    <div className="create_slide_img" key={i}>
                      <img src={image.url} />
                      <div
                        className="create_slide_img_del"
                        onClick={() => {
                          props.deleteVisual(props.slideIdx, 'image', image.image_id);
                        }}
                      >
                        {svgIcons.delete2}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {attachedVisuals.length > 0 && (
                <div className="create_slide__table_wrap">
                  {attachedVisuals.map((item, i) => (
                    <div className="create_slide__table squares_block" key={i}>
                      Add a {item.visual_type} later in Power Point
                      <div className="squares_block__inner"></div>
                      <div
                        className="create_slide_img_del"
                        onClick={() => {
                          props.deleteVisual(slideIndex, item.visual_type, item.visual_id);
                        }}
                      >
                        {svgIcons.delete2}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        <OnboardingMessage
          boardId={5}
          isActivate={currentOnboardingKey()}
          isOptional={true}
          zIndex={Number(currentOnboardingKey()) === 5 ? '1' : '0'}
          ignoreRef={[onboardingPermissionRef]}
          {...ONBOARDING_MESSAGE}
        >
          <div
            className={cl(
              'create_slide_submit gen_btn_main z-0',
              isGlobalRequest && 'opacity-20',
              slideText?.source_text?.length ? '' : ' hint disabled',
            )}
            onClick={() => generateSlide()}
          >
            <svg width="25" height="24" viewBox="0 0 25 24">
              <path d="M11.5001 4L11.0001 3L10.5001 4L9.50006 4.125L10.3341 4.833L10.0001 6L11.0001 5.334L12.0001 6L11.6661 4.833L12.5001 4.125L11.5001 4ZM19.8341 14.666L19.0001 13L18.1661 14.666L16.5001 14.875L17.8891 16.056L17.3341 18L19.0001 16.889L20.6661 18L20.1111 16.056L21.5001 14.875L19.8341 14.666ZM7.16706 6.333L6.50006 5L5.83306 6.333L4.50006 6.5L5.61106 7.444L5.16706 9L6.50006 8.111L7.83306 9L7.38906 7.444L8.50006 6.5L7.16706 6.333ZM3.91406 17C3.91406 17.534 4.12206 18.036 4.50006 18.414L6.08606 20C6.46406 20.378 6.96606 20.586 7.50006 20.586C8.03406 20.586 8.53606 20.378 8.91406 20L20.5001 8.414C20.8781 8.036 21.0861 7.534 21.0861 7C21.0861 6.466 20.8781 5.964 20.5001 5.586L18.9141 4C18.1581 3.244 16.8421 3.244 16.0861 4L4.50006 15.586C4.12206 15.964 3.91406 16.466 3.91406 17ZM17.5001 5.414L19.0861 7L15.5001 10.586L13.9141 9L17.5001 5.414Z" />
            </svg>
            {LANG.design_this_slide}
            {!slideText?.length && (
              <div className="hint_text">{LANG.design_this_slide_tooltip}</div>
            )}
          </div>
        </OnboardingMessage>
      </div>
    </>
  );
};

export default SlideDraftModeBlock;
