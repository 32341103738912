import React from 'react';
import { Link } from 'react-router-dom';

import t from '../../../lib/lng';
import { route_path } from '../../utils/route_path';

const LegalPage = () => {
  return (
    <div className="legal_content">
      <h1 className="legal_menu_title">{t.Legal}</h1>
      <div className="legal_menu">
        <Link to={route_path.legal_terms} className="legal_menu_item ">
          {t.TermsOfUse}
        </Link>
        <Link to={route_path.legal_privacy} className="legal_menu_item">
          {t.PrivacyPolicy}
        </Link>
        <Link to={route_path.legal_cookie} className="legal_menu_item">
          {t.CookiePolicy}
        </Link>
      </div>
    </div>
  );
};

export default LegalPage;
