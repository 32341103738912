import { contrast } from '@lib/utils';

import { rgbaToHex } from '@uiw/react-color';

import { Dispatch, SetStateAction } from 'react';

import { UserData } from '@app/types/user.type';

import {
  DemoFileDownloadStatus,
  FileDownloadStatus,
  FileInteractionLocation,
} from '@app/app/analytics/types';

import { analytics } from '@app/app/analytics';

import t from '../../lib/lng';
import { themeColors } from '../data';
import { setGlobalRequest } from '../store/slice/common.slice';
import {
  useLazyDownloadPresentationPingQuery,
  useLazyDownloadPresentationQuery,
  useLazyLoadPresentationQuery,
} from '../store/api/presentation.api';
import { useRemakeSlideDesignMutation } from '../store/api/slide.api';
import { Presentation } from '../types/presentation.type';

import { useAppDispatch } from './use-store';

type DownloadHandlerParams = {
  retry?: number;
  ignore_code?: boolean;
  presentation: Presentation['result'];
  interactionLocation: FileInteractionLocation;
};

type SaveFileParams = {
  presentation: Presentation['result'];
  retry?: number;
  ignoreTrial?: boolean;
  interactionLocation: FileInteractionLocation;
};

export type DownloadStatus = 'success' | 'error' | 'redirect';

const downloadStatusMap: Record<DownloadStatus, FileDownloadStatus> = {
  success: 'file_download_success',
  error: 'file_download_error',
  redirect: 'file_download_redirect',
};
const downloadDemoStatusMap: Record<DownloadStatus, DemoFileDownloadStatus> = {
  success: 'demo_file_download_success',
  error: 'demo_file_download_error',
  redirect: 'demo_file_download_redirect',
};

type DownloadTagManagerParams = {
  isDemo: boolean;
  status: DownloadStatus;
  interactionLocation: FileInteractionLocation;
};

type DownloadHookParams = {
  projectId: number;
  getToast: (sysMessageOptions: {
    systemMessage: {
      msg: string;
      endButtons?: {
        background: string;
        text: string;
        onClick: ({ setShow }: { setShow: (status: boolean) => void }) => void;
      }[];
    };
  }) => void;
  setTrialPopup: Dispatch<
    SetStateAction<{ type: string; callBack: () => void; onClose: () => void }>
  >;
  setResendNotify: Dispatch<SetStateAction<boolean>>;
  context: UserData;
  setDownloadDisabled?: Dispatch<SetStateAction<boolean>>;
};

const DOWNLOAD_PROBLEMS_MESSAGES = [
  {
    msg: 'Please, wait a little longer',
    msg4: 'The generation is taking slightly longer than usual, but it will be ready soon',
    type: 'warning',
    autoclose: 10000,
  },
  {
    msg: 'Check your email!',
    msg4: "To save your time, we'll email you the completed presentation after generation done",
    type: 'warning',
    autoclose: 10000,
  },
];

const RESULT_DOWNLOAD_WITHOUT_POPUP: { [key: string]: string } = { FAIL_DOWNLOAD: 'FAIL_DOWNLOAD' };
const RESULT_DOWNLOAD_STATUS: { [key: string]: string } = {
  USER_NOT_CONFIRMED: 'USER_NOT_CONFIRMED',
  INVALID_CONSTRAINTS: 'INVALID_CONSTRAINTS',
  EMPTY_BALANCE: 'EMPTY_BALANCE',
};
const RESULT_TRIAL_POPUP_TYPE: { [key: string]: string } = {
  USER_NOT_CONFIRMED: 'download expired',
  INVALID_CONSTRAINTS: 'download expired',
  IS_TRIAL: 'download trial',
  EMPTY_BALANCE: 'download expired',
};

// TODO: Refactor
const downloadTagManager = ({ status, isDemo, interactionLocation }: DownloadTagManagerParams) => {
  return isDemo
    ? analytics.emitEvent('demo_file_download', {
        GTM: {
          file_download_status: downloadDemoStatusMap[status],
          file_interaction_location: interactionLocation,
          redirect_place: status == 'redirect' ? 'confirm_email' : undefined,
        },
      })
    : analytics.emitEvent('file_download', {
        GTM: {
          file_download_status: downloadStatusMap[status],
          file_interaction_location: interactionLocation,
          redirect_place: status == 'redirect' ? 'confirm_email' : undefined,
        },
      });
};

export function usePresentationDownload(params: DownloadHookParams) {
  const { setDownloadDisabled, context, setResendNotify, setTrialPopup, getToast } = params;

  const dispatch = useAppDispatch();
  const [reloadPresentationQuery] = useLazyLoadPresentationQuery();
  const [remakeSlideDesignMutation] = useRemakeSlideDesignMutation();
  const [downloadPresentationPing] = useLazyDownloadPresentationPingQuery();
  const [downloadPresentationQuery] = useLazyDownloadPresentationQuery();
  function stopDownloading() {
    dispatch(setGlobalRequest({ requesting: false }));
    setDownloadDisabled?.(false);
  }
  function downloadHandler(downloadParams: DownloadHandlerParams) {
    const { presentation, retry = 0, ignore_code = false, interactionLocation } = downloadParams;

    if (presentation) {
      const projectId = presentation.images_info.project_id;

      // TODO: Add demo check
      const isDemo = false;

      dispatch(setGlobalRequest({ requesting: true }));

      if (retry === 0) {
        if (isDemo) {
          analytics.emitEvent('click_demo_file_download', {
            GTM: {
              file_interaction_location: interactionLocation,
            },
          });
          analytics.emitEvent('file_interactions', {
            GTM: {
              interaction_name: 'demo_file_download',
              file_interaction_location: interactionLocation,
            },
          });
        } else {
          analytics.emitEvent('click_file_download', {
            GTM: { file_interaction_location: interactionLocation },
          });
          analytics.emitEvent('file_interactions', {
            GTM: {
              interaction_name: 'file_download',
              file_interaction_location: interactionLocation,
            },
          });
        }
      }

      if (!context?.user_ctx?.is_confirmed) {
        setResendNotify(true);
        stopDownloading();
        downloadTagManager({ status: 'redirect', isDemo, interactionLocation });
        return;
      }

      if (context?.product_balance?.product?.is_trial && !ignore_code) {
        stopDownloading();
        setTrialPopup({
          type: RESULT_TRIAL_POPUP_TYPE.IS_TRIAL,
          callBack: () => downloadHandler({ ...downloadParams, retry: 1, ignore_code: true }),
          onClose: () => {
            stopDownloading();
          },
        });
        return;
      }

      analytics.emitEvent('file_click_download', {
        Amplitude: {
          'processed file id': projectId,
        },
      });

      downloadPresentationQuery({ projectId })
        .unwrap()
        .then((downloadResponse) => {
          setDownloadDisabled?.(true);
          const isExistError = Object.values(RESULT_DOWNLOAD_STATUS).includes(
            downloadResponse.result?.code,
          );
          if (isExistError) {
            setDownloadDisabled?.(false);
            const showPopupError = () =>
              getToast({ systemMessage: { msg: 'Error downloading presentation' } });
            dispatch(setGlobalRequest({ requesting: false }));
            if (downloadResponse.result?.code === RESULT_DOWNLOAD_STATUS.FAIL_DOWNLOAD) {
              getToast({ systemMessage: { msg: 'Error downloading presentation' } });
              dispatch(setGlobalRequest({ requesting: false }));
              downloadTagManager({ status: 'error', isDemo, interactionLocation });
              return;
            }
            if (downloadResponse.result?.code === RESULT_DOWNLOAD_STATUS.INVALID_CONSTRAINTS) {
              if (retry > 3) {
                showPopupError();
                return;
              }
              const formData = new FormData();
              formData.append('action_type', 'remake_presentation');
              formData.append(
                'wonder_file_id',
                String(presentation?.remake.slide?.[0].wonder_file_id),
              );
              const ratioWhite = +contrast(
                  [255, 255, 255],
                  presentation.process.option[0].color_value,
                ).toFixed(2),
                ratioBlack = +contrast(
                  [0, 0, 0],
                  presentation.process.option[0].color_value,
                ).toFixed(2);
              let textColor = [];

              if (ratioWhite > 2) textColor.push('FFFFFF');
              if (ratioBlack > 4.2) textColor.push('000000');
              if (ratioWhite <= 2 && ratioBlack <= 4.2) {
                if (ratioWhite >= ratioBlack) textColor.push('FFFFFF');
                else textColor.push('000000');
              }
              const rgbKeys = presentation.process.option[0].color_value;
              const rgbFromOptionColor = {
                r: rgbKeys[0],
                g: rgbKeys[1],
                b: rgbKeys[2],
                a: 100,
              };
              if (
                themeColors.includes(rgbaToHex(rgbFromOptionColor).toLowerCase()) ||
                (rgbaToHex(rgbFromOptionColor).toLowerCase() === 'ed2626' && textColor.length > 1)
              )
                textColor = ['FFFFFF'];
              formData.append('text_color', JSON.stringify(textColor));
              formData.append('text_color', String(['FFFFFF', '000000']));
              remakeSlideDesignMutation({
                params: { projectId },
                body: formData,
              })
                .unwrap()
                .then((remakePresentationResult) => {
                  if (remakePresentationResult.status) {
                    downloadHandler({ ...downloadParams, retry: retry + 1 });
                    reloadPresentationQuery({ projectId: String(projectId) });
                  } else showPopupError();
                });
              return;
            }
            if (downloadResponse.result?.code === RESULT_DOWNLOAD_STATUS.USER_NOT_CONFIRMED) {
              setResendNotify(true);
              downloadTagManager({ status: 'redirect', isDemo, interactionLocation });
              return;
            }
            setTrialPopup({
              type: RESULT_TRIAL_POPUP_TYPE[downloadResponse.result?.code],
              callBack: () => downloadHandler({ ...downloadParams, retry: 1, ignore_code: true }),
              onClose: () => {
                dispatch(setGlobalRequest({ requesting: false }));
                setDownloadDisabled?.(false);
              },
            });
            downloadTagManager({ status: 'error', isDemo, interactionLocation });
            return;
          }
          if (downloadResponse.status) {
            saveFile({ presentation, interactionLocation });
          } else {
            stopDownloading();
          }
        })
        .catch((error) => {
          console.error('[DOWNLOAD ERROR]: ', error);
          getToast({ systemMessage: { msg: t.oopsSomethingBroke } });
          stopDownloading();
          downloadTagManager({ status: 'error', isDemo, interactionLocation });
        });
    } else {
      console.error('UNKNOWN PRESENTATION OR NOT INITIALIZED');
    }
  }

  function saveFile(saveFileParams: SaveFileParams) {
    const { retry = 1, presentation, interactionLocation } = saveFileParams;

    if (presentation) {
      const projectId = presentation.images_info.project_id;
      downloadPresentationPing({ projectId })
        .unwrap()
        .then((response) => {
          if (response?.result?.code) {
            if (response?.result.code === RESULT_DOWNLOAD_WITHOUT_POPUP.FAIL_DOWNLOAD) {
              getToast({ systemMessage: { msg: t.oopsSomethingBroke } });
              stopDownloading();
            }
            stopDownloading();
            return (
              RESULT_TRIAL_POPUP_TYPE?.[response.result.code] &&
              setTrialPopup({
                type: RESULT_TRIAL_POPUP_TYPE[response.result.code],
                callBack: () =>
                  downloadHandler({
                    presentation,
                    retry: 1,
                    ignore_code: true,
                    interactionLocation,
                  }),
                onClose: () => {
                  stopDownloading();
                },
              })
            );
          }
          if (
            response?.type ===
            'application/vnd.openxmlformats-officedocument.presentationml.presentation'
          ) {
            const fileName = decodeURIComponent(String(presentation.process?.file_info?.filename));
            const file = new File([response], fileName, {
              type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            });
            const link = document.createElement('a');
            const url = URL.createObjectURL(file);
            link.href = url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            dispatch(setGlobalRequest({ requesting: false }));

            // TODO: Add demo check
            const isDemo = false;

            downloadTagManager({
              status: 'success',
              isDemo,
              interactionLocation,
            });
            stopDownloading();
            return;
          }
          if (response?.result) {
            dispatch(setGlobalRequest({ requesting: false }));
            setDownloadDisabled?.(false);
          } else {
            const modalInfoButtonStyle = [
              {
                background: '#F7F7FC',
                text: 'Okay',
                onClick: ({ setShow }: { setShow: (status: boolean) => void }) => setShow(false),
              },
            ];
            if (retry === 5) {
              getToast({
                systemMessage: {
                  ...DOWNLOAD_PROBLEMS_MESSAGES[0],
                  endButtons: modalInfoButtonStyle,
                },
              });
            }
            if (retry === 10) {
              getToast({
                systemMessage: {
                  ...DOWNLOAD_PROBLEMS_MESSAGES[1],
                  endButtons: modalInfoButtonStyle,
                },
              });
              stopDownloading();
              return;
            }
            setTimeout(
              () =>
                saveFile({
                  ignoreTrial: false,
                  presentation,
                  retry: retry + 1,
                  interactionLocation,
                }),
              2000,
            );
          }
        })
        .catch((error) => {
          console.error('[ERROR DOWNLOAD PING]:', error);
          stopDownloading();
        });
      //     .finally(() => {
      //     stopDownloading();
      // });
    } else {
      console.error('[PRESENTATION NOT INITIALIZED]');
    }
  }
  return { downloadHandler, saveFile };
}
