import React, { useEffect, useRef, useState } from 'react';
import { convertToRaw, RichUtils } from 'draft-js';

import { OnboardingMessage } from '../../../components/template';

const ONBOARDING_MESSAGE_EDIT_TEXT = {
  title: 'Structure your text',
  description:
    'Transform your content with headers, subheaders, bullet points, and footnotes for the perfect design fit.',
  className: 'onboarding_message__edit_block',
  position: 'vertical',
  alignmentX: 100,
  alignment: 187,
  xPosition: 'center',
  yPosition: 'bottom',
  onBoardType: 'presentation',
  customWidth: 280,
};

const Toolbar = ({
  editorState,
  applyStyle,
  constraints,
  singleBulletsCount,
  ignoreOnboardingRefArray,
  onboardingMessage,
}) => {
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const dropdownDOM = useRef(null);

  useEffect(() => {
    const closeOnClick = (e) => {
      if (e.target.closest('.cpanel_dropdown') !== dropdownDOM.current) {
        setDropdownOpened(false);
      }
    };
    window.addEventListener('click', closeOnClick);

    return () => window.removeEventListener('click', closeOnClick);
  }, []);

  useEffect(() => {
    if (
      dropdownOpened &&
      dropdownDOM.current.querySelector('.cpanel_dropdown__items').getBoundingClientRect().bottom >
        window.innerHeight
    ) {
      dropdownDOM.current.querySelector('.cpanel_dropdown__items').scrollIntoView(false);
    }
  }, [dropdownOpened]);

  const isActive = (style, method) => {
    if (method === 'block') {
      const selection = editorState.getSelection();
      const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
      return blockType === style;
    } else {
      const currentStyle = editorState.getCurrentInlineStyle();
      return currentStyle.has(style);
    }
  };

  const currentTypeCaption = () => {
    const blockTypes = {
      'header-one': 'Title',
      'header-two': 'Subtitle',
      'header-three': 'Heading',
    };
    const currentBlockType = RichUtils.getCurrentBlockType(editorState);
    return blockTypes[currentBlockType] || 'Style';
  };

  const isDisabled = (type) => {
    const contentState = convertToRaw(editorState.getCurrentContent());
    const blockType = RichUtils.getCurrentBlockType(editorState);
    if (type === 'unordered-list-item' && constraints && constraints?.single_bullet) {
      if (blockType !== type && singleBulletsCount >= constraints?.single_bullet) return true;
    } else if (type === 'header-three') {
      if (
        blockType !== type &&
        contentState.blocks.filter((i) => i.type === type).length >= constraints?.group_bullet
      )
        return true;
    } else {
      if (blockType !== type && contentState.blocks.some((i) => i.type === type)) return true;
    }
  };

  return (
    <OnboardingMessage
      boardId={9}
      ignoreRef={ignoreOnboardingRefArray}
      isActivate={onboardingMessage(ONBOARDING_MESSAGE_EDIT_TEXT.onBoardType)[0]()}
      onNext={() =>
        onboardingMessage(ONBOARDING_MESSAGE_EDIT_TEXT.onBoardType)[1]({ freeze: 6, step: 10 })
      }
      {...ONBOARDING_MESSAGE_EDIT_TEXT}
    >
      <div className="cpanel editor_toolbar">
        <div
          className={'cpanel_item cpanel_dropdown' + (dropdownOpened ? ' opened' : '')}
          onClick={() => {
            setDropdownOpened(!dropdownOpened);
          }}
          ref={dropdownDOM}
        >
          <div
            className={
              'cpanel_dropdown__caption' + (currentTypeCaption() !== 'Style' ? ' active' : '')
            }
          >
            {currentTypeCaption()}
          </div>
          <svg width="12" height="8" viewBox="0 0 12 8" fill="none">
            <path d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z" />
          </svg>
          {dropdownOpened && (
            <div className="cpanel_dropdown__items">
              <button
                className={
                  'cpanel_dropdown__item' +
                  (isActive('header-one', 'block') ? ' active' : '') +
                  (isDisabled('header-one') ? ' exists hint' : '')
                }
                onClick={(e) => !isDisabled('header-one') && applyStyle(e, 'header-one')}
                onMouseDown={(e) => e.preventDefault()}
              >
                Title
                {isDisabled('header-one') && (
                  <div className="hint_text hint_text-arrow">Please use the existing title</div>
                )}
              </button>
              <button
                className={
                  'cpanel_dropdown__item' +
                  (isActive('header-two', 'block') ? ' active' : '') +
                  (isDisabled('header-two') ? ' exists hint' : '')
                }
                onClick={(e) => !isDisabled('header-two') && applyStyle(e, 'header-two')}
                onMouseDown={(e) => e.preventDefault()}
              >
                {isDisabled('header-two') && (
                  <div className="hint_text hint_text-arrow">Please use the existing subtitle</div>
                )}
                Subtitle
              </button>
              <button
                className={
                  'cpanel_dropdown__item' +
                  (isActive('header-three', 'block') ? ' active' : '') +
                  (isDisabled('header-three') ? ' exists hint' : '')
                }
                onClick={(e) => !isDisabled('header-three') && applyStyle(e, 'header-three')}
                onMouseDown={(e) => e.preventDefault()}
              >
                {isDisabled('header-three') && (
                  <div className="hint_text hint_text-arrow">
                    Maximum bullet groups: {constraints.group_bullet}
                  </div>
                )}
                Heading
              </button>
            </div>
          )}
        </div>
        <button
          className={
            'cpanel_item hint' +
            (isActive('unordered-list-item', 'block') ? ' active' : '') +
            (isDisabled('unordered-list-item') ? ' exists' : '')
          }
          onClick={(e) =>
            !isDisabled('unordered-list-item') && applyStyle(e, 'unordered-list-item')
          }
          onMouseDown={(e) => e.preventDefault()}
        >
          {isDisabled('unordered-list-item') && (
            <div className="hint_text hint_text-arrow">
              Maximum bullets: {constraints?.single_bullet}
            </div>
          )}
          {!isDisabled('unordered-list-item') && (
            <div className="hint_text hint_text-arrow">Bullet</div>
          )}
          <svg width="16" height="12" viewBox="0 0 16 12" fill="none">
            <path d="M0 0H2V2H0V0ZM0 5H2V7H0V5ZM0 10H2V12H0V10ZM16 2V0H4.023V2H14.8H16ZM4 5H16V7H4V5ZM4 10H16V12H4V10Z" />
          </svg>
        </button>
        <button
          className={
            'cpanel_item hint' +
            (isActive('header-six', 'block') ? ' active' : '') +
            (isDisabled('header-six') ? ' exists' : '')
          }
          onClick={(e) => !isDisabled('header-six') && applyStyle(e, 'header-six')}
          onMouseDown={(e) => e.preventDefault()}
        >
          {isDisabled('header-six') && (
            <div className="hint_text hint_text-arrow">Please use the existing footnote</div>
          )}
          {!isDisabled('header-six') && <div className="hint_text hint_text-arrow">Footnote</div>}
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M16 6.72L15.2253 4.34526L9.24632 6.29895V0H6.75368V6.29895L0.774737 4.34526L0 6.72L5.97895 8.67368L2.29053 13.76L4.31158 15.2253L8 10.1389L11.6884 15.2253L13.7095 13.76L10.0211 8.67368L16 6.72Z" />
          </svg>
        </button>
      </div>
    </OnboardingMessage>
  );
};

export default Toolbar;
