import React, { memo } from 'react';

type Context = {
  value: string;
  options: { font: Array<{ face: string; value: string }> };
  preset: { font: Array<{ face: string; value: string }> };
};

const PreloadFonts = memo(function PreloadFonts({ context }: { context: Context }) {
  return (
    <span style={{ opacity: 0 }}>
      {context.preset &&
        context.preset.font &&
        context.preset.font.map((item, i) => {
          if (item.face != 'regular') return;
          return <span style={{ fontFamily: item.value }} key={i} />;
        })}
      {context?.options?.font.map((item: { value: string }, i: number) => (
        <span style={{ fontFamily: item.value == 'Exo 2' ? 'Exo-2' : item.value }} key={i} />
      ))}
    </span>
  );
});

export default PreloadFonts;
