import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Icon, Modal } from '../../ui';
import { useClickOutside } from '../../../hooks/use-click-outside';
import CongratulationsModal from '../congratulations-modal/congratulations-modal';
import { useLocation, useNavigate } from 'react-router-dom';
import { route_path } from '../../../utils/route_path';

const ONBOARDING_DEFAULTS = {
  position: 'horizontal',
  alignment: 146,
  alignmentY: '100%',
  nextBtn: 'Next',
  xPosition: 'left',
  yPosition: 'bottom',
  wait: 'wait...',
  modal: {
    title: 'Are you sure?',
    description_text:
      "If you skip the onboarding, you will miss out on unlocking Wonderslide's full power",
    delete: 'Yes, skip',
    cancel: 'Cancel',
  },
};

const CONGRATULATIONS_MODAL = {
  title: 'Congratulations!',
  description: `<span><span style='display: block; padding-bottom: 8px'>Congratulations!</span>
               <span style='display: block; padding-bottom: 8px'>You have mastered the magic of Wonderslide</span>
               <span>Now, it's time to spread your wings and create <br/> your own masterpiece</span>
                </span>
                `,
  btnText: 'Create design!',
};

const AMPLITUDE_TYPE_NAME = {
  presentation: 'first_time onboarding_bubbles',
  share: 'sharing onboarding_bubbles',
  pin_share: 'sharing onboarding_bubbles',
  success_info: 'non_applicable',
};

const OnboardingMessage = (props) => {
  const {
    children,
    title,
    boardId,
    xPosition = ONBOARDING_DEFAULTS.xPosition,
    yPosition = ONBOARDING_DEFAULTS.yPosition,
    onNext,
    onFinish,
    isActivate,
    position = ONBOARDING_DEFAULTS.position,
    description,
    alignment = ONBOARDING_DEFAULTS.alignment,
    alignmentX,
    isFullWidthChild = false,
    onClick,
    className,
    parentStyle = {},
    childrenStyle,
    forceShow = true,
    zIndex = 1,
    ignoreRef = [],
    isOptional = true,
    nowrapDisabled = false,
    onBoardType,
    finishText = 'Finish',
    nextText = ONBOARDING_DEFAULTS.nextBtn,
    isGlobalFinish = false,
    onBoardTypeActive,
    isNoCongratulate = false,
    isShowCongratulationForce = false,
    isFinishDisabled = false,
    customWidth,
    onClose,
    isScrollIntoView = false,
    withoutCancel = false,
  } = props;
  const refBoard = useRef(null);
  const navigate = useNavigate();
  const [isFinish, setIsFinish] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(true);
  const [isDisableOnboarding, setIdDisableOnboarding] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const isCurrentBoardActive = Number(isActivate) === Number(boardId);
  const alignmentForSide = alignmentX ? `${alignmentX}px` : ONBOARDING_DEFAULTS.alignmentY;
  const isActiveType = (onBoardTypeActive ?? onBoardType) === onBoardType;
  const location = useLocation();
  const positionList = {
    horizontal: {
      center: { left: alignmentForSide },
      edge: { left: `calc(50% - ${alignment}px)` },
      top: { top: `calc(50% - ${alignment}px)` },
      left: { left: alignmentForSide },
      bottom: { bottom: `calc(50% - ${alignment}px)` },
      right: { right: alignmentForSide },
    },
    vertical: {
      center: { left: `calc(50% - ${alignment}px)` },
      edge: { right: `calc(100% - ${alignment}px)` },
      top: { top: alignmentForSide },
      left: { left: `calc(50% - ${alignment}px)` },
      bottom: { bottom: alignmentForSide },
      right: { right: `calc(50% - ${alignment}px)` },
    },
  };
  useClickOutside([...ignoreRef, refBoard], (event) => {
    if (
      isCurrentBoardActive &&
      !isOptional &&
      localStorage.getItem(`${onBoardType}_process`) !== 'finish'
    ) {
      setIdDisableOnboarding(true);
      ignoreRef?.[0]?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  });
  function onBtnClick(event) {
    setIsClicked(true);
    onNext?.(event);
  }
  if (
    isCurrentBoardActive &&
    onFinish &&
    localStorage.getItem(`${onBoardType}_first_show`) !== '1' &&
    localStorage.getItem(`${onBoardType}_process`) !== 'finish'
  ) {
    // TODO: Implement
    // amplitude.getInstance().logEvent('onboarding_bubbles end', {
    //     'end type': 'onboarding_bubbles finish',
    //     'onboarding name': AMPLITUDE_TYPE_NAME[onBoardType],
    //     'step number': AMPLITUDE_TYPE_NAME.success_info,
    //     'step name': AMPLITUDE_TYPE_NAME.success_info,
    // });
    localStorage.setItem(`${onBoardType}_first_show`, '1');
  }
  function closeDisableOnboardingHandler() {
    setIdDisableOnboarding(false);
  }
  function finishHandler() {
    onFinish?.();
    isShowCongratulationForce && setIsFinish(true);
  }
  function endOnboardingHandler() {
    // TODO: Implement
    // localStorage.getItem(`${onBoardType}_first_show`) !== '1'
    // && amplitude.getInstance().logEvent('onboarding_bubbles end', {
    //  'end type': 'onboarding_bubbles skip',
    //  'onboarding name': AMPLITUDE_TYPE_NAME[onBoardType],
    //  'step number': boardId,
    //  'step name': title ?? description
    // });
    localStorage.setItem(`${onBoardType}_process`, 'finish');
    setShowOnboarding(false);
  }
  function successFinishHandler() {
    setIsFinish(false);
    localStorage.setItem(`${onBoardType}_process`, 'finish');
    navigate(route_path.create);
  }
  const isShow = showOnboarding && isCurrentBoardActive && forceShow && isActiveType;
  useEffect(() => {
    if (refBoard?.current && isShow && isScrollIntoView) {
      refBoard.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [isShow]);
  useEffect(() => {
    return () => {
      if (
        localStorage.getItem(`${onBoardType}_first_show`) === '1' &&
        typeof onFinish !== 'undefined' &&
        localStorage.getItem(`${onBoardType}_process`) !== 'finish'
      ) {
        localStorage.setItem(`${onBoardType}_process`, 'finish');
      }
    };
  }, [location.href]);
  const currentPositionStyle = {
    ...positionList[position][xPosition],
    ...positionList[position][yPosition],
  };
  if (localStorage.getItem(`${onBoardType}_process`) === 'finish') return <>{children}</>;
  if (!isShow && nowrapDisabled) return <>{children}</>;
  return (
    <>
      <div
        ref={refBoard}
        className={`onboarding_message ${isFullWidthChild ? 'onboarding_message__full_width' : ''} ${className ?? ''}`}
        style={{ ...parentStyle, ...(zIndex ? { zIndex: zIndex } : { zIndex: 1 }) }}
      >
        <div
          onClick={(event) => (isGlobalFinish ? finishHandler?.(event) : onClick?.())}
          style={childrenStyle}
        >
          {children}
        </div>
        {isShow && (
          <div className="onboarding_message__container" style={currentPositionStyle}>
            <div
              className={`onboarding_message__wrapper onboarding_message__wrapper__${position}__${xPosition}__${yPosition}`}
              style={customWidth ? { width: `${customWidth}px` } : {}}
            >
              <div
                className="onboarding_message__close"
                onClick={() =>
                  withoutCancel
                    ? endOnboardingHandler()
                    : (onClose?.() ?? setIdDisableOnboarding(true))
                }
              >
                <Icon name="X2Icon" size={8} />
              </div>
              <h3 className="onboarding_message__title">{title}</h3>
              <p className="onboarding_message__description">{description}</p>
              <div className="onboarding_message__buttons">
                {onNext && (
                  <div className="onboarding_message__btn">
                    <Button
                      id={`${boardId}_onboard_message`}
                      size="small"
                      onClick={(event) => onBtnClick(event)}
                      label={isClicked ? ONBOARDING_DEFAULTS.wait : nextText}
                    />
                  </div>
                )}
                {onFinish && (
                  <div className="onboarding_message__btn">
                    <Button
                      size="small"
                      isDisabled={isFinishDisabled}
                      onClick={(event) => finishHandler(event)}
                      label={isClicked ? ONBOARDING_DEFAULTS.wait : finishText}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {isShowCongratulationForce || (isFinish && !isNoCongratulate) ? (
        <CongratulationsModal
          onClick={successFinishHandler}
          onCancel={successFinishHandler}
          {...CONGRATULATIONS_MODAL}
        />
      ) : (
        <></>
      )}
      {isCurrentBoardActive && (
        <Modal isOpen={isDisableOnboarding} onClose={closeDisableOnboardingHandler}>
          <div className="onboarding_message__modal">
            <h3>{ONBOARDING_DEFAULTS.modal.title}</h3>
            <p>{ONBOARDING_DEFAULTS.modal.description_text}</p>
            <div className="onboarding_message__modal_buttons">
              <Button
                rounded="middle"
                variant="outline"
                size="extra_small"
                onClick={endOnboardingHandler}
                label={ONBOARDING_DEFAULTS.modal.delete}
                isFullWidth
              />
              <Button
                rounded="middle"
                size="extra_small"
                onClick={closeDisableOnboardingHandler}
                label={ONBOARDING_DEFAULTS.modal.cancel}
                isFullWidth
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

OnboardingMessage.prototype = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['horizontal', 'vertical']),
  isEnabled: PropTypes.bool,
  alignment: PropTypes.number,
  onNext: PropTypes.func,
  onFinish: PropTypes.func,
  boardId: PropTypes.string,
  isActive: PropTypes.string,
  xPosition: PropTypes.oneOf(['left', 'right', 'edge', 'center']),
  yPosition: PropTypes.oneOf(['top', 'bottom']),
  /* make onboard div inner to width: 100% */
  isFullWidthChild: PropTypes.bool,
  onClick: PropTypes.func,
  parentStyle: PropTypes.object,
  childrenStyle: PropTypes.string,
  className: PropTypes.string,
  ignoreRef: PropTypes.array,
  forceHidden: PropTypes.bool,
  zIndex: PropTypes.number,
  isOptional: PropTypes.bool,
  /* don't show onboarding then it no active */
  nowrapDisabled: PropTypes.bool,
  onBoardType: PropTypes.string,
  onBoardTypeActive: PropTypes.string,
  finishText: PropTypes.string,
  nextText: PropTypes.string,
  isNoCongratulate: PropTypes.bool,
  isGlobalFinish: PropTypes.bool,
  /* force show congratulations */
  isShowCongratulationForce: PropTypes.bool,
  /* disable button onFinish */
  isFinishDisabled: PropTypes.bool,
  /* custom bubble width  */
  customWidth: PropTypes.number,
  /* change action after click x button */
  onClose: PropTypes.func,
  /* scroll to bubble default false */
  isScrollIntoView: PropTypes.bool,
  /* don't show modal if x is clicked default: false */
  withoutCancel: PropTypes.bool,
};

export default OnboardingMessage;
