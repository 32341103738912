import React, { useState, useEffect, useRef } from 'react';

const Dropdown = ({ className, name, items, isForcedOpen = false }) => {
	const [isOpen, setIsOpen] = useState(isForcedOpen ?? false);
	const elementDOM = useRef(null);

	useEffect(() => {
		const closeOnClick = (e) =>  {
			if (e.target.closest('.dropdown') != elementDOM.current) {
				setIsOpen(false);
			}
		}
		window.addEventListener('click', closeOnClick);

		return () => window.removeEventListener('click', closeOnClick);
	}, []);

	useEffect(() => {
		const options = elementDOM.current.querySelector('.dropdown_items');
		if (!options) return;
		if (isOpen && options.getBoundingClientRect().bottom > window.innerHeight) {
			options.scrollIntoView(false);
		}
	});

	return(
		<div className={'dropdown' + (isOpen || isForcedOpen ? ' open' : '') + className} ref={elementDOM} onClick={() => {setIsOpen((prevState) => isForcedOpen || !prevState)}}>
			<div className="dropdown_name btn-outlined">
				{name}
				<svg width="13" height="8" viewBox="0 0 13 8" fill="none"><path d="M10.793 0.292969L6.49997 4.58597L2.20697 0.292969L0.792969 1.70697L6.49997 7.41397L12.207 1.70697L10.793 0.292969Z" /></svg>
			</div>
			{
				(isOpen || isForcedOpen) &&
				<div className="dropdown_items">
					{items.map((item, i) => 
						item && <div className={'dropdown_item' + (item.disabled ? ' disabled' : '')} key={i} onClick={item.onClick}>{item.name}</div>
					)}
				</div>
			}
		</div>
	);
}


export default Dropdown;