import { FC } from 'react';

import { UploadIcon } from '../icons';

interface UploadLogoItemProps {
  onClick: () => void;
}

export const UploadLogoItem: FC<UploadLogoItemProps> = ({ onClick }) => {
  return (
    <div
      className="text-secondary_success_SI_500_success bg-general_background_MIII_600_dark w-[72px] h-[40px] flex items-center justify-center rounded"
      onClick={onClick}
    >
      <UploadIcon width={24} height={24} />
    </div>
  );
};
