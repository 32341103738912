import React from 'react';

const XIcon = ({size}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 4l8.516 8.516M4 12.516l8.516-8.517"
            ></path>
        </svg>
    );
};

export default XIcon;