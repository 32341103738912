import { cookie } from '../lib/cookie';

import { analytics } from './analytics';
import { CustomerCategory, SignInUpMethod, SignUpUseCase } from './types';

// TODO: Need refactoring
export const registerAnalytics = {
  userRegistered: ({ method, userId }: { method: SignInUpMethod; userId: number }) => {
    let accountsOnDevice = 1;

    if (window.localStorage.getItem('accounts')) {
      const accounts = JSON.parse(window.localStorage.getItem('accounts') || '');
      if (!accounts.includes(userId)) {
        accounts.push(userId);
        window.localStorage.setItem('accounts', JSON.stringify(accounts));
      }
      accountsOnDevice = accounts.length;
    } else {
      window.localStorage.setItem('accounts', '[' + userId + ']');
    }

    // const reg = JSON.parse(window.sessionStorage.getItem('reg') || '');
    const useCase = localStorage.getItem('reg_quiz') ?? 'skip';

    localStorage.removeItem('reg_quiz');

    if (useCase !== 'skip') document.cookie = `user_usecase=${useCase}; path=/;`;

    /* 
      TODO: Add user via Amplitude Identify API:
        Check docs:
          https://docs.google.com/document/d/1s0pgnBHsJUzXmgXDjAlSFxKHD7abgXy484M5oUOij1o/edit?pli=1&tab=t.0#heading=h.ws6ojyplumtp
	*/
    analytics.emitEvent('user_data', {
      GTM: {
        user_id: userId,
        user_presentations_number: 1,
        // TODO: Implement
        customer_category: '' as CustomerCategory,
      },
    });

    analytics.emitEvent('trial_start', {
      GTM: {},
    });

    analytics.emitEvent('sign_up', {
      GTM: {
        method,
        // TODO: Fix
        usecase: useCase as SignUpUseCase,
      },
      Amplitude: {
        // 'USER ID': userId,
        accounts_on_device: accountsOnDevice,
        registration_by: method,
        // TODO: Fix
        send_marketing_email: 'allow',
      },
    });

    cookie.delete('oauth_reg', '/');
    window.sessionStorage.removeItem('reg');
    window.sessionStorage.removeItem('auth_by_google');
    window.localStorage.removeItem('ph_support_shown');
  },
};
