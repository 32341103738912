import React from 'react';

const Onboarding = ({ close }) => {
  return (
    <div className="modal modal-onboarding">
      <div className="modal_overlay" onClick={close}></div>
      <div className="onboarding">
        <div className="modal_close" onClick={close}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.192 0.343994L5.94897 4.58599L1.70697 0.343994L0.292969 1.75799L4.53497 5.99999L0.292969 10.242L1.70697 11.656L5.94897 7.41399L10.192 11.656L11.606 10.242L7.36397 5.99999L11.606 1.75799L10.192 0.343994Z"
              fill="#6E718F"
            />
          </svg>
        </div>
        <div className="onboarding_main">
          <div className="onboarding_video">
            <div className="onboarding_title" id="video">
              How to prepare a draft presentation for&nbsp;the&nbsp;best design from Wonderslide
            </div>
            <iframe
              src="https://www.youtube.com/embed/VqF1-HmHG28?&showinfo=0&rel=0"
              allowFullScreen
            ></iframe>
          </div>

          <div className="onboarding_subtitle">Don't use background images and design elements</div>
          <div className="onboarding_imgs">
            <img src="/static/i/onboarding7.webp" alt="" />
            <img src="/static/i/onboarding8.webp" alt="" />
          </div>

          <div className="onboarding_subtitle">One slide is one thought</div>
          <div className="onboarding_item">
            Do not overload the slide with long texts and a lot of graphs or tables
          </div>
          <div className="onboarding_imgs">
            <img src="/static/i/onboarding9.webp" alt="" />
            <img src="/static/i/onboarding10.webp" alt="" />
          </div>

          <div className="onboarding_subtitle">The shorter, the better!</div>
          <div className="onboarding_item">
            It is better to turn large passages of text into a list with short abstracts using
            bulleted lists
          </div>
          <div className="onboarding_imgs">
            <img src="/static/i/onboarding11.webp" alt="" />
            <img src="/static/i/onboarding12.webp" alt="" />
          </div>

          <div className="onboarding_subtitle">Down with complicated long lists!</div>
          <div className="onboarding_item">Use simple short lists without sub-items</div>
          <div className="onboarding_imgs">
            <img src="/static/i/onboarding13.webp" alt="" />
            <img src="/static/i/onboarding14.webp" alt="" />
          </div>

          <div className="onboarding_subtitle">
            Instead of screenshots of graphs and tables, add them using
            <br />
            the built-in MS PowerPoint tools.
          </div>
          <div className="onboarding_imgs">
            <img src="/static/i/onboarding15.webp" alt="" />
            <img src="/static/i/onboarding16.webp" alt="" />
          </div>

          <button className="about_btn onboarding_btn" onClick={close}>
            Get a design!
          </button>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
