import React from "react";
import PropTypes from "prop-types";

import { Icon } from "../../ui";

const RegisterTemplate = (props) => {
    const { children, authType = 'google' } = props;
    return (
        <div className="auth_wrap auth_wrap-new">
            <div className="auth_info_wrap">
                <div className="auth_info auth_info-register">
                    <img className="auth_info_img" src="/static/i/w15@2x.webp" alt="W"/>
                    <div className="auth_info_title">Design impressive<br/>presentations effortlessly</div>
                    <ul className="auth_info_list">
                        <li><Icon name="CheckIcon" size={24}/> Tailor-made templates, images, and&nbsp;icons
                            to&nbsp;match your content
                        </li>
                        <li><Icon name="CheckIcon" size={24}/> Wide-ranging customization and branding options</li>
                        <li><Icon name="CheckIcon" size={24}/> Seamless export to&nbsp;PowerPoint format</li>
                        <li><Icon name="CheckIcon" size={24}/> AI-powered content structuring to&nbsp;help amplify your
                            message
                        </li>
                    </ul>
                </div>
            </div>
            <div className={'auth auth-register' + (authType === 'email' ? ' auth-email' : '')}>
                <div className="auth_block">
                    {children}
                </div>
            </div>
        </div>
    );
};

RegisterTemplate.prototype = {
    /* wrapped ReactElement as a children */
    children: PropTypes.element,
    /* type of children template */
    authType: PropTypes.oneOf(['email', 'google']),
}

export default RegisterTemplate;