import React from 'react';

const DeleteIcon = ({ size }: { size: number }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 8 8" fill="none">
      <path
        d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94283L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DeleteIcon;
