import React from 'react';

const LoadingScreen = () => {
  return (
    <div className="w-screen h-[100vh]">
      <div className="w-screen h-screen flex items-center justify-center">
        <h1 className="text-wf/40 text-right flex flex-col gap-[3px] text-[10px]">
          <img src="/static/i/logo_white.png" className="w-[150px] animate-pulse" />
          <span className="text-[#10ac84] px-[10px]">Ideas rule the world</span>
        </h1>
      </div>
    </div>
  );
};

export default LoadingScreen;
