import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLazyCancelStripeSubscriptionQuery } from '@app/store/api/payments.api';
import { UserData } from '@app/types/user.type';
import t from '@lib/lng';

type PaymentsProps = {
  close: () => void;
  product_balance: UserData['product_balance'];
  subscriptionActive: boolean;
  updateData: (callback?: () => void | undefined) => void;
};
const Payments: FC<PaymentsProps> = (props) => {
  const { close, product_balance, updateData, subscriptionActive } = props;
  const [cancelStripeSubscription] = useLazyCancelStripeSubscriptionQuery();
  const [page, setPage] = useState('main');

  const confirm = () => {
    cancelStripeSubscription(null);
    // TODO before here was "transaction_cancel_subscription" event
    updateData();
    setPage('main');
  };

  return (
    <div className="modal">
      <div className="modal_overlay" onClick={close}></div>
      <div className="m_payments">
        <div className="modal_close" onClick={close}>
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path
              d="M17 1.5L1 17.5M1 1.5L17 17.5"
              stroke="#F8F8FB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {page === 'main' && (
          <>
            <h2 className="m_payments_title">{t.Payments}</h2>
            {product_balance?.product?.is_trial && (
              <div className="m_payments_content">
                <div className="m_payments_content_row">
                  <div className="m_payments_text">
                    {product_balance?.expire && (
                      <>
                        <span>{t.trialWillCloseOn} </span>
                        <span className="m_payments_text_date">
                          {new Date(product_balance?.expire).toLocaleDateString('en-US')}
                        </span>
                      </>
                    )}
                  </div>
                  {product_balance.expire && (
                    <div className="m_payments_btns">
                      <Link
                        className="about_btn m_payments_upgrade"
                        to="/pricing/"
                        onClick={() => {
                          close();
                        }}
                      >
                        {t.upgradeSubscription}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            )}

            {!product_balance?.product?.is_trial && subscriptionActive && (
              <div className="m_payments_content">
                <div className="m_payments_text">
                  <span>{product_balance?.product.name} </span>
                  {product_balance.expire && (
                    <>
                      <span>{t.paidUntil} </span>
                      <span className="m_payments_text_date">
                        {new Date(product_balance.expire).toLocaleDateString('en-US')}
                      </span>
                    </>
                  )}
                </div>
                {product_balance.expire && (
                  <div className="m_payments_btns">
                    <div
                      className="m_payments_alt_btn"
                      onClick={() => {
                        setPage('confirmation');
                      }}
                    >
                      {t.cancelSubscription}
                    </div>
                    <div className="about_btn" onClick={close}>
                      {t.Ok}
                    </div>
                  </div>
                )}
              </div>
            )}

            {!product_balance?.product?.is_trial && !subscriptionActive && (
              <div className="m_payments_content">
                <div className="m_payments_content_row">
                  <div className="m_payments_text">{t.noSubscription}</div>
                  <div className="m_payments_btns">
                    <Link className="about_btn m_payments_upgrade" to="/pricing/">
                      {t.upgradeSubscription}
                    </Link>
                  </div>
                </div>
                {product_balance.expire && (
                  <div className="m_payments_text">
                    <span>{t.serviceWillBeFullyOperational} </span>
                    <span className="m_payments_text_date">
                      {new Date(product_balance.expire).toLocaleDateString('en-US')}
                    </span>
                  </div>
                )}
              </div>
            )}
          </>
        )}

        {page === 'confirmation' && (
          <>
            <h2 className="m_payments_title">{t.doYouWantToCancelSubscription}</h2>
            <div className="m_payments_content m_payments_content-confirm">
              <div className="m_payments_text">
                {t.formatString(t.ifYouCancelSubscription, <br />)}{' '}
                {product_balance?.expire &&
                  new Date(product_balance.expire).toLocaleDateString('en-US')}
              </div>
              <div className="m_payments_btns">
                <div className="m_payments_alt_btn" onClick={confirm}>
                  {t.Yes}
                </div>
                <div
                  className="about_btn"
                  onClick={() => {
                    setPage('main');
                  }}
                >
                  {t.No}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Payments;
