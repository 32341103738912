import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { SystemMessageType } from '@app/types/system-message.type';

import t from '../../../lib/lng';

type InitialCommonSlice = {
  isGlobalRequest: boolean;
  setup: {
    presentationType: 'draft' | 'pptx';
    onboardingIndex: number;
    isShowCongratulationForce: boolean;
    uploading: boolean;
    uploadingProgress: number;
    uploadingFileName: string;
    fileSelected: boolean;
    firstScreenAnimation: boolean;
    resultMapAnimation: boolean;
    processing: boolean;
    processingProgress: number;
    showResult: boolean;
    pinging: boolean;
    regenPinging: boolean;
    preparingDownload: boolean;
    regenSlideId: Array<string>;
    regenSlideAction: Array<string>;
    regenSlideFailId: Array<string>;
    dragging: boolean;
    isSample: boolean;
    sampleType: string;
    slideLimitExceeded: boolean;
    projectId: string;
    options: {
      asset_theme: string;
      shift: '0';
      color: string;
      shape: 9;
      font: string;
      logo: string;
      custom_color: string;
      custom_shape: string;
      custom_font: string;
      custom_logo: string;
    };
    optionsCopy: {
      asset_theme: string;
      color: string;
      shape: number;
      font: string;
      logo: string;
    };
    newLogo: '';
    colorpickerColor: string;
    pingResult: { remake?: { url?: string } };
    successfulSlidesCount: number;
    systemMessage: SystemMessageType['systemMessage'] | null;
    systemMessageStack: Array<string>;
    showRefillNotify: boolean;
    showNotActivePlanNotify: boolean;
    showAccountConfirmedNotify: boolean;
    showResendConfirmAccountNotify: boolean;
    showTryToUploadPopup: boolean;
    showSessionTimeoutNotify: boolean;
    showUserSurvey: boolean;
    showUploadLogo: boolean;
    showSharingModal: boolean;
    showOldVersionNotify: boolean;
    oldVersionFileOperating: boolean;
    trialPopup: string | null;
    isShownConfirmNotify: boolean;
    debugInfoSlide: string | null;
    checkSavedState: boolean;
    redirectTo: string;
    isShowSettingsOut: boolean;
    showOnboarding: boolean;
    editedShape: string | null;
    editedImageShape: string | null;
    styleSaved: boolean;
    existedFilePing: boolean;
    readonly: boolean;
    preventRender: boolean;
    allPresetsByOne: boolean;
    animateSaveBtn: boolean;
    presentationName: string;
    showRenameModal: boolean;
    showDeleteModal: boolean;
    isFromScratch: boolean;
    files: Array<string>;
    analyticsGen: {};
    promptOnSlide: {
      number: number;
      isChanged: boolean;
    };
  };
};

export const initialCommonState: InitialCommonSlice = {
  isGlobalRequest: false,
  setup: {
    presentationType: 'draft',
    onboardingIndex: 1,
    isShowCongratulationForce: false,
    uploading: false,
    uploadingProgress: 0,
    uploadingFileName: '',
    fileSelected: false,
    firstScreenAnimation: true,
    resultMapAnimation: true,
    processing: false,
    processingProgress: 0,
    showResult: false,
    pinging: false,
    regenPinging: false,
    preparingDownload: false,
    regenSlideId: [],
    regenSlideAction: [],
    regenSlideFailId: [],
    dragging: false,
    isSample: false,
    sampleType: '',
    slideLimitExceeded: false,
    projectId: '',
    options: {
      asset_theme: '',
      shift: '0',
      color: '',
      shape: 9,
      font: t.AutomaticSelection,
      logo: '',
      custom_color: '',
      custom_shape: '',
      custom_font: '',
      custom_logo: '',
    },
    optionsCopy: {
      asset_theme: '',
      color: '',
      shape: 9,
      font: t.AutomaticSelection,
      logo: '',
    },
    newLogo: '',
    colorpickerColor: '',
    pingResult: {},
    successfulSlidesCount: 0,
    systemMessage: null,
    systemMessageStack: [],
    showRefillNotify: false,
    showNotActivePlanNotify: false,
    showAccountConfirmedNotify: false,
    showResendConfirmAccountNotify: false,
    showTryToUploadPopup: false,
    showSessionTimeoutNotify: false,
    showUserSurvey: false,
    showUploadLogo: false,
    showSharingModal: false,
    showOldVersionNotify: false,
    oldVersionFileOperating: false,
    trialPopup: null,
    isShownConfirmNotify: false,
    debugInfoSlide: null,
    checkSavedState: false,
    redirectTo: '',
    isShowSettingsOut: false,
    showOnboarding: false,
    editedShape: null,
    editedImageShape: null,
    styleSaved: false,
    existedFilePing: false,
    readonly: false,
    preventRender: false,
    allPresetsByOne: false,
    animateSaveBtn: false,
    presentationName: 'New presentation',
    showRenameModal: false,
    showDeleteModal: false,
    isFromScratch: false,
    files: [],
    analyticsGen: {},
    promptOnSlide: {
      number: 0,
      isChanged: false,
    },
  },
};

export const commonSlice = createSlice({
  name: 'commonSlice',
  initialState: initialCommonState,
  reducers: {
    setGlobalRequest: (state, action: PayloadAction<{ requesting: boolean }>) => {
      state.isGlobalRequest = action.payload.requesting;
    },
    updateSetup: (
      state,
      action: PayloadAction<{ [key: string]: boolean | string | number | object }>,
    ) => {
      const { payload } = action;
      state.setup = { ...state.setup, ...payload };
    },
    upgradeSetup: (state, action: PayloadAction<InitialCommonSlice['setup']>) => {
      state.setup = action.payload;
    },
    clearSetup: (state) => {
      state.setup = initialCommonState.setup;
    },
  },
});

export const { setGlobalRequest, updateSetup, upgradeSetup, clearSetup } = commonSlice.actions;

export default commonSlice.reducer;
