import React from 'react';
import PropTypes from 'prop-types';
import { Outlet, useOutletContext } from 'react-router';
import Footer from '@app/common/footer';

const WithoutHeaderLayout = () => {
  const context = useOutletContext();
  return (
    <div className="main">
      <Outlet context={context} />
    </div>
  );
};

WithoutHeaderLayout.prototype = {
  updateData: PropTypes.func,
  context: PropTypes.object,
};

export default WithoutHeaderLayout;
