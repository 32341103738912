import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { analytics, amplitudeInstance } from '@app/app/analytics';
import { SignInUpMethod } from '@app/app/analytics/types';
import { registerAnalytics } from '@app/app/analytics/register.analytics';
import { LoadingScreen, TrialPopup } from '@app/components/template';
import { route_path } from '@app/utils/route_path';
import { CommonInit, UserData, UserDataType } from '@app/types/user.type';
import {
  useGetCommonInitQuery,
  useGetThemeInitQuery,
  useGetUserInitQuery,
  useLazyLogOutQuery,
} from '@app/store/api/user.api';

import { useLazyLoadFonts } from '@app/hooks/use-lazy-load-fonts';

import { TrialType } from '@app/types/trial.type';

import { AnimatePresence, motion } from 'framer-motion';

import { AccountConfirmNotify, ResendConfirmAccountNotify } from '@app/components/template';

import Footer from '@app/common/footer';
import { cookie } from '@app/app/lib/cookie';

const ONBOARD_PRESENTATION_TYPE = 'presentation';

type PopupState = {
  type: TrialType | null;
  callback: (() => void) | null;
  callBack?: () => void;
  onClose: (() => void) | null;
};

export type CommonOutletContext = {
  user_ctx?: UserData['user_ctx'];
  updateData: UserDataType;
  logout: () => void;
  context?: UserData;
  commonContext?: CommonInit;
  updateSharedFile: (data: { [x: string]: string | number | boolean }) => void;
  onboardingMessage: (
    type: string,
    keepSteps?: boolean,
  ) => (({ step, freeze }: { step: number; freeze: number }) => void)[];
  changePresentationType: Dispatch<SetStateAction<'pptx' | 'draft'>>;
  setTrialPopup: Dispatch<SetStateAction<PopupState>>;
  setResendNotify: Dispatch<SetStateAction<boolean>>;
  setEmailNotify: Dispatch<SetStateAction<boolean>>;
  setSendConfirmNotify: Dispatch<SetStateAction<boolean>>;
};

type CommonOutletProps = {
  logout: () => void;
  updateSharedFile: (data: { [x: string]: string | number | boolean }) => void;
};

const PROTECTED_ROUTE_LIST = ['create', 'project', 'projects', 'setup-style', 'upload-draft'];

const foldSignMethod = (method: string): SignInUpMethod => {
  switch (method.toLowerCase()) {
    case 'google':
      return 'google';
    case 'email':
      return 'email';
    default:
      throw Sentry.captureException(`Invalid sign-in method: ${method}`);
  }
};

const CommonOutlet: FC<CommonOutletProps> = (props) => {
  const { updateSharedFile } = props;
  const [logout] = useLazyLogOutQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: commonInit, isLoading: isCommonInitLoading } = useGetCommonInitQuery(null);
  const {
    data: userInit,
    isLoading: isUserInitLoading,
    isSuccess,
    refetch,
  } = useGetUserInitQuery(null);
  const { data: themesData } = useGetThemeInitQuery();

  const baseUrl = commonInit?.result?.ws_api_static;
  const fonts = themesData?.result?.options?.font || [];
  useLazyLoadFonts(fonts, baseUrl);

  const [trialPopup, setTrialPopup] = useState<PopupState>({
    type: null,
    callback: null,
    onClose: null,
  });
  const [resendNotify, setResendNotify] = useState(false);
  const [emailNotify, setEmailNotify] = useState(false);
  const [sendConfirmNotify, setSendConfirmNotify] = useState(false);
  const [onboardingState, changeOnboardingState] = useState<{ [type: string]: number }>({});
  const [presentationType, changePresentationType] =
    useState<CommonOutletContext['changePresentationType']['arguments']>('pptx');

  const withoutFooterLocations = ['project', 'create'];

  const apiKey = commonInit?.result?.amplitude_key;
  const mode = commonInit?.result?.DEV ? 'development' : 'production';

  useEffect(() => {
    analytics.switchMode(mode);
  }, [mode]);

  useEffect(() => {
    if (!apiKey) return;

    amplitudeInstance.init(apiKey, {
      defaultTracking: {
        pageViews: true,
      },
      // NOTE: Our passed UserId throw and error "Invalid user_id or device_id.
      minIdLength: 1,
    });

    analytics.registerPlatform('Amplitude', amplitudeInstance);
  }, [apiKey]);

  function onboardingMessage(type: string, keepSteps: boolean = true) {
    return [
      () => {
        if (presentationType === 'pptx' && type === ONBOARD_PRESENTATION_TYPE) {
          return 1;
        }
        if (
          onboardingState?.['type'] === 1 &&
          window.localStorage.getItem(`${type}_onboard_index`) !==
            window.localStorage.getItem('onboard_freeze')
        ) {
          window.localStorage.setItem(
            `${type}_onboard_index`,
            window.localStorage.getItem(`${String(type)}_onboard_freeze`) ?? '1',
          );
          return window.localStorage.getItem(`${type}_onboard_freeze`) ?? 1;
        }
        return window.localStorage.getItem(`${type}_onboard_index`) ?? onboardingState?.[type] ?? 1;
      },
      ({ step, freeze }: { step: number; freeze: number }) => {
        if (presentationType === 'pptx' && type === ONBOARD_PRESENTATION_TYPE) return;
        const localStep = window.localStorage.getItem(`${type}_onboard_index`);
        if (Number(localStep) >= Number(step) && keepSteps) return;
        window.localStorage.setItem(`${type}_onboard_index`, String(step));
        window.localStorage.setItem(`${type}_onboard_freeze`, String(freeze));
        changeOnboardingState((prevState) => ({ ...prevState, [type]: Number(step) }));
      },
    ];
  }

  function updateData(callback: (() => void) | undefined) {
    refetch().then(() => {
      callback?.();
    });
  }

  function logoutHandler() {
    localStorage.clean();
    logout()
      .unwrap()
      .then(() => {
        window.location.href = route_path.main;
      });
  }

  function trackAuth(userId: number) {
    // TODO: Unused variable
    // let accountsOnDevice = 1;

    const accountsFromStorage = window.localStorage.getItem('accounts');

    if (accountsFromStorage) {
      const accounts = JSON.parse(accountsFromStorage);
      if (!accounts.includes(userId)) {
        accounts.push(userId);
        window.localStorage.setItem('accounts', JSON.stringify(accounts));
      }
      // TODO: Unused variable
      // accountsOnDevice = accounts.length;
    } else {
      window.localStorage.setItem('accounts', JSON.stringify([userId]));
    }

    let method = 'email';
    if (window.sessionStorage.getItem('auth_by_google')) {
      method = 'google';
    }

    if (
      window.sessionStorage.getItem('login') ||
      (window.sessionStorage.getItem('auth_by_google') && !cookie.get('oauth_reg'))
    ) {
      analytics.emitEvent('login', {
        GTM: {
          method: foldSignMethod(method),
        },
      });

      window.sessionStorage.removeItem('login');
      window.sessionStorage.removeItem('auth_by_google');
    } else if (window.sessionStorage.getItem('reg')) {
      registerAnalytics.userRegistered({ method: foldSignMethod(method), userId });
    }
  }

  function clearConfirmNotifyAndClose() {
    setSendConfirmNotify(false);
    navigate(location.pathname);
  }

  function closeTrialPopupHandler() {
    trialPopup?.onClose?.();
    setTrialPopup({ type: null, callback: null, onClose: null });
  }
  function downloadTrialHandler(callBack: () => void) {
    callBack();
  }

  useEffect(() => {
    if (window.location.search.includes('email-confirmed=True')) {
      //window.history.replaceState({}, document.title, location.href.replace(/(\?|&)+email-confirmed=True/, ''));
      setSendConfirmNotify(true);
      if (window.localStorage.getItem('genState')) {
        const savedState = JSON.parse(String(window.localStorage.getItem('genState')) ?? {});
        window.localStorage.removeItem('genState');
        window.location.href = `${route_path.project}${savedState.projectId}/`;
      }
    }
  }, []);

  useEffect(() => {
    if (window.sessionStorage.getItem('buyFromTrial')) {
      window.sessionStorage.removeItem('buyFromTrial');
      if (
        userInit?.result?.product_balance &&
        userInit?.result?.product_balance?.is_active &&
        !userInit?.result?.product_balance?.product?.is_trial
      ) {
        if (userInit.result.product_balance?.product?.count) {
          analytics.emitEvent('trial_end', {
            GTM: {
              trial_end_reason: 'buy_payg',
            },
          });
        } else {
          analytics.emitEvent('trial_end', {
            GTM: {
              trial_end_reason: 'buy_subscription',
            },
          });
        }
      }
    }

    if (userInit?.result?.user_ctx?.id) {
      amplitudeInstance.getInstance().setUserId(String(userInit?.result?.user_ctx?.id));
      if (!cookie.get('uid')) {
        cookie.set('uid', String(userInit.result.user_ctx.id), 2050, 0, 0, '/'); //TODO: мб это и не надо? 2050? я взял из другого участка кода
      }
      trackAuth(userInit.result.user_ctx.id);
    }

    //TODO: в будущем бек добавит роут на чек логина, по нему будет определять авторизацию пользователя и перенаправлять, если пользователь не залогинен
    if (
      !isUserInitLoading &&
      !userInit &&
      PROTECTED_ROUTE_LIST.includes(location?.pathname?.split('/')?.[1])
    ) {
      const next = encodeURIComponent(location?.pathname);
      navigate(`${route_path.register}?next=${next}`, { replace: true });
    }
    //TODO: в будущем уходим от этого
    if (
      isSuccess &&
      PROTECTED_ROUTE_LIST.includes(location?.pathname?.split('/')?.[1]) &&
      !userInit
    ) {
      navigate(route_path.login);
    }
  }, [userInit, location?.pathname, isSuccess, navigate]);

  useEffect(() => {
    const projectId = location.pathname.match(/[0-9]+\/$/);
    if (projectId) {
      analytics.emitEvent('page_view', {
        Amplitude: {
          URL: location.pathname,
          'processed file id': +projectId[0].replace('/', ''),
        },
      });
    } else {
      if (userInit?.result?.user_ctx) {
        analytics.emitEvent('page_view', {
          Amplitude: {
            URL: location.pathname,
          },
        });
      }
    }
  }, [location.pathname, userInit?.result?.user_ctx]);

  if (isUserInitLoading || isCommonInitLoading) return <LoadingScreen />;

  return (
    <>
      <Outlet
        context={
          {
            user_ctx: userInit?.result?.user_ctx,
            logout: logoutHandler,
            context: userInit?.result,
            commonContext: commonInit?.result,
            updateData,
            updateSharedFile,
            onboardingMessage,
            changePresentationType,
            setResendNotify,
            setEmailNotify,
            setSendConfirmNotify,
            setTrialPopup,
          } satisfies CommonOutletContext
        }
      />
      {!withoutFooterLocations.includes(location.pathname.split('/')[1]) ? <Footer /> : <></>}
      <div className="absolute z-[999] bottom-[-500px]">
        <AnimatePresence>
          {trialPopup.type && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="z-[50]"
            >
              <TrialPopup
                type={trialPopup.type}
                onClickDownload={() => {
                  const callback = trialPopup?.callback || trialPopup?.callBack; //TODO: увидел по коду, что где-то он задается с большой, а в типах определен с маленькой. В общем, чтобы ничего не сломалось делаю пока так
                  if (typeof callback === 'function') {
                    callback();
                  }
                }}
                context={userInit?.result}
                close={closeTrialPopupHandler}
              />
            </motion.div>
          )}
          {resendNotify && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <ResendConfirmAccountNotify
                close={() => {
                  setResendNotify(false);
                }}
              />
            </motion.div>
          )}
          {emailNotify && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <ResendConfirmAccountNotify
                close={() => {
                  setEmailNotify(false);
                }}
                linkSended={true}
              />
            </motion.div>
          )}
          {sendConfirmNotify && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <AccountConfirmNotify close={clearConfirmNotifyAndClose} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default CommonOutlet;
