import React from 'react';
import { AnimatePresence, motion, Target } from 'framer-motion';

interface MotionWrapperProps {
  children: React.ReactNode;
  isVisible: boolean;
  delay?: number;
  duration?: number;
  animationProps?: {
    initial?: Target;
    animate?: Target;
    exit?: Target;
  };
}

export const MotionWrapper: React.FC<MotionWrapperProps> = ({
  children,
  isVisible,
  delay = 0,
  duration = 0.5,
  animationProps,
}) => {
  const defaultAnimation: {
    initial: Target;
    animate: Target;
    exit: Target;
  } = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={animationProps?.initial || defaultAnimation.initial}
          animate={animationProps?.animate || defaultAnimation.animate}
          exit={animationProps?.exit || defaultAnimation.exit}
          transition={{
            delay,
            duration,
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
