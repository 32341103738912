import React from 'react';

const MagicIcon = ({ size }: { size: number }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="currentColor">
      <path d="M8.00006 1L7.50006 0L7.00006 1L6.00006 1.125L6.83406 1.833L6.50006 3L7.50006 2.334L8.50006 3L8.16606 1.833L9.00006 1.125L8.00006 1ZM16.3341 11.666L15.5001 10L14.6661 11.666L13.0001 11.875L14.3891 13.056L13.8341 15L15.5001 13.889L17.1661 15L16.6111 13.056L18.0001 11.875L16.3341 11.666ZM3.66706 3.333L3.00006 2L2.33306 3.333L1.00006 3.5L2.11106 4.444L1.66706 6L3.00006 5.111L4.33306 6L3.88906 4.444L5.00006 3.5L3.66706 3.333ZM0.414062 14C0.414062 14.534 0.622062 15.036 1.00006 15.414L2.58606 17C2.96406 17.378 3.46606 17.586 4.00006 17.586C4.53406 17.586 5.03606 17.378 5.41406 17L17.0001 5.414C17.3781 5.036 17.5861 4.534 17.5861 4C17.5861 3.466 17.3781 2.964 17.0001 2.586L15.4141 1C14.6581 0.244 13.3421 0.244 12.5861 1L1.00006 12.586C0.622062 12.964 0.414062 13.466 0.414062 14ZM14.0001 2.414L15.5861 4L12.0001 7.586L10.4141 6L14.0001 2.414Z" />
    </svg>
  );
};

export default MagicIcon;
