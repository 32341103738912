import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useGetProductInitQuery } from '@app/store/api/user.api';
import { analytics } from '@app/app/analytics';

import { Loader } from '@app/components/ui/loader';

import t from '../../lib/lng';
import CorporateModal from '../modals/corporateModal';
import { Switcher } from '../components/ui';
import { ProductInit } from '@app/types/product.type';

const currency = {
  USD: '$',
  IDR: 'IDR ',
};

const tariffOrderClass = {
  'Pay-as-you-go': { order: 1 },
  'Pro plan': { order: 2 },
  'Light plan': { order: 3 },
};

export function Tariffs({ context, page }) {
  const {
    data: { result: { products = [] } = {} } = {},
    isLoading,
    isError,
  } = useGetProductInitQuery();

  const [chosenTariff, setChosenTariff] = useState({});
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  if (isLoading)
    return (
      <div className="w-full flex justify-center">
        <Loader />
      </div>
    );

  if (isError) return 'Error loading products';

  function sendTariffAmplitude(product) {
    let itemVariant = '';
    if (product.duration.hasOwnProperty('days')) itemVariant = 'weekly plan';
    if (product.productTitle === t.MonthlySubscription) itemVariant = 'monthly plan';
    if (product.productTitle === t.YearlySubscription) itemVariant = 'yearly plan';
    if (product.count === 10) itemVariant = 'payg plan';

    analytics.emitEvent('click_plan', {
      Amplitude: {
        'plan name': itemVariant,
      },
    });
  }
  function onClickTariff(product) {
    sendTariffAmplitude(product);
    setChosenTariff(product);
    setShowPaymentPopup(true);
  }
  function buyTariffWithoutPopup(product) {
    sendTariffAmplitude(product);
    setChosenTariff(product);
    buyTariff('stripe', product);
  }

  function buyTariff(paymentType, tariff = chosenTariff) {
    analytics.emitEvent('transaction_click_tariff', {
      Amplitude: {
        'product ID': tariff.id,
        price: tariff.price.amount,
        paymentMethod: paymentType,
      },
    });

    const getItemVariant = (product) => {
      const { duration, count } = product;

      if (duration.years === 1) return 'yearly_plan';
      if (duration.months === 1) return 'monthly_plan';
      if (duration.days === 7) return 'weekly_plan';
      if (count === 10) return 'payg_10';

      throw new Error('Invalid product');
    };

    analytics.emitEvent('begin_checkout', {
      GTM: {
        ecommerce: {
          value: tariff.price.amount,
          currency: tariff.price.currency,
          payment_method: paymentType,
          items: [
            {
              item_name: tariff.count ? 'pay_as_you_go' : 'subscription',
              item_variant: getItemVariant(tariff),
              price: tariff.price.amount,
              quantity: 1,
            },
          ],
        },
      },
    });

    if (page) {
      const getTrailPopupType = (page) => {
        switch (page) {
          case 'download trial':
            return 'download_trial_popup';
          case 'download expired':
            return 'download_trial_popup';
          case 'style':
            return 'style_trial_popup';
          case 'logo':
            return 'logo_trial_popup';
          case 'image':
            return 'slide_edit_trial_popup';
          case 'upgrade':
            return 'slide_edit_trial_popup';
          case 'trial limit':
            return 'projects_trial_popup';
          default:
            throw new Error('Unknown trial type');
        }
      };

      analytics.emitEvent('trial_popup_to_checkout', {
        GTM: { popup_name: getTrailPopupType(page) },
        Amplitude: { 'popup name': getTrailPopupType(page) },
      });
    }

    if (context.product_balance && context.product_balance.product?.is_trial) {
      window.sessionStorage.setItem('buyFromTrial', '1');
    }

    setLoading(true);

    if (context.product_balance) {
      window.sessionStorage.setItem(
        'gotoProduct',
        JSON.stringify({ url: `/purchase/${tariff.id}/${paymentType}?next=${location.pathname}` }),
      );
    }
    location.href = `/purchase/${tariff.id}/${paymentType}?next=${location.pathname}`;
  }

  const combined = {};
  products?.forEach((product) => {
    if (product.duration.months) {
      combined.months = product;
    }
    if (product.duration.years) {
      combined.years = product;
    }
  });
  return (
    <div className="about8_item_wrap_paid">
      {(!context.user_ctx || context.product_balance?.product?.is_trial) && (
        <FreeTariff context={context} products={products} />
      )}
      {products?.length > 0 &&
        products?.slice(0).map((product, i) => {
          if (product.duration.months || product.duration.years) return null;

          const product_balance = context.product_balance;
          let isCurrent = false;
          let productTitle = product.name;
          // if (product.duration.weeks) productTitle = 'Weekly';
          // if (product.duration.months) productTitle = t.MonthlySubscription;
          // if (product.duration.years) productTitle = t.YearlySubscription;
          // if (product.count == 10) productTitle = t.PayAsYouGo;

          if (product_balance && product_balance.is_active) {
            if (product_balance.product.count && product.count == 10) isCurrent = true;
            else if (
              product_balance.product.info &&
              product_balance.product.info.duration.months &&
              product.duration.months
            )
              isCurrent = true;
            else if (
              product_balance.product.info &&
              product_balance.product.info.duration.weeks &&
              product.duration.weeks
            )
              isCurrent = true;
            else if (
              product_balance.product.info &&
              product_balance.product.info.duration.years &&
              product.duration.years
            )
              isCurrent = true;
          }
          return (
            <div
              className={
                'about8_item' +
                (isCurrent || product.duration.years ? ' current' : '') +
                (product.count == 10 ? ' has_hint payg' : '')
              }
              style={tariffOrderClass?.[product?.name]}
              key={i}
            >
              <div className="about8_item__title_tariff about8_item__title__line">
                <h3 className="about8_item__title">{productTitle}</h3>
              </div>
              <div className="tariff_subtitle">
                {product.type === 'presentation' ? 'One-time payment' : 'Subscription'}
              </div>
              <div className="about8_item__descr about8_item__descr__nofocus">
                {product.duration.days && <>Great option to test drive our complete toolkit</>}
                {product.duration.years && (
                  <>For those who love fantastic deals and want to support our team!</>
                )}
                {product.duration.months && (
                  <>A month of wonderful presentations with access to all features</>
                )}
                {product.duration.weeks && <>Great option to test drive our complete toolkit</>}
                {product.count == 10 && (
                  <>
                    Perfect when you need a&nbsp;few
                    <br />
                    great presentations
                  </>
                )}
              </div>
              <div className="about8_item__price">
                {currency[product.price.currency]}{' '}
                {!Number.isInteger(product.price.amount)
                  ? +String(product.price.amount)
                  : formatPrice(product.price.amount, product.price.currency)}
                {product.duration.days && (
                  <div className="about8_item__price__small">
                    / {product.duration.days === 7 ? 'week' : 'days'}
                  </div>
                )}
                {product.duration.weeks && <div className="about8_item__price__small">/ week</div>}
                {product.duration.months && (
                  <div className="about8_item__price__small">/ month</div>
                )}
                {product.duration.years && <div className="about8_item__price__small">/ year</div>}
                {product.count == 10 && (
                  <div className="about8_item__price__small">
                    / {product.count} downloads
                    <div className="hint info_icon">
                      <div className="hint_text hint_text-arrow">
                        Get full access to all Wonderslide features
                        <br />
                        and the ability to download 10 presentations.
                      </div>
                    </div>
                  </div>
                )}
                {!!product.profit && (
                  <div className="about8_item__discount">-{product.profit}%</div>
                )}
                {product.duration.years && (
                  <div className="about8_item__discount_month">
                    Just {currency[product.price.currency]}
                    {formatPrice(product.price.amount / 12, product.price.currency).toFixed(2)} /
                    month!
                  </div>
                )}
              </div>
              <ul className="about8_item__features">
                {product.duration.years && (
                  <>
                    <li>
                      <strong>Create & share unlimited number</strong> of&nbsp;presentations
                    </li>
                    <li>
                      <strong>Unlimited number of&nbsp;presentations</strong> to&nbsp;download
                    </li>
                    <li>
                      <strong>Uploading your logo</strong>
                    </li>
                    <li>
                      <strong>Full access</strong> to&nbsp;image and&nbsp;icon library
                    </li>
                  </>
                )}
                {product.duration.months && (
                  <>
                    <li>
                      <strong>Create & share unlimited number</strong> of&nbsp;presentations
                    </li>
                    <li>
                      <strong>10 presentation downloads</strong> per month
                    </li>
                    <li>
                      <strong>Uploading your logo</strong>
                    </li>
                    <li>
                      <strong>Full access</strong> to&nbsp;image and&nbsp;icon library
                    </li>
                  </>
                )}
                {product.duration.days === 7 && (
                  <>
                    <li>
                      <strong>Create unlimited number of&nbsp;presentations</strong>
                    </li>
                    <li>
                      <strong>3 presentations to download and share weekly</strong>
                    </li>
                    <li>
                      <strong>Up to 15 draft slides</strong> to upload for each presentation
                    </li>
                    <li>
                      <strong>Full access</strong> to image and icon library
                    </li>
                  </>
                )}
                {product.duration.weeks && (
                  <>
                    <li>
                      <strong>Create & share unlimited number</strong> of&nbsp;presentations
                    </li>
                    <li>
                      <strong>10 presentation downloads</strong> per week
                    </li>
                    <li>
                      <strong>Uploading your logo</strong>
                    </li>
                    <li>
                      <strong>Full access</strong> to&nbsp;image and&nbsp;icon library
                    </li>
                  </>
                )}
                {product.count == 10 && (
                  <>
                    <li>
                      <strong>10 presentations to create and share</strong>
                    </li>
                    <li>
                      <strong>10 full presentation </strong>to&nbsp;download
                    </li>
                    <li>
                      <strong>Up to 30 draft slides</strong> to upload for each presentation
                    </li>
                    <li>
                      <strong>Uploading your logo</strong>
                    </li>
                    <li>
                      <strong>Full access</strong> to&nbsp;image and&nbsp;icon library
                    </li>
                  </>
                )}
              </ul>
              <span
                className={
                  'about8_item__btn ga_select_plan btn-outlined' +
                  (product.duration.days ? ' ga_weekly_plan ' : '') +
                  (product.duration.years ? ' ga_yearly_plan ' : '') +
                  (product.duration.months ? ' ga_monthly_plan' : '') +
                  (product.count == 10 ? ' ga_payg_plan' : '')
                }
                onClick={(e) => {
                  product.duration.days === 7
                    ? buyTariffWithoutPopup(product)
                    : onClickTariff({
                        ...product,
                        productTitle: productTitle,
                      });
                }}
              >
                {isCurrent ? 'Your current plan' : t.SelectPlan}
              </span>
            </div>
          );
        })}
      <CombinedTariff
        combined={combined}
        product_balance={context.product_balance}
        onClickTariff={onClickTariff}
      />
      {showPaymentPopup && (
        <PaymentPopup
          chosenTariff={chosenTariff}
          buyTariff={buyTariff}
          loading={loading}
          hidePaypal={context.GEO == 'ID'}
          close={() => {
            setShowPaymentPopup(false);
          }}
        />
      )}
    </div>
  );
}

const PaymentPopup = ({ chosenTariff, buyTariff, loading, close, hidePaypal }) => {
  return (
    <div className="modal">
      <div className="modal_overlay" onClick={close}></div>
      <div className={'payment_types' + (loading ? ' loading' : '')}>
        <div className="modal_close" onClick={close}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor">
            <path d="M10.192 0.343994L5.94897 4.58599L1.70697 0.343994L0.292969 1.75799L4.53497 5.99999L0.292969 10.242L1.70697 11.656L5.94897 7.41399L10.192 11.656L11.606 10.242L7.36397 5.99999L11.606 1.75799L10.192 0.343994Z" />
          </svg>
        </div>
        <h2 className="payment_types_title">
          <svg width="41" height="40" viewBox="0 0 41 40" fill="none">
            {' '}
            <path d="M27.167 20H30.5003V26.6667H27.167V20Z" fill="#6E718F" />{' '}
            <path
              d="M33.833 11.6667V8.33333C33.833 6.495 32.338 5 30.4997 5H8.83301C6.07634 5 3.83301 7.24333 3.83301 10V30C3.83301 33.6683 6.82301 35 8.83301 35H33.833C35.6713 35 37.1663 33.505 37.1663 31.6667V15C37.1663 13.1617 35.6713 11.6667 33.833 11.6667ZM8.83301 8.33333H30.4997V11.6667H8.83301C8.40387 11.6475 7.99868 11.4635 7.7018 11.153C7.40492 10.8426 7.23923 10.4296 7.23923 10C7.23923 9.57044 7.40492 9.15743 7.7018 8.84697C7.99868 8.53651 8.40387 8.35253 8.83301 8.33333ZM33.833 31.6667H8.85301C8.08301 31.6467 7.16634 31.3417 7.16634 30V14.6917C7.68967 14.88 8.24467 15 8.83301 15H33.833V31.6667Z"
              fill="#6E718F"
            />{' '}
          </svg>
          Choose a payment method
        </h2>
        <div className="payment_types_text">Click on the option to go to payment</div>
        <div className="payment_types_btns">
          <div
            className="payment_types_btn stripe"
            onClick={() => {
              buyTariff('stripe');
            }}
          >
            by card and other
            <img srcSet="/static/i/payment_logos.png, /static/i/payment_logos@2x.png 2x" />
          </div>
          {!hidePaypal && (
            <>
              <div>or</div>
              <div
                className="payment_types_btn paypal"
                onClick={() => {
                  buyTariff('paypal');
                }}
              >
                via Pay Pal
                <img srcSet="/static/i/paypal_logo.png, /static/i/paypal_logo@2x.png 2x" />
              </div>
            </>
          )}
        </div>
        <div className="payment_types_info">
          <div>Your plan: {chosenTariff.productTitle}</div>
          <div>
            <strong>
              Total: {currency[chosenTariff.price.currency]}
              {formatPrice(chosenTariff.price.amount, chosenTariff.price.currency)}
              {chosenTariff.productTitle === 'Yearly' && '/year'}
              {!!chosenTariff.profit && (
                <span className="payment_types_profit">
                  -&nbsp;{Number(chosenTariff.profit)}&nbsp;%
                </span>
              )}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export function CombinedTariff({ combined, product_balance, onClickTariff }) {
  const [mode, setMode] = useState('years');
  let product = combined[mode ?? 'years'];
  if (!product) return 'Error loading products';
  let productTitle = product?.name;
  if (product?.duration.months) productTitle = t.MonthlySubscription;
  if (product?.duration.years) productTitle = t.YearlySubscription;
  let isCurrent = false;
  if (product_balance && product_balance.is_active) {
    if (product_balance.product.info?.duration.months && product.duration.months) isCurrent = true;
    else if (product_balance.product.info?.duration.years && product.duration.years)
      isCurrent = true;
  }
  let byMonthPrice = product.price.amount;
  if (product.duration.years) {
    byMonthPrice = Math.floor((byMonthPrice / 12) * 100) / 100;
  }

  return (
    <div className={'about8_item highlighted'} style={tariffOrderClass?.[product?.name]}>
      <div className="about8_item__title_tariff about8_item__title__line title_combine">
        <h3>{product.name} </h3>
        <div className="tariff_checkbox_switcher">
          <Switcher
            id="sw_tarif"
            name={product.name.toString().toLowerCase()}
            checked={mode === 'years'}
            onCheck={(check) => {
              setMode(check ? 'years' : 'months');
            }}
          />
          <div
            className={`tariff_check_label ${mode === 'years' && 'tariff_check_label__checked'}`}
          >
            Pay annualy
          </div>
        </div>
      </div>
      <div className="tariff_subtitle">
        {product.type === 'presentation' ? 'One-time payment' : 'Subscription'}
      </div>
      <div className="about8_item__descr">
        {product.duration.years && (
          <>For those who love fantastic deals and want to support our team!</>
        )}
        {product.duration.months && (
          <>A month of wonderful presentations with access to all features</>
        )}
      </div>
      <div className="about8_item__price">
        {currency[product.price.currency]}{' '}
        {!Number.isInteger(byMonthPrice)
          ? +String(byMonthPrice)
          : formatPrice(byMonthPrice, product.price.currency)}
        {product.duration.months && <div className="about8_item__price__small">/ month</div>}
        {product.duration.years && <div className="about8_item__price__small">/ month</div>}
        {product.count == 10 && (
          <div className="about8_item__price__small">
            / {product.count} downloads
            <div className="hint info_icon">
              <div className="hint_text hint_text-arrow">
                Get full access to all Wonderslide features
                <br />
                and the ability to download 10 presentations.
              </div>
            </div>
          </div>
        )}
        {!!product.profit && <div className="about8_item__discount">-{product.profit}%</div>}
        {product.duration.years && (
          <div className="about8_item__discount_month">
            {currency[product.price.currency]}
            {+product.price.amount} billed annually
          </div>
        )}
      </div>
      <ul className="about8_item__features">
        <li>
          <strong>Create & share unlimited number</strong> of&nbsp;presentations
        </li>
        <li>
          <strong>Unlimited number of&nbsp;presentations</strong> to&nbsp;download
        </li>
        <li>
          <strong>Up to 30 draft slides </strong>to upload for each presentation
        </li>
        <li>
          <strong>Uploading your logo</strong>
        </li>
        <li>
          <strong>Full access</strong> to&nbsp;image and&nbsp;icon library
        </li>
      </ul>
      <span
        className={
          'about8_item__btn ga_select_plan btn-outlined' +
          (product.duration.days ? ' ga_weekly_plan ' : '') +
          (product.duration.years ? ' ga_yearly_plan ' : '') +
          (product.duration.months ? ' ga_monthly_plan' : '') +
          (product.count == 10 ? ' ga_payg_plan' : '')
        }
        onClick={(e) => {
          onClickTariff({ ...product, productTitle: productTitle });
        }}
      >
        {isCurrent ? 'Your current plan' : t.SelectPlan}
      </span>
    </div>
  );
}

export function FreeTariff({ context, products }) {
  const product_balance = context.product_balance;
  if (!products || products?.length === 0) return 'Error loading products';
  return (
    <div className="about8_item_wrap_free">
      <div
        className={
          'about8_item' +
          (product_balance && product_balance.product && product_balance.product?.is_trial
            ? ' current'
            : '')
        }
      >
        <div className="about8_item__title_tariff about8_item__title__line">
          <h3 className="about8_item__title">Free trial</h3>
        </div>
        <div className="tariff_subtitle">7-day access</div>
        <div className="about8_item__descr about8_item__descr__nofocus ">
          Great chance to try the magic of&nbsp;Wonderslide
        </div>
        <div className="about8_item__price">
          {currency[products[0].price.currency]} {+String(0.0)}
        </div>
        <ul className="about8_item__features">
          <li>
            <strong>3 presentations to&nbsp;create and share</strong>
          </li>
          <li>
            <strong>3 slides to&nbsp;download </strong> for&nbsp;each presentation
          </li>
          <li>10 slides to upload for each presentation</li>
          <li>2 presentation styles available</li>
          <li>Limited access to features library</li>
        </ul>
        {product_balance && product_balance.is_active && product_balance.product?.is_trial && (
          <span className="about8_item__note">Your current plan</span>
        )}
        {product_balance && !product_balance.is_active && context.user_ctx && (
          <span className="about8_item__note">Expired</span>
        )}
        {!context.user_ctx && (
          <Link to="/projects/" className="about8_item__btn btn-outlined">
            Get started
          </Link>
        )}
      </div>
    </div>
  );
}

export function EnterpriseTariff() {
  const [showCorporateModal, setShowCorporateModal] = useState(false);

  return (
    <div className="about8_ent">
      <h3 className="about8_ent__title">Enterprise plan</h3>
      <div className="about8_ent__text">
        We can customize our product to your company's needs. Here's what we can do for you:
      </div>
      <ul className="about8_ent__items">
        <li className="about8_ent__item">Add your corporate colors</li>
        <li className="about8_ent__item">Build your own library of images and objects</li>
        <li className="about8_ent__item">Add your branded fonts</li>
        <li className="about8_ent__item">Private accounts for employees</li>
        <li className="about8_ent__item">Upload your corporate templates</li>
        <li className="about8_ent__item">Personal support manager</li>
        <li className="about8_ent__item">Create exclusive corporate-styled templates</li>
        <li className="about8_ent__item">The ability to disable ChatGPT functions</li>
      </ul>
      <div
        className="about8_ent__btn about_btn"
        onClick={() => {
          setShowCorporateModal(true);
        }}
      >
        Book a demo call
      </div>
      {showCorporateModal && (
        <CorporateModal
          close={() => {
            setShowCorporateModal(false);
          }}
        />
      )}
    </div>
  );
}

function formatPrice(price, cur) {
  if (cur === 'IDR') {
    price = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(
      +String(price),
    );
    price = price.split('Rp')[1].split(',')[0];
  }
  return price;
}
