import React, { FC } from 'react';
import { PaymentType, ProductInit } from '@app/types/product.type';
import { currency, formatPrice } from '@app/pages/pricing/blocks/tariffs.block';

type PaymentPopupBlockProps = {
  chosenTariff?: ProductInit['products'][number] & { productTitle: string };
  buyTariff: (
    paymentType: PaymentType,
    tariff?: ProductInit['products'][number] & { productTitle: string },
  ) => void;
  loading: boolean;
  close: () => void;
  hidePaypal: boolean;
};

const PaymentPopupBlock: FC<PaymentPopupBlockProps> = (props) => {
  const { chosenTariff, buyTariff, loading, close, hidePaypal } = props;
  if (!chosenTariff) return <></>;
  return (
    <div className="modal">
      <div className="modal_overlay" onClick={close}></div>
      <div className={'payment_types' + (loading ? ' loading' : '')}>
        <div className="modal_close" onClick={close}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor">
            <path d="M10.192 0.343994L5.94897 4.58599L1.70697 0.343994L0.292969 1.75799L4.53497 5.99999L0.292969 10.242L1.70697 11.656L5.94897 7.41399L10.192 11.656L11.606 10.242L7.36397 5.99999L11.606 1.75799L10.192 0.343994Z" />
          </svg>
        </div>
        <h2 className="payment_types_title">
          <svg width="41" height="40" viewBox="0 0 41 40" fill="none">
            {' '}
            <path d="M27.167 20H30.5003V26.6667H27.167V20Z" fill="#6E718F" />{' '}
            <path
              d="M33.833 11.6667V8.33333C33.833 6.495 32.338 5 30.4997 5H8.83301C6.07634 5 3.83301 7.24333 3.83301 10V30C3.83301 33.6683 6.82301 35 8.83301 35H33.833C35.6713 35 37.1663 33.505 37.1663 31.6667V15C37.1663 13.1617 35.6713 11.6667 33.833 11.6667ZM8.83301 8.33333H30.4997V11.6667H8.83301C8.40387 11.6475 7.99868 11.4635 7.7018 11.153C7.40492 10.8426 7.23923 10.4296 7.23923 10C7.23923 9.57044 7.40492 9.15743 7.7018 8.84697C7.99868 8.53651 8.40387 8.35253 8.83301 8.33333ZM33.833 31.6667H8.85301C8.08301 31.6467 7.16634 31.3417 7.16634 30V14.6917C7.68967 14.88 8.24467 15 8.83301 15H33.833V31.6667Z"
              fill="#6E718F"
            />{' '}
          </svg>
          Choose a payment method
        </h2>
        <div className="payment_types_text">Click on the option to go to payment</div>
        <div className="payment_types_btns">
          <div
            className="payment_types_btn stripe"
            onClick={() => {
              buyTariff('stripe');
            }}
          >
            by card and other
            <img srcSet="/static/i/payment_logos.png, /static/i/payment_logos@2x.png 2x" />
          </div>
          {!hidePaypal && (
            <>
              <div>or</div>
              <div
                className="payment_types_btn paypal"
                onClick={() => {
                  buyTariff('paypal');
                }}
              >
                via Pay Pal
                <img srcSet="/static/i/paypal_logo.png, /static/i/paypal_logo@2x.png 2x" />
              </div>
            </>
          )}
        </div>
        <div className="payment_types_info">
          <div>Your plan: {chosenTariff?.productTitle}</div>
          <div>
            <strong>
              Total: {currency[chosenTariff.price.currency]}
              {formatPrice(String(chosenTariff?.price.amount ?? 0), chosenTariff.price.currency)}
              {chosenTariff.productTitle === 'Yearly' && '/year'}
              {!!chosenTariff.profit && (
                <span className="payment_types_profit">
                  -&nbsp;{Number(chosenTariff.profit)}&nbsp;%
                </span>
              )}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentPopupBlock;
