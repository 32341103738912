import React from 'react';
import PropTypes from "prop-types";

import { Button, Icon } from "../../ui";

const CongratulationsModal = (props) => {
    const { title, description, btnText, onClick, onCancel } = props;
    return (
        <div className="congratulations_template">
            <div className="congratulations_template__bg" />
            <div className="congratulations_template__container">
                <div className="congratulations_template__close" onClick={() => onCancel?.()}>
                    <Icon name="X2Icon" size={11}/></div>
                <div className="congratulations_template__icon"><Icon name="CongratulationIcon" size={64} /> </div>
                <h3 className="congratulations_template__title">{title}</h3>
                <p className="congratulations_template__description" dangerouslySetInnerHTML={{__html: String(description)}} />
                <div className="congratulations_template__btn">
                    <Button onClick={() => onClick?.()} label={btnText} size="extra_small" rounded="middle" isFullWidth />
                </div>
            </div>
        </div>
    );
};

CongratulationsModal.prototype = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    btnText: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    onCancel: PropTypes.func,
}

export default CongratulationsModal;