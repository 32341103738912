import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router';

import { useGetProductInitQuery } from '@app/store/api/user.api';
import { Skeleton } from '@app/components/ui';
import { CommonOutletContext } from '@app/pages/common.outlet';
import t from '@lib/lng';
import { loadScript } from '@lib/utils';

import EnterpriseTariffBlock from './blocks/enterprise-tariff.block';
import TariffsBlock from './blocks/tariffs.block';

const PricingPage = function () {
  const { context } = useOutletContext<CommonOutletContext>();
  const { data: productResponse, isLoading } = useGetProductInitQuery(null);
  const products = productResponse?.result;
  useEffect(() => {
    if (
      products &&
      products.payment_options &&
      products.payment_options.length > 0 &&
      products.payment_options[0]?.type === 'cloud-payments'
    ) {
      loadScript('https://widget.cloudpayments.ru/bundles/cloudpayments');
    }
  }, [context?.user_ctx, products?.payment_options]);
  return (
    <div className="prices">
      <section className="about8">
        <div className="about8_inner">
          <h2 className="prices_title">{t.Pricing}</h2>

          <div className="about8_items">
            {/* {
							(!props.context.user_ctx || props.context.product_balance?.product?.is_trial) &&
							<FreeTariff context={props.context} />
						} */}
            {!isLoading && products ? (
              <TariffsBlock autoStartEnabled={true} productsData={products} />
            ) : (
              <>
                {Array(3)
                  .fill(':)')
                  .map((_, index) => (
                    <Skeleton key={index} width={'100%'} height={'525px'} />
                  ))}
              </>
            )}
          </div>
          <EnterpriseTariffBlock />
        </div>
      </section>
    </div>
  );
};

export default PricingPage;
