import cn from 'classnames';

export const Loader = ({
  firstColor = '#00ba88',
  secondColor = '#6e718f',
  width = '15px',
  variant = 'default',
}: {
  firstColor?: string;
  secondColor?: string;
  width: string;
  variant: 'default' | 'second';
}) => {
  return (
    <div
      className={cn('customLoader', {
        'customLoader--isDefault': variant === 'default',
        'customLoader--isSecond': variant === 'second',
      })}
      style={
        {
          '--customLoaderFirstColor': firstColor,
          '--customLoaderSecondColor': secondColor,
          '--customLoaderWidth': width,
        } as React.CSSProperties
      }
    ></div>
  );
};

export default Loader;
