import React, { FC, useState } from 'react';
import { useOutletContext } from 'react-router';

import { PaymentType, ProductInit } from '@app/types/product.type';
import { analytics } from '@app/app/analytics';

import { ItemVariant, PlanName, TrialPopupType } from '@app/app/analytics/types';
import { CommonOutletContext } from '@app/pages/common.outlet';

import t from '../../../../lib/lng';

import { TrialType } from '../../../types/trial.type';

import PaymentPopupBlock from './payment-popup.block';
import CombinedTariffBlock from './combined-tariff.block';
import FreeTariffBlock from './free-tariff.block';

type CurrencyList = Record<'USD' | 'IDR', string>;

type TariffsBlockProps = {
  page?: TrialType | null;
  autoStartEnabled: boolean;
  productsData: ProductInit;
};
export type TariffTitles = 'Pay-as-you-go' | 'Pro plan' | 'Light plan';
export type TariffOrder = Record<TariffTitles, { order: number }>;

export const tariffOrderClass: TariffOrder = {
  'Pay-as-you-go': { order: 1 },
  'Pro plan': { order: 2 },
  'Light plan': { order: 3 },
};

export const currency: CurrencyList = {
  USD: '$',
  IDR: 'IDR ',
};

const TariffsBlock: FC<TariffsBlockProps> = (props) => {
  const { page, productsData } = props;
  const [chosenTariff, setChosenTariff] = useState<
    ProductInit['products'][number] & { productTitle: string }
  >();
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const { context } = useOutletContext<CommonOutletContext>();
  function sendTariffAmplitude(
    product: ProductInit['products'][number] & { productTitle: string },
  ) {
    let itemVariant = '';
    if (Object.prototype.hasOwnProperty.call(product.duration, 'days')) itemVariant = 'weekly plan';
    if (product.productTitle === t.MonthlySubscription) itemVariant = 'monthly plan';
    if (product.productTitle === t.YearlySubscription) itemVariant = 'yearly plan';
    if (product.count === 10) itemVariant = 'payg plan';
    analytics.emitEvent('click_plan', {
      Amplitude: {
        // TODO: Fix as
        'plan name': itemVariant as PlanName,
      },
    });
  }
  function onClickTariff(
    product: ProductInit['products'][number] & { productTitle: string },
  ): void {
    sendTariffAmplitude(product);
    setChosenTariff(product);
    setShowPaymentPopup(true);
  }
  function buyTariffWithoutPopup(
    product: ProductInit['products'][number] & { productTitle: string },
  ) {
    sendTariffAmplitude(product);
    setChosenTariff(product);
    buyTariff('stripe', product);
  }

  function buyTariff(
    paymentType: PaymentType,
    tariff: (ProductInit['products'][number] & { productTitle: string }) | undefined = chosenTariff,
  ) {
    if (!tariff) return;
    analytics.emitEvent('transaction_click_tariff', {
      Amplitude: {
        'product ID': tariff.id,
        price: tariff.price.amount,
        paymentMethod: paymentType,
      },
    });

    const getItemVariant = (product: ProductInit['products'][number]): ItemVariant => {
      const { duration, count } = product;

      if (duration.years === 1) return 'yearly_plan';
      if (duration.months === 1) return 'monthly_plan';
      if (duration.days === 7) return 'weekly_plan';
      if (count === 10) return 'payg_10';

      throw new Error('Invalid product');
    };

    analytics.emitEvent('begin_checkout', {
      GTM: {
        ecommerce: {
          value: tariff.price.amount,
          currency: tariff.price.currency,
          payment_method: paymentType,
          items: [
            {
              item_name: tariff.count ? 'pay_as_you_go' : 'subscription',
              item_variant: getItemVariant(tariff),
              price: tariff.price.amount,
              quantity: 1,
            },
          ],
        },
      },
    });

    if (page) {
      const getTrailPopupType = (page: TrialType): TrialPopupType => {
        switch (page) {
          case 'download trial':
            return 'download_trial_popup';
          case 'download expired':
            return 'download_trial_popup';
          case 'style':
            return 'style_trial_popup';
          case 'logo':
            return 'logo_trial_popup';
          case 'image':
            return 'slide_edit_trial_popup';
          case 'upgrade':
            return 'slide_edit_trial_popup';
          case 'trial limit':
            return 'projects_trial_popup';
          default:
            throw new Error('Unknown trial type');
        }
      };

      analytics.emitEvent('trial_popup_to_checkout', {
        GTM: { popup_name: getTrailPopupType(page) },
        Amplitude: { 'popup name': getTrailPopupType(page) },
      });
    }

    if (context?.product_balance && context?.product_balance.product?.is_trial) {
      window.sessionStorage.setItem('buyFromTrial', '1');
    }

    setLoading(true);

    if (context?.product_balance) {
      window.sessionStorage.setItem(
        'gotoProduct',
        JSON.stringify({ url: `/purchase/${tariff.id}/${paymentType}?next=${location.pathname}` }),
      );
    }
    location.href = `/purchase/${tariff.id}/${paymentType}?next=${location.pathname}`;
  }

  const combined: Partial<
    Record<
      Partial<keyof ProductInit['products'][number]['duration']>,
      ProductInit['products'][number]
    >
  > = {};
  productsData?.products?.forEach((product: ProductInit['products'][number]) => {
    if (product.duration.months) {
      combined.months = product;
    }
    if (product.duration.years) {
      combined.years = product;
    }
  });
  return (
    <div className="about8_item_wrap_paid">
      {(!context?.user_ctx || context?.product_balance?.product?.is_trial) && (
        <FreeTariffBlock productData={productsData} />
      )}
      {(productsData?.products?.length ?? 0) > 0 &&
        productsData?.products?.slice(0).map((product, i) => {
          if (product.duration.months || product.duration.years) return null;

          const product_balance = context?.product_balance;
          let isCurrent = false;
          const productTitle = product.name;
          // if (product.duration.weeks) productTitle = 'Weekly';
          // if (product.duration.months) productTitle = t.MonthlySubscription;
          // if (product.duration.years) productTitle = t.YearlySubscription;
          // if (product.count == 10) productTitle = t.PayAsYouGo;

          if (product_balance && product_balance.is_active) {
            if (product_balance.product.count && product.count == 10) isCurrent = true;
            else if (
              product_balance.product.info &&
              product_balance.product.info.duration?.months &&
              product.duration?.months
            )
              isCurrent = true;
            else if (
              product_balance.product.info &&
              product_balance.product.info.duration?.weeks &&
              product.duration?.weeks
            )
              isCurrent = true;
            else if (
              product_balance.product.info &&
              product_balance.product.info.duration.years &&
              product.duration?.years
            )
              isCurrent = true;
          }
          return (
            <div
              className={
                'about8_item' +
                (isCurrent || product.duration.years ? ' current' : '') +
                (product.count == 10 ? ' has_hint payg' : '')
              }
              style={product?.name ? (tariffOrderClass?.[product?.name as TariffTitles] ?? {}) : {}}
              key={i}
            >
              <div className="about8_item__title_tariff about8_item__title__line">
                <h3 className="about8_item__title">{productTitle}</h3>
              </div>
              <div className="tariff_subtitle">
                {product.type === 'presentation' ? 'One-time payment' : 'Subscription'}
              </div>
              <div className="about8_item__descr about8_item__descr__nofocus">
                {product.duration.days && <>Great option to test drive our complete toolkit</>}
                {product.duration.years && (
                  <>For those who love fantastic deals and want to support our team!</>
                )}
                {product.duration.months && (
                  <>A month of wonderful presentations with access to all features</>
                )}
                {product.duration.weeks && <>Great option to test drive our complete toolkit</>}
                {product.count == 10 && (
                  <>
                    Perfect when you need a&nbsp;few
                    <br />
                    great presentations
                  </>
                )}
              </div>
              <div className="about8_item__price">
                {currency[product.price.currency]}{' '}
                {!Number.isInteger(product.price.amount)
                  ? +String(product.price.amount)
                  : formatPrice(String(product.price.amount), product.price.currency)}
                {product.duration.days && (
                  <div className="about8_item__price__small">
                    / {product.duration.days === 7 ? 'week' : 'days'}
                  </div>
                )}
                {product.duration.weeks && <div className="about8_item__price__small">/ week</div>}
                {product.duration.months && (
                  <div className="about8_item__price__small">/ month</div>
                )}
                {product.duration.years && <div className="about8_item__price__small">/ year</div>}
                {product.count == 10 && (
                  <div className="about8_item__price__small">
                    / {product.count} downloads
                    <div className="hint info_icon">
                      <div className="hint_text hint_text-arrow">
                        Get full access to all Wonderslide features
                        <br />
                        and the ability to download 10 presentations.
                      </div>
                    </div>
                  </div>
                )}
                {!!product.profit && (
                  <div className="about8_item__discount">-{product.profit}%</div>
                )}
                {product.duration.years && (
                  <div className="about8_item__discount_month">
                    Just {currency[product.price.currency]}
                    {Number(
                      formatPrice(String(product.price.amount / 12), product.price.currency),
                    ).toFixed(2)}{' '}
                    / month!
                  </div>
                )}
              </div>
              <ul className="about8_item__features">
                {product.duration.years && (
                  <>
                    <li>
                      <strong>Create & share unlimited number</strong> of&nbsp;presentations
                    </li>
                    <li>
                      <strong>Unlimited number of&nbsp;presentations</strong> to&nbsp;download
                    </li>
                    <li>
                      <strong>Uploading your logo</strong>
                    </li>
                    <li>
                      <strong>Full access</strong> to&nbsp;image and&nbsp;icon library
                    </li>
                  </>
                )}
                {product.duration.months && (
                  <>
                    <li>
                      <strong>Create & share unlimited number</strong> of&nbsp;presentations
                    </li>
                    <li>
                      <strong>10 presentation downloads</strong> per month
                    </li>
                    <li>
                      <strong>Uploading your logo</strong>
                    </li>
                    <li>
                      <strong>Full access</strong> to&nbsp;image and&nbsp;icon library
                    </li>
                  </>
                )}
                {product.duration.days === 7 && (
                  <>
                    <li>
                      <strong>Create unlimited number of&nbsp;presentations</strong>
                    </li>
                    <li>
                      <strong>3 presentations to download and share weekly</strong>
                    </li>
                    <li>
                      <strong>Up to 15 draft slides</strong> to upload for each presentation
                    </li>
                    <li>
                      <strong>Full access</strong> to image and icon library
                    </li>
                  </>
                )}
                {product.duration.weeks && (
                  <>
                    <li>
                      <strong>Create & share unlimited number</strong> of&nbsp;presentations
                    </li>
                    <li>
                      <strong>10 presentation downloads</strong> per week
                    </li>
                    <li>
                      <strong>Uploading your logo</strong>
                    </li>
                    <li>
                      <strong>Full access</strong> to&nbsp;image and&nbsp;icon library
                    </li>
                  </>
                )}
                {product.count == 10 && (
                  <>
                    <li>
                      <strong>10 presentations to create and share</strong>
                    </li>
                    <li>
                      <strong>10 full presentation </strong>to&nbsp;download
                    </li>
                    <li>
                      <strong>Up to 30 draft slides</strong> to upload for each presentation
                    </li>
                    <li>
                      <strong>Uploading your logo</strong>
                    </li>
                    <li>
                      <strong>Full access</strong> to&nbsp;image and&nbsp;icon library
                    </li>
                  </>
                )}
              </ul>
              <span
                className={
                  'about8_item__btn ga_select_plan btn-outlined' +
                  (product.duration.days ? ' ga_weekly_plan ' : '') +
                  (product.duration.years ? ' ga_yearly_plan ' : '') +
                  (product.duration.months ? ' ga_monthly_plan' : '') +
                  (product.count == 10 ? ' ga_payg_plan' : '')
                }
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  product?.duration.days === 7
                    ? buyTariffWithoutPopup({
                        ...product,
                        productTitle,
                      })
                    : onClickTariff({
                        ...product,
                        productTitle: productTitle,
                      });
                }}
              >
                {isCurrent ? 'Your current plan' : t.SelectPlan}
              </span>
            </div>
          );
        })}

      {
        <CombinedTariffBlock
          combined={combined}
          product_balance={context?.product_balance}
          onClickTariff={onClickTariff}
        />
      }
      {showPaymentPopup && (
        <PaymentPopupBlock
          chosenTariff={chosenTariff}
          buyTariff={buyTariff}
          loading={loading}
          hidePaypal={context?.GEO == 'ID'}
          close={() => {
            setShowPaymentPopup(false);
          }}
        />
      )}
    </div>
  );
};

export default TariffsBlock;

export function formatPrice(price: string, cur: keyof CurrencyList) {
  if (cur === 'IDR') {
    price = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(
      +String(price),
    );
    price = price.split('Rp')[1].split(',')[0];
  }
  return Number(price);
}
