export const useSlideDown = ({ distance = 40 } = {}) => ({
  initial: { opacity: 0, y: -distance },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -distance },
});

export const useSlideUp = ({ distance = 40 } = {}) => ({
  initial: { opacity: 0, y: distance },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: distance },
});
