import React, {useState, useRef, useEffect} from 'react';

const Main3_slider2 = ({isActive}) => {
	const [rendered, setRendered] = useState(false);
	const [animClass, setAnimClass] = useState('');
	const imgRef = useRef(null);

	const animate = () => {
		setRendered(true);
		setTimeout(() => {
			setAnimClass('a1');
			setTimeout(() => {
				setAnimClass('a1 a2');
			}, 600);
		}, 200);
	}

	useEffect(() => {
		if (isActive && !rendered) animate();
	}, [isActive]);

	return(
		<div className={'main3_slide_img main3_slide_img2 ' + animClass}>
			<div className="el1">
				<svg width="144" height="22" viewBox="0 0 144 22" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0.646162 14.105C1.43943 16.8029 3.2653 19.0787 5.72703 20.438C8.18876 21.7973 11.0875 22.1302 13.7933 21.3644L143.5 9.52722L9.60783 0.537354C8.07122 0.721 6.59306 1.23719 5.27619 2.05003C3.95931 2.86287 2.83533 3.95284 1.98242 5.24413C1.1295 6.53541 0.568146 7.99699 0.337381 9.52722C0.106615 11.0575 0.211981 12.6196 0.646162 14.105Z" fill="#0E47C0"/>
				</svg>
			</div>

			<svg width="521" viewBox="0 0 521 516" fill="none" xmlns="http://www.w3.org/2000/svg" ref={imgRef}>
				<foreignObject x="0" y="0" width="521" height="516">
					<img srcSet="/static/i/main3_slide2_1.webp, /static/i/main3_slide2_1@2x.webp 2x" loading="lazy" />
				</foreignObject>
				<g className="el2">
					<path d="M351.161 217.853H269.264V298.707H351.161V217.853Z" stroke="white" strokeWidth="0.188492" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.75 0.75"/>
					<path d="M352.207 216.808H350.116V218.899H352.207V216.808Z" fill="black" stroke="white" strokeWidth="0.188492" strokeMiterlimit="10"/>
					<path d="M352.207 297.661H350.116V299.753H352.207V297.661Z" fill="black" stroke="white" strokeWidth="0.188492" strokeMiterlimit="10"/>
					<path d="M332.485 238.916H322.656V297.578H332.485V238.916Z" stroke="#18AB86" strokeWidth="0.565477" strokeMiterlimit="10"/>
					<path d="M349.773 219.889H339.944V297.569H349.773V219.889Z" stroke="#18AB86" strokeWidth="0.565477" strokeMiterlimit="10"/>
				</g>
				<g className="el2">
					<path d="M462.98 444.525H283.184V449.633H462.98V444.525Z" fill="#3A3C4F"/>
					<path d="M462.98 453.446H283.184V458.554H462.98V453.446Z" fill="#3A3C4F"/>
					<path d="M392.272 462.367H283.184V467.475H392.272V462.367Z" fill="#3A3C4F"/>
				</g>
				<foreignObject className="el2" x="328" y="74" width="84" height="32">
					<img srcSet="/static/i/main3_slide2_2.webp, /static/i/main3_slide2_2@2x.webp 2x" loading="lazy" />
				</foreignObject>
				<foreignObject className="el2" x="279" y="369" width="185" height="42">
					<img srcSet="/static/i/main3_slide2_3.webp, /static/i/main3_slide2_3@2x.webp 2x" loading="lazy" />
				</foreignObject>

			</svg>
		</div>
	);
}
export default Main3_slider2;
