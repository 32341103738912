import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import * as Sentry from '@sentry/browser';
import noScroll from 'no-scroll';

import t from '@lib/lng';
import { useUploadLogoMutation } from '@app/store/api/presentation.api';

import { useClickOutside } from '@app/hooks/use-click-outside';

import UploadImageCrop from './upload-image-crop.block';

const UploadLogoBlock = (props) => {
  const { onCloseUpload, onError, close } = props;
  const [imgSrc, setImgSrc] = useState('');
  const [useBlackBg, setUseBlackBg] = useState(false);
  const [fileInfo, setFileInfo] = useState({ name: '', type: '' });
  const [isSaving, setIsSaving] = useState(false);
  const previewCanvasRef = useRef(null);
  const [uploadLogoMutation] = useUploadLogoMutation();
  const modalRef = useRef(null);

  const onSelectFile = (e) => {
    if (!e.target.files[0]) return;
    if (!e.target.files[0].type.match(/jpeg|png/)) {
      onError(t.weSupportOnlyJpgPng);
      return;
    }
    if (e.target.files[0].size > 5242880) {
      onError(t.maximumSizeExceeded);
      return;
    }
    setFileInfo({
      name: e.target.files[0].name,
      type: e.target.files[0].type,
    });
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgSrc(reader.result.toString() || '');
    });
    reader.readAsDataURL(e.target.files[0]);
  };

  const onClickSave = async () => {
    try {
      setIsSaving(true);
      previewCanvasRef?.current.toBlob(async (blob) => {
        const file = new File([blob], fileInfo.name, { type: fileInfo.type });
        const formData = new FormData();
        formData.append('file', file);
        formData.append('color', useBlackBg ? '#000000' : '#ffffff');
        uploadLogoMutation({ body: formData })
          .unwrap()
          .then((response) => {
            setIsSaving(false);
            if (response.status) {
              onCloseUpload(response?.result?.value);
            }
          });
      });
    } catch (e) {
      Sentry.captureException({
        function: 'UploadLogo',
        msg: e.msg,
        file_name: e.file ? e.file.name : '',
        file_size: e.file ? e.file.size : '',
      });
    }
  };

  useEffect(() => {
    noScroll.on();
    return () => {
      noScroll.off();
    };
  }, []);

  useClickOutside(modalRef, close);

  return (
    <>
      <div className="modal_overlay fixed z-[9999]" onClick={onCloseUpload}></div>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: '100%' }}
        exit={{ scale: 0 }}
        className="modal upload_logo"
        ref={modalRef}
      >
        <div className="modal_window">
          <div className="modal_close" onClick={onCloseUpload}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
              <line
                y1="-1"
                x2="21.2624"
                y2="-1"
                transform="matrix(0.706015 0.708197 -0.706015 0.708197 1 2.35706)"
                stroke="#fff"
                strokeWidth="2"
              />
              <line
                y1="-1"
                x2="21.2624"
                y2="-1"
                transform="matrix(-0.706015 0.708197 0.706015 0.708197 16.7895 2.35706)"
                stroke="#fff"
                strokeWidth="2"
              />
            </svg>
          </div>
          <div className="upload_logo__title">{t.UploadLogo}</div>
          {!imgSrc && (
            <div className="gen_upload">
              <label>
                <div className="gen_btn_main gen_upload__btn">{t.SelectYourFile}</div>
                <input
                  className="gen_upload__input"
                  type="file"
                  accept=".png, .jpg"
                  onChange={onSelectFile}
                  onDragLeave={() => {
                    // onDragLeaveHandler();
                  }}
                  onDrop={() => {
                    // onDragLeaveHandler();
                  }}
                />
              </label>
              <div className="gen_upload__subtext">{t.UploadLogoText}</div>
            </div>
          )}
          {imgSrc && (
            <div className="upload_logo__crop">
              <div className={'upload_logo__crop_wrap' + (useBlackBg ? ' black_bg' : '')}>
                <UploadImageCrop imgSrc={imgSrc} previewCanvasRef={previewCanvasRef} />
              </div>
              <div className="upload_logo__cbar">
                <label className="checkbox_wrap">
                  <input
                    type="checkbox"
                    style={{ display: 'none' }}
                    value={useBlackBg}
                    onChange={() => {
                      setUseBlackBg(!useBlackBg);
                    }}
                  />
                  <span className="checkbox checkbox-color"></span>
                  {t.useBlackBg}
                </label>
                <div
                  className={'about_btn upload_logo__save' + (isSaving ? ' disabled' : '')}
                  onClick={onClickSave}
                >
                  {isSaving ? t.Saving + '...' : t.Save}
                </div>
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default UploadLogoBlock;
