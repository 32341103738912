import React, {useState, useRef, useEffect} from 'react';

const Main3_slider3 = ({isActive}) => {
	const [rendered, setRendered] = useState(false);
	const [animClass, setAnimClass] = useState('');
	const imgRef = useRef(null);

	const animate = () => {
		setRendered(true);
		setTimeout(() => {
			setAnimClass('a1');
			setTimeout(() => {
				setAnimClass('a2');
				setTimeout(() => {
					setAnimClass('a2 a3');
					setTimeout(() => {
						setAnimClass('a2 a3 a4');
						setTimeout(() => {
							setAnimClass('a2 a3 a4 a5');
						}, 600);
					}, 400);
				}, 500);
			}, 800);
		}, 200);
	}

	useEffect(() => {
		if (isActive && !rendered) animate();
	}, [isActive]);

	return(
		<div className={'main3_slide_img main3_slide_img3 ' + animClass}>
			<svg className="main3_slide_img" width="520" height="516" viewBox="0 0 520 516" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" ref={imgRef}>
				<foreignObject x="0" y="0" width="520" height="516">
					<img srcSet="/static/i/main3_slide3_1.webp, /static/i/main3_slide3_1@2x.webp 2x" loading="lazy" />
				</foreignObject>
				<g className="el3">
					<path d="M295.028 158.712H88.103V206.635H295.028V158.712Z" stroke="white" strokeWidth="0.232716" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.83 0.83"/>
					<path d="M90.1328 156.682H86.0723V160.742H90.1328V156.682Z" fill="black" stroke="white" strokeWidth="0.232716" strokeMiterlimit="10"/>
					<path d="M90.1328 204.356H86.0723V208.417H90.1328V204.356Z" fill="black" stroke="white" strokeWidth="0.232716" strokeMiterlimit="10"/>
					<path d="M296.609 156.682H292.548V160.742H296.609V156.682Z" fill="black" stroke="white" strokeWidth="0.232716" strokeMiterlimit="10"/>
					<path d="M296.609 204.356H292.548V208.417H296.609V204.356Z" fill="black" stroke="white" strokeWidth="0.232716" strokeMiterlimit="10"/>
					<path d="M193.371 156.682H189.311V160.742H193.371V156.682Z" fill="black" stroke="white" strokeWidth="0.232716" strokeMiterlimit="10"/>
					<path d="M193.371 204.356H189.311V208.417H193.371V204.356Z" fill="black" stroke="white" strokeWidth="0.232716" strokeMiterlimit="10"/>
					<path d="M178.943 197.391H104.594C101.039 197.391 98.1743 195.208 98.1743 192.52C98.1743 189.824 101.063 187.649 104.594 187.649H178.943C182.498 187.649 185.363 189.824 185.363 192.52C185.387 195.208 182.498 197.391 178.943 197.391Z" fill="#3A3C4F"/>
					<path d="M275.135 179.159H104.594C101.039 179.159 98.1743 176.976 98.1743 174.288C98.1743 171.599 101.063 169.417 104.594 169.417H275.135C278.698 169.417 281.555 171.599 281.555 174.288C281.579 176.976 278.698 179.159 275.135 179.159Z" fill="#3A3C4F"/>
				</g>
				<g className="el5">
					<path d="M412.365 232.603H262.969C258.684 232.603 255.209 236.077 255.209 240.363V338.657C255.209 342.943 258.684 346.417 262.969 346.417H412.365C416.651 346.417 420.125 342.943 420.125 338.657V240.363C420.125 236.077 416.651 232.603 412.365 232.603Z" fill="#98D4F2"/>
					<path d="M364.176 323.756L361.448 320.931L290.429 246.261L255.201 283.319L255.273 338.69C255.277 340.747 256.097 342.719 257.554 344.172C259.01 345.625 260.983 346.441 263.041 346.441L412.428 346.377C413.449 346.376 414.458 346.174 415.4 345.783C416.343 345.392 417.198 344.818 417.919 344.096C418.639 343.374 419.211 342.517 419.6 341.575C419.989 340.632 420.189 339.621 420.188 338.601V315.956L395.906 290.429L364.176 323.756Z" fill="#18AB86"/>
					<path d="M363.213 289.378C372.786 289.378 380.547 281.617 380.547 272.044C380.547 262.471 372.786 254.711 363.213 254.711C353.64 254.711 345.88 262.471 345.88 272.044C345.88 281.617 353.64 289.378 363.213 289.378Z" fill="#FFDA2B"/>
				</g>
				<foreignObject className="el4" x="85" y="235" width="146" height="80">
					<img srcSet="/static/i/main3_slide3_4.webp, /static/i/main3_slide3_4@2x.webp 2x" loading="lazy" />
				</foreignObject>
				<foreignObject className="el2" x="286" y="342" width="119" height="148">
					<img srcSet="/static/i/main3_slide3_3.webp, /static/i/main3_slide3_3@2x.webp 2x" loading="lazy" />
				</foreignObject>
				<foreignObject className="el1" x="72" y="316" width="96" height="143">
					<img srcSet="/static/i/main3_slide3_2.webp, /static/i/main3_slide3_2@2x.webp 2x" loading="lazy" />
				</foreignObject>
			</svg>
			</div>
	);
}
export default Main3_slider3;