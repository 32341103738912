import React, { useState, useEffect, useCallback } from 'react';
import { useOutletContext } from 'react-router';
import * as Sentry from '@sentry/browser';

import { useToastContext } from '@app/hooks/use-toast-provider';
import { useUploadPPTXMutation } from '@app/store/api/presentation.api';
import { serverPath } from '@app/utils/server-path';
import { analytics } from '@app/app/analytics';

import { useAppDispatch } from '@app/hooks/use-store';
import { uploadFileProgress } from '@app/store/slice/presentation.slice';

import t from '../../../lib/lng';
import { ProcessLoader } from '../../components/ui';

import SetupStyleBlock from './blocks/setup-style.block';
import UploadDraftPanel from './blocks/upload-draft/upload-draft-panel.block';

const UploadDraftPage = (props) => {
  const [isUploadProcess, setUploadProcess] = useState(false);
  const { getToast } = useToastContext();
  const { context } = useOutletContext();
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [downloadingDemo, setDownloadingDemo] = useState(false);
  const dispatch = useAppDispatch();

  // TODO: is sample add
  // const isSample = false;
  const [uploadPPTX] = useUploadPPTXMutation();

  useEffect(() => {
    if (props.isDemo) {
      tryWithSample();
    }
  }, [props.isDemo, tryWithSample]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tryWithSample = useCallback(async () => {
    setDownloadingDemo(true);
    let file = await fetch('/static/i/sample.pptx?3');
    file = await file.blob();
    file = new File([file], 'sample.pptx', {
      type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    });
    setDownloadingDemo(false);
    uploadFile(file, 'demo_file_from_button', true);
    analytics.emitEvent('service_onboarding_demofile', {
      Amplitude: {
        action: 'demo started',
      },
    });
  }, []);

  function uploadFile(file, sampleType, isSample = false, isDraft = false) {
    if (!file) return;
    //Начало загрузки пользовательского файла
    //https://docs.google.com/document/d/1s0pgnBHsJUzXmgXDjAlSFxKHD7abgXy484M5oUOij1o/edit?tab=t.0#heading=h.cdxr798t3184
    const isDemoFile = file.size === 73041;
    if (isDemoFile) {
      //https://docs.google.com/document/d/1SUH76Cz58jJfdF3MvuNESiYyUxshPgGaI01xZCGNgd4/edit?tab=t.0#heading=h.25rqy0p04wzf
      analytics.emitEvent('file_upload_demo_start', {
        Amplitude: {
          from: isSample ? 'demo file self upload' : 'demo file from button',
          type: `demo ${isDraft ? 'scratch' : 'file'}`,
        },
      });
    } else {
      //https://docs.google.com/document/d/1s0pgnBHsJUzXmgXDjAlSFxKHD7abgXy484M5oUOij1o/edit?tab=t.0#heading=h.4kurkeiq4ag
      analytics.emitEvent('file_upload_start', {
        Amplitude: {
          'file extension': 'pptx',
        },
      });
    }
    const handleError = ({ error }) => {
      let msg = error || t.oopsSomethingBroke;
      if (msg === 'Payload Too Large') msg = t.maximumSizeExceeded;
      console.error('[UPLOAD ERROR]', error);
      getToast({
        systemMessage: {
          type: 'error',
          msg: msg,
        },
      });
      if (isSample) {
        analytics.emitEvent('demo_file_upload', {
          GTM: {
            file_upload_status: 'demo_file_upload_error',
            demo_file_place: 'demo_file_from_button',
          },
        });
      } else {
        analytics.emitEvent('file_upload', {
          GTM: {
            file_upload_status: 'file_upload_error',
          },
        });
      }

      Sentry.captureException({
        function: 'uploadFile',
        msg: msg,
        file_name: file ? file.name : '',
        file_size: file ? file.size : '',
      });
    };

    const splitName = file.name.split('.');
    if (splitName[splitName.length - 1] !== 'pptx') {
      getToast({
        systemMessage: {
          type: 'error',
          msg: t.weSupportOnlyPPTX,
        },
      });
      return;
    }
    setUploadProcess(true);
    const formData = new FormData();
    formData.append('file', file);
    dispatch(uploadFileProgress({ fileName: file.name, uploadProgress: 0 }));
    uploadPPTX({
      url: isSample ? `${serverPath.upload}?sample` : serverPath.upload,
      data: formData,
    })
      .unwrap()
      .then((responseFileUpload) => {
        if (responseFileUpload.status) {
          setCurrentProjectId(responseFileUpload.result.project_id);
          //navigate(`${route_path.setupStyle}${responseFileUpload.result.project_id}`);
          if (!isDemoFile) {
            analytics.emitEvent('file_upload', {
              GTM: {
                file_upload_status: 'file_upload_success',
              },
            });
            // https://docs.google.com/document/d/1s0pgnBHsJUzXmgXDjAlSFxKHD7abgXy484M5oUOij1o/edit?tab=t.0#heading=h.s21r8j229pcf
            analytics.emitEvent('file_uploaded', {
              Amplitude: {
                status: 'uploaded',
                message: '',
              },
            });
          } else {
            analytics.emitEvent('demo_file_upload', {
              GTM: {
                file_upload_status: 'demo_file_upload_success',
                demo_file_place: sampleType,
              },
            });
          }
        } else {
          handleError(responseFileUpload.error);
        }
      })
      .catch((error) => {
        handleError(error);
      });
    if (file.size > context?.upload_max_size) {
      getToast({
        systemMessage: {
          type: 'error',
          msg: t.formatString(t.FileSizeShouldNotExceed, context?.upload_max_size / 1024 / 1024),
        },
      });
      document.getElementById('pptxInput').value = '';
      return;
    }
  }
  function submitDragEnter(e) {
    e.stopPropagation();
    e.preventDefault();
  }
  function onDropHandler(event) {
    event.stopPropagation();
    event.preventDefault();
    const droppedFile = event.dataTransfer.files?.[0];
    if (droppedFile) uploadFile(droppedFile, 'demo_file_self_load', true);
  }

  if (downloadingDemo) {
    return <ProcessLoader />;
  }

  return (
    <div className="gen_main text-wf">
      {!isUploadProcess ? (
        <>
          <div className="gen_content">
            <form
              id="form-file-upload"
              onDragEnter={submitDragEnter}
              onDrop={onDropHandler}
              onDragOver={submitDragEnter}
              onSubmit={(e) => uploadFile(e.target.files[0], 'demo_file_self_load', true)}
            >
              <label id="label-file-upload" htmlFor="pptxInput">
                <div className="gen_upload">
                  <div className="gen_upload__text">{t.uploadDraftOfYourPresentation}</div>
                  <div className="block w-full h-full">
                    <div className="gen_btn_main gen_upload__btn">
                      <span>{t.formatString(t.chooseFile, <span>&nbsp;</span>)}</span>
                    </div>
                    <input
                      className="gen_upload__input"
                      id="pptxInput"
                      type="file"
                      accept=".pptx"
                      onChange={(e) => {
                        uploadFile(e.target.files[0], 'demo_file_self_load', true);
                      }}
                    />
                  </div>
                  <div className="gen_upload__subtext">
                    <div>
                      {t.selectPptxFileOrDrag}
                      <a href="/static/i/sample.pptx?2">{t.exampleFile}</a>.
                      <div className="gen_upload__info">
                        <div className="gen_upload__info_text">{t.uploadInfoText}</div>
                      </div>
                    </div>
                    <div>
                      {t.formatString(
                        t.ChooseDraft30Mb,
                        <strong>{t.draft}</strong>,
                        <strong>{t._30slides}</strong>,
                      )}
                    </div>
                  </div>
                </div>
              </label>{' '}
            </form>
            <div className="f_onboarding">
              <div className="f_onboarding_title">Let’s collect the content, don’t design:</div>
              <div className="f_onboarding_items">
                <div className="f_onboarding_item">
                  <img
                    className="f_onboarding_item__img"
                    srcSet="/static/i/onboarding1.webp, /static/i/onboarding1@2x.webp 2x"
                    alt=""
                  />
                  <div className="f_onboarding_item__title">
                    Keep it simple! One slide - one thought, don't skimp on the slides!
                  </div>
                </div>
                <div className="f_onboarding_item">
                  <img
                    className="f_onboarding_item__img"
                    srcSet="/static/i/onboarding2.webp, /static/i/onboarding2@2x.webp 2x"
                    alt=""
                  />
                  <div className="f_onboarding_item__title">Don't use background images.</div>
                </div>
                <div className="f_onboarding_item">
                  <img
                    className="f_onboarding_item__img"
                    srcSet="/static/i/onboarding3.webp, /static/i/onboarding3@2x.webp 2x"
                    alt=""
                  />
                  <div className="f_onboarding_item__title">Use bulletpoints for your ideas.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="gen_panel a_shake_bubble a_fadein_shapes a_fadein_others">
            <UploadDraftPanel />
          </div>
        </>
      ) : (
        <SetupStyleBlock
          isPPTXUpload={true}
          projectId={currentProjectId ? String(currentProjectId) : null}
        />
      )}
    </div>
  );
};

export default UploadDraftPage;
