import React, { useContext, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import t from '@lib/lng';

import { SlideEditContent } from '../project-id.page';

import SlideMenu from './slide-menu.block';
import DebugInfo from './debug-info.block';

const LANG = {
  error_slide_creation: 'Slide creation error. Try again later or contact us.',
  email: 'support@wonderslide.com',
};

const SlideErrorBlock = (props) => {
  const { errors, slideIndex, projectId, noDelete } = props;
  const [showDebugInfo, setShowDebugInfo] = useState(null);
  const { presentation } = useContext(SlideEditContent);

  return (
    <>
      <div className="gen_result__item">
        <div className="create_slide_number gen_result__show_debug">
          <span
            onClick={() => {
              setShowDebugInfo(slideIndex);
            }}
          >
            {t.slide} {Number(slideIndex + 1)}
          </span>
          <SlideMenu
            slideIndex={slideIndex}
            projectId={projectId}
            noDelete={!noDelete}
            noClone={true}
          />
        </div>
        <div className="gen_result__wrap">
          <div className="gen_message gen_message-slide error">
            <div className="gen_message__text">
              <ul className="list-none">
                {errors?.length ? (
                  errors?.map((errorMessage, messageIndex) => {
                    return (
                      <li
                        key={messageIndex}
                        className="text-[14px] text-general_background_MIII_400_dark"
                      >
                        {errorMessage}
                        {errorMessage === LANG.error_slide_creation && (
                          <div className="text-[12px] text-secondary_error_SI_600_error block whitespace-pre-wrap">
                            {t.formatString(t.slideErrorText, <span>&nbsp;</span>)}
                            <br />
                            <a href={`mailto:${LANG.email}`}>{LANG.email}</a>
                          </div>
                        )}
                      </li>
                    );
                  })
                ) : (
                  <li className="text-[14px] text-general_background_MIII_400_dark">
                    <div className="text-[12px] text-secondary_error_SI_600_error block whitespace-pre-wrap">
                      {t.formatString(t.slideErrorText, <span>&nbsp;</span>)}
                      <br />
                      <a href={`mailto:${LANG.email}`}>{LANG.email}</a>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {showDebugInfo !== null && (
        <AnimatePresence>
          <motion.div
            className="w-full h-full inset-0 fixed z-[9999]"
            initial={{ scale: 0 }}
            animate={{ scale: '100%' }}
            exit={{ scale: 0 }}
          >
            <DebugInfo
              queue={presentation?.queue}
              slideIndex={slideIndex}
              close={() => setShowDebugInfo(null)}
            />
          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
};

export default SlideErrorBlock;
