import React from 'react';

import t from '../../../../lib/lng';

const DebugInfo = (props) => {
  const { queue, slideIndex, close } = props;
  const stageError = queue[slideIndex].filter((stage) => !stage.status && stage.error)[0];
  let debugInfo = {};
  for (const stage in queue[slideIndex]) {
    const debug = queue[slideIndex][stage].debug;
    for (const debugKey in debug) {
      debugInfo[debugKey] = debug[debugKey];
    }
  }

  function renderDebugInfo(debugItem) {
    if (!debugItem) return;
    if (typeof debugItem == 'object' && !Array.isArray(debugItem)) {
      return (
        <ul className="text-left">
          {Object.keys(debugItem).map((key, i) => (
            <li className="text-left" key={i}>
              <strong>{key}</strong>: {renderDebugInfo(debugItem[key])}
            </li>
          ))}
        </ul>
      );
    } else if (Array.isArray(debugItem)) {
      return (
        <ul>
          {debugItem.map((item, i) => (
            <li key={i}>{renderDebugInfo(item)}</li>
          ))}
        </ul>
      );
    } else {
      return debugItem;
    }
  }

  return (
    <div className="modal debug_info z-[9999]">
      <div className="modal_overlay" onClick={close}></div>
      <div className="modal_window">
        <div className="modal_close" onClick={close}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
            <line
              y1="-1"
              x2="21.2624"
              y2="-1"
              transform="matrix(0.706015 0.708197 -0.706015 0.708197 1 2.35706)"
              stroke="black"
              strokeWidth="2"
            />
            <line
              y1="-1"
              x2="21.2624"
              y2="-1"
              transform="matrix(-0.706015 0.708197 0.706015 0.708197 16.7895 2.35706)"
              stroke="black"
              strokeWidth="2"
            />
          </svg>
        </div>
        <div className="debug_info_title">{t.debugInformation}</div>
        {stageError && stageError.error.message}
        <ul>{renderDebugInfo(debugInfo)}</ul>
      </div>
    </div>
  );
};

export default DebugInfo;
