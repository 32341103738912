import { createApi } from '@reduxjs/toolkit/query/react';
import { appBaseQueryWithResend } from '@app/store/api/base-query';
import { serverPath } from '@app/utils/server-path';

const paymentApi = createApi({
  reducerPath: 'paymentApi',
  tagTypes: ['PAYMENT'],
  baseQuery: appBaseQueryWithResend,
  endpoints: (build) => ({
    cancelStripeSubscription: build.query({
      query: () => ({
        url: `${serverPath.stripe.stripe_subscription_cancel}`,
      }),
      providesTags: ['PAYMENT'],
    }),
  }),
});

export const { useCancelStripeSubscriptionQuery, useLazyCancelStripeSubscriptionQuery } =
  paymentApi;
export default paymentApi;
