import React, { FC, ReactNode } from 'react';
import cl from 'classnames';

type SkeletonProps = {
  width?: string;
  height?: string;
  className?: string;
};

const Skeleton: FC<SkeletonProps> = (props) => {
  const { width = '100%', height = '20px', className } = props;
  return (
    <div
      className={cl('rounded-[20px] animate-pulse bg-general_background_MIII_400_dark', className)}
      style={{ width, height }}
    />
  );
};

export default Skeleton;
