import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '@app/hooks/use-store';
import { motion } from 'framer-motion';
import { useAnimatedSymbol } from '@app/hooks/use-animated-ellipsis';

type UploadProcessBlockProps = {
  uploadingFileName?: string;
};

const UploadProcessBlock: FC<UploadProcessBlockProps> = () => {
  const { fileUpload } = useAppSelector((state) => state.presentationState);
  const { uploadProgress, fileName } = fileUpload;
  const animatedLoadingText = useAnimatedSymbol('', '.', 500);

  const [isVisible, setIsVisible] = useState(!!fileName);

  const slideUpAnimation = {
    initial: { opacity: 0, bottom: -140 },
    animate: { opacity: 1, bottom: 20 },
    exit: { opacity: 0, bottom: -140 },
  };

  useEffect(() => {
    if (fileName) {
      setIsVisible(true);
    }
    if (uploadProgress === 100) {
      const timer = setTimeout(() => setIsVisible(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [fileName, uploadProgress]);

  if (!fileName) return null;

  return (
    <motion.div
      className="fixed right-5 bottom-5 m-auto bg-general_background_MIII_600_dark z-[100] py-4 px-6 rounded-lg"
      style={{ width: 'min(100%, 400px)' }}
      initial={slideUpAnimation.initial}
      animate={isVisible ? slideUpAnimation.animate : slideUpAnimation.exit}
      transition={{ duration: 0.5 }}
    >
      <div className="w-full h-[87px]">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-1">
            <span className="text-general_ico_MIV_500_hover font-bold text-[16px] leading-[19px] flex items-start min-h-6">
              Loading {uploadProgress}%
              {uploadProgress === 100 && <span>{animatedLoadingText}</span>}
            </span>
            <span className="text-general_text_MII_secondary_ii text-sm">{fileName}</span>
          </div>
          <div className="bg-general_background_MIII_450_dark rounded-md relative h-[16px]">
            <div
              className="absolute left-0 top-0 bottom-0 bg-secondary_success_SI_600_success rounded-md"
              style={{ width: uploadProgress + '%' }}
            ></div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default UploadProcessBlock;
