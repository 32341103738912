import { useOutletContext } from 'react-router';

import { Skeleton } from '@app/components/ui';

import ThemeItem from './theme-item.block';
import { useEffect } from 'react';

const renderThemesSkeleton = ({ countItems = 10 } = {}) => {
  return Array.from({ length: countItems }).map((_, index) => (
    <div key={index} className="flex flex-col gap-3">
      <Skeleton className="min-w-72 min-h-40" />
      <Skeleton className="max-w-48 min-h-5" />
    </div>
  ));
};

export const GenThemes = ({
  selectedTheme,
  setSelectedTheme,
  themes,
  isLoadingThemes,
  isThemesError,
  refetchThemes,
}) => {
  const {
    setTrialPopup,
    context: {
      product_balance: { product },
    },
  } = useOutletContext();

  const handleSelect = (isLocked, themeName) => {
    if (isLocked) {
      setTrialPopup({ type: 'style' });
    } else {
      setSelectedTheme(themeName);
    }
  };

  //Установка первой доступной темы для пользователя
  useEffect(() => {
    if (!selectedTheme && themes.length > 0) {
      const firstUnlockedTheme = themes.find((theme) => !(product?.is_trial && theme.is_premium));

      if (firstUnlockedTheme) {
        setSelectedTheme(firstUnlockedTheme.name);
      }
    }
  }, [themes, selectedTheme, setSelectedTheme, product]);

  const renderThemeList = () => {
    return (
      <>
        {themes.map((theme) => {
          const isPremium = theme.is_premium;
          const isLocked = product?.is_trial && isPremium;

          return (
            <div key={theme.id}>
              <ThemeItem
                key={theme.id}
                theme={theme}
                isActive={selectedTheme?.toLowerCase() === theme.name?.toLowerCase()}
                isLocked={isLocked}
                select={() => handleSelect(isLocked, theme.name)}
                style={{
                  width: '100%',
                  zIndex: 1000,
                }}
              />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="z-[0]">
      <h1 className="leading-[22px] text-general_text_MII_secondary_ii m-0 mb-6 text-left">
        {!isThemesError && 'Choose the presentation style'}
      </h1>
      <div className="gen_styles_list pb-16">
        {isLoadingThemes ? renderThemesSkeleton({ countItems: 25 }) : renderThemeList()}
      </div>
      {isThemesError && (
        <div>
          Error loading themes 😔,{' '}
          <span
            className="text-secondary_success_SI_400_success cursor-pointer"
            onClick={refetchThemes}
          >
            try again
          </span>
        </div>
      )}
    </div>
  );
};
