import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import { cookie } from '@app/app/lib/cookie';
import { analytics } from '@app/app/analytics';

import t from '../lib/lng.js';
import { route_path } from './utils/route_path';
import ErrorBoundary from './common/errorBoundary';
import { WithoutHeaderLayout } from './components/layout';
import DefaultLayout from './components/layout/default';
import { ToastProvider } from './hooks/use-toast-provider';
import { Provider } from 'react-redux';
import { store } from './store';

import {
  ProjectIdPage,
  SetupStylePage,
  CommonOutlet,
  CreateStartPage,
  UploadDraftPage,
  ProjectsPage,
  LoginPage,
  MainPage,
  PricingPage,
} from './pages';

const LegalPage = lazy(() => import('./pages/legal/legal.page'));
const TermsPage = lazy(() => import('./pages/legal/terms.page'));
const PrivacyPage = lazy(() => import('./pages/legal/privacy.page'));
const CookiePage = lazy(() => import('./pages/legal/cookie.page'));
const FeaturesPage = lazy(() => import('./pages/features/features.page'));
const AboutPage = lazy(() => import('./pages/about/about.page'));
// const Main = lazy(() => import('./main/main.js'));
// const Pricing = lazy(() => import('./pricing/pricing.js'));
const Wondercheck = lazy(() => import('./wondercheck/wondercheck.js'));
// const Login = lazy(() => import());
const SharedFile = lazy(() => import('./sharing/SharedFile.js'));
const PasswordReset = lazy(() => import('./auth/passwordReset.js'));
const PasswordResetConfirm = lazy(() => import('./auth/passwordResetConfirm.js'));
const CookiePopup = lazy(() => import('./common/cookiePopup.js'));
const PaymentCancelNotify = lazy(() => import('./modals/paymentCancelNotify.js'));
const PaymentSuccessNotify = lazy(() => import('./modals/paymentSuccessNotify.js'));
const RegistrationPage = lazy(() => import('./pages/registration/registration.page.js'));
const Error404Page = lazy(() => import('./pages/error/error404.page'));

const metaData = {
  '/': {
    title: 'Wonderslide - Fast AI Presentation Maker',
    description: 'Wonderslide is a fast and easy way to design stunning presentations.',
  },
  '/pricing/': {
    title: 'Wonderslide Pricing',
    description:
      'Wonderslide makes it easy to create professional-looking presentations without breaking the bank.',
  },
  '/about/': {
    title: 'Wonderslide About',
    description:
      'Wonderslide makes it easy to create professional-looking presentations without breaking the bank.',
  },
  '/features/': {
    title: 'Wonderslide Features',
    description:
      'Wonderslide makes it easy to create professional-looking presentations without breaking the bank.',
  },
};

export default function app() {
  // V18
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(<App />);
  // V17
  // ReactDOM.render((
  // 	<App />
  // ), document.getElementById('root'));
}

let prevLocation = {};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCookiePopup: !cookie.get('cookie_accepted'),
      showPaymentSuccessNotify: false,
      showPaymentErrorNotify: false,
      showPaymentCancelNotify: false,
      paymentCancelNotifyBackUrl: '',
      logout: false,
    };
  }

  componentDidMount() {
    if (window.location.search.includes('success')) {
      this.setState({ showPaymentSuccessNotify: true });
      window.history.replaceState({}, document.title, location.href.replace(/(\?|&)+success/, ''));
    } else if (window.location.search.includes('error')) {
      this.setState({ showPaymentErrorNotify: true });
      window.history.replaceState({}, document.title, location.href.replace(/(\?|&)+error/, ''));
    } else {
      if (window.location.search.includes('cancel')) {
        this.setState({ showPaymentCancelNotify: true });
        window.history.replaceState({}, document.title, location.href.replace(/(\?|&)+cancel/, ''));
      }
      if (window.sessionStorage.getItem('gotoProduct')) {
        this.setState({
          showPaymentCancelNotify: true,
          paymentCancelNotifyBackUrl: JSON.parse(window.sessionStorage.getItem('gotoProduct')).url,
        });
      }
    }
    window.sessionStorage.removeItem('gotoProduct');
  }

  updateSharedFile(data) {
    let state = {
      ...this.state,
      context: { ...this.state.context, shared_file: data },
    };
    this.setState(state);
  }

  render() {
    const { context, logout } = this.state;
    return (
      <ErrorBoundary>
        <Suspense fallback={<div style={{ background: '#191B20', height: '100vh' }}></div>}>
          <Provider store={store}>
            <ToastProvider>
              <BrowserRouter basename="/">
                <RoutesContainer
                  context={context}
                  updateSharedFile={::this.updateSharedFile}
                  logout={logout}
                />
                {this.state.showCookiePopup && (
                  <CookiePopup
                    close={() => {
                      this.setState({
                        showCookiePopup: false,
                      });
                    }}
                  />
                )}
                {this.state.showPaymentSuccessNotify && (
                  <PaymentSuccessNotify
                    close={() => {
                      this.setState({
                        showPaymentSuccessNotify: false,
                      });
                    }}
                  />
                )}
                {this.state.showPaymentErrorNotify && (
                  <PaymentErrorNotify
                    close={() => {
                      this.setState({
                        showPaymentErrorNotify: false,
                      });
                    }}
                  />
                )}
                {this.state.showPaymentCancelNotify && (
                  <PaymentCancelNotify
                    close={() => {
                      this.setState({
                        showPaymentCancelNotify: false,
                      });
                    }}
                    backUrl={this.state.paymentCancelNotifyBackUrl}
                  />
                )}
              </BrowserRouter>
            </ToastProvider>
          </Provider>
        </Suspense>
      </ErrorBoundary>
    );
  }
}

function RoutesContainer({ context, updateSharedFile, logout }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    setMetaData(location.pathname);
    scrollToAnchor(location.hash);
    updateViewport(location.pathname);

    if (!location.pathname.match(/\/create\//)) window.sessionStorage.removeItem('genState');
    if (!location.pathname.match(/\/create-draft\//))
      window.sessionStorage.removeItem('draftState');

    if (location.pathname.match(/upload-draft/) && !location.search.includes('demo')) {
      analytics.emitEvent('file_upload_open', {
        GTM: {},
        Amplitude: {},
      });
    }

    prevLocation = location;
  }, [location.pathname, location.hash]);

  return (
    <Routes>
      <Route
        path={route_path.main}
        element={<CommonOutlet updateSharedFile={updateSharedFile} logout={logout} />}
      >
        <Route index element={<MainPage />} />
        {/***** Page features *****/}
        <Route path={route_path.login} element={<LoginPage />} />
        <Route path={route_path.main} element={<WithoutHeaderLayout />}>
          {/***** Page about *****/}
          <Route path={route_path.about} element={<AboutPage />} />
          <Route path={route_path.features} element={<FeaturesPage />} />
        </Route>
        {/* REDUX UPDATE */}
        <Route path={route_path.main} element={<DefaultLayout />}>
          {/* PRICING */}
          <Route path={route_path.pricing} element={<PricingPage />} />
          {/* START CREATE PAGE */}
          <Route
            path={route_path.create}
            element={<CreateStartPage />}
            /*<Create page="create" context={context} navigate={navigate} updateData={updateData} isDemo={location.search.includes('demo') ? true : false} />*/
          />
          {/* CREATE PRESENTATION AND SELECT STYLE */}
          <Route path={`${route_path.setupStyle}:projectId/`} element={<SetupStylePage />} />
          {/* UPLOAD DRAFT PAGE */}
          <Route
            path={`${route_path.uploadDraft}`}
            element={<UploadDraftPage isDemo={location.search.includes('demo')} />}
          />
          {/* PROJECT PAGE */}
          <Route path={`${route_path.project}:projectId/`} element={<ProjectIdPage />} />
          {/* PRESENTATIONS */}
          <Route path={route_path.projects} element={<ProjectsPage />} />
        </Route>
        <Route path="/wondercheck/*" element={<Wondercheck />} />
        {/***** Page LEGAL ROUTE *****/}
        <Route path={route_path.legal} element={<DefaultLayout context={context} />}>
          <Route index element={<LegalPage />} />
          <Route path={route_path.legal_terms} element={<TermsPage />} />
          <Route path={route_path.legal_cookie} element={<CookiePage />} />
          <Route path={route_path.legal_privacy} element={<PrivacyPage />} />
        </Route>

        {/***** REGISTRATION  *****/}
        <Route path={route_path.register} element={<RegistrationPage context={context} />} />
        <Route path="/password-reset/" element={<PasswordReset context={context} />} />
        <Route path="/password-reset/:token" element={<PasswordResetConfirm context={context} />} />
        <Route
          path="/s/*"
          element={<SharedFile context={context} updateSharedFile={updateSharedFile} />}
        />
      </Route>
      <Route path="*" element={<Error404Page />} />
    </Routes>
  );
}

function setMetaData(pathname) {
  if (metaData[pathname]) {
    document.querySelector('title').innerText = metaData[pathname].title;
    document.querySelector('meta[name="description"]').content = metaData[pathname].description;
  } else if (!pathname.match('/s/')) {
    document.querySelector('title').innerText = 'Wonderslide';
    document.querySelector('meta[name="description"]').content = '';
  }
}

function scrollToAnchor(hash) {
  if (!hash) return;
  setTimeout(() => {
    const el = document.getElementById(hash.replace('#', ''));
    if (el) el.scrollIntoView();
  }, 200);
}

//TODO: убрать / рефактор так как тут блокируется адаптив для мобилок
function updateViewport(pathname) {
  if (window.innerWidth > 1024 || !pathname) return;
  if (pathname.match(/\/create|files/)) {
    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=1024');
  } else if (!document.querySelector('meta[name="viewport"]').content.match(/width=device-width/)) {
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute('content', 'width=device-width, initial-scale=1');
  }
}

const PaymentErrorNotify = ({ close }) => {
  return (
    <div className="modal">
      <div className="modal_overlay" onClick={close}></div>
      <div className="modal_window">
        <div className="modal_close" onClick={close}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
            <line
              y1="-1"
              x2="21.2624"
              y2="-1"
              transform="matrix(0.706015 0.708197 -0.706015 0.708197 1 2.35706)"
              stroke="black"
              strokeWidth="2"
            />
            <line
              y1="-1"
              x2="21.2624"
              y2="-1"
              transform="matrix(-0.706015 0.708197 0.706015 0.708197 16.7895 2.35706)"
              stroke="black"
              strokeWidth="2"
            />
          </svg>
        </div>
        <div className="modal_msg">
          {t.paymentErrorMsg1}
          <br />
          {t.paymentErrorMsg2}
        </div>
      </div>
    </div>
  );
};
