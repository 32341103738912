import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import t from '@lib/lng';
import { loadScript } from '@lib/utils';
import { cookie } from '@app/app/lib/cookie';

import { RegisterQuiz, RegisterTemplate } from '../../components/template';

const GOOGLE_GSI_PATH = 'https://accounts.google.com/gsi/client';
const GOOGLE_LOGIN_LINK = '/social/login/google-oauth2/';

const RegistrationPage = () => {
  const [authType, setAuthType] = useState('google');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [regSuccess, setIsRegSuccess] = useState(false);

  useEffect(() => {
    loadScript(GOOGLE_GSI_PATH);
  }, []);

  function quizCloseHandler() {
    // eslint-disable-next-line compat/compat
    let url = new URL(window.location.href);
    let next = url.searchParams.get('next');
    if (next) {
      url.searchParams.delete('next');
      url.pathname = next;
      window.location.href = url.pathname;
      // use location.href because urls like /purchase/ are not handled by react
    } else {
      window.location.href = `${window.location.protocol}//${window.location.host}/create/`;
    }
  }

  async function submitForm(e) {
    e.preventDefault();
    e.target.elements.password2.value = e.target.elements.password1.value;
    let body = new FormData(e.target);
    body.append('email_notify', document.getElementById('email_notify').checked);

    try {
      setIsFetching(true);
      let response = await fetch('/register/', {
        method: 'POST',
        body: body,
        headers: { 'X-Requested-With': 'XMLHttpRequest', 'X-CSRFToken': cookie.get('csrftoken') },
      }).finally(() => setIsFetching(false));

      let result = await response.json();

      if (result.status) {
        window.sessionStorage.setItem(
          'reg',
          JSON.stringify({
            send_marketing_email: document.getElementById('email_notify').checked
              ? 'allow'
              : 'deny',
          }),
        );
        setIsRegSuccess(true);
      } else {
        if (result.errors) {
          setErrors(result.errors);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
  function googleRegisterHandler() {
    window.sessionStorage.setItem('reg', String(new Date().getTime()));
    window.sessionStorage.setItem(
      'auth_by_google',
      JSON.stringify({
        send_marketing_email: document.getElementById('email_notify').checked ? 'allow' : 'deny',
      }),
    );
  }

  return (
    <RegisterTemplate authType={authType}>
      <div className="auth_menu">
        <Link
          className="auth_menu_item"
          to={'/login/' + (window.location.search.includes('?next=') ? window.location.search : '')}
        >
          {t.Login}
        </Link>
        <span className="auth_menu_item active">{t.Signup}</span>
      </div>
      {regSuccess ? (
        <RegisterQuiz close={quizCloseHandler} />
      ) : (
        <>
          <div className="auth_title">Register for free!</div>

          {authType === 'email' && (
            <form
              className="auth_form"
              onSubmit={(e) => {
                submitForm(e);
              }}
            >
              <div className="auth_row">
                <input
                  className="auth_input"
                  type="email"
                  pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                  name="username"
                  onClick={() => {
                    setErrors(null);
                  }}
                  placeholder={t.Email}
                  required
                  autoFocus
                />
                {errors && errors.username && (
                  <div className="auth_error">{errors.username[0]}</div>
                )}
              </div>

              <div className="auth_row">
                <input
                  className="auth_input"
                  type={showPassword ? 'text' : 'password'}
                  name="password1"
                  onClick={() => {
                    setErrors(null);
                  }}
                  placeholder={t.Password}
                  required
                />
                <span
                  className="auth_show_pwd"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                ></span>
                <input type="hidden" name="password2" />
                {errors && errors.password2 && (
                  <div className="auth_error">{errors.password2[0]}</div>
                )}
              </div>

              <input type="hidden" name="birthday" value="1970-01-01" />

              <button
                className={`auth_submit gen_btn_main ${isFetching ? 'disabled' : ''}`}
                type="submit"
                disabled={isFetching}
              >
                {t.Signup}
              </button>

              {
                errors && errors?.error_context && (
                  <div className="auth_error">{errors?.error_context?.[0]}</div>
                ) // TODO fix
              }
            </form>
          )}

          <a
            className="auth_google_btn"
            href={GOOGLE_LOGIN_LINK + window.location.search}
            onClick={googleRegisterHandler}
          >
            {t.ContinueWithGoogle}
          </a>

          {authType === 'google' && (
            <span
              className="auth_by_email about_plain_link"
              onClick={() => {
                setAuthType('email');
              }}
            >
              {t.SignupByEmail}
            </span>
          )}

          <div className="auth_policy">
            {t.ByContinuingYouAgreeTo} <br />
            <span className="inline-flex gap-[6px]">
              <Link className="about_plain_link" to="/legal/terms">
                {t.TermsOfUse}
              </Link>{' '}
              {t.and}{' '}
              <Link className="about_plain_link" to="/legal/privacy">
                {t.PrivacyPolicy}
              </Link>
            </span>
          </div>

          <label className="auth_agree">
            <input type="checkbox" id="email_notify" defaultChecked className="hidden" />
            <span className="checkbox checkbox-square"></span>
            <div className="auth_agree_label">{t.IAgreeToReceiveEmails}</div>
          </label>
        </>
      )}
    </RegisterTemplate>
  );
};

export default RegistrationPage;
