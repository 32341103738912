import React from 'react';

const CongratulationIcon = ({ size }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 65 64"
        >
            <path
                fill="currentColor"
                d="M18.944 21.343L.5 64l42.657-18.444-24.213-24.213zM8.517 55.983l1.966-4.546.558 3.455-2.524 1.09zm6.479-2.802l-1.434-8.867 4.185-9.677 2.622 16.221-5.373 2.323zm9.328-4.033l-3.186-19.704 6.858 6.858 1.701 10.523-5.373 2.323zm8.72-7.799l2.812 2.813-2.204.953-.609-3.766zM43.341 18.205l-8.51 8.51 2.953 2.954 8.51-8.51-2.953-2.954zM43.849 31.673c-2.926.667-5.569 2.116-7.644 4.19l2.954 2.954c3.405-3.405 8.242-3.914 11.525-2.909l1.224-3.994c-2.508-.768-5.37-.854-8.059-.241zM32.585 12.592l-3.994 1.224c1.006 3.283.497 8.12-2.908 11.525l2.953 2.954c2.075-2.075 3.524-4.719 4.19-7.644.614-2.689.528-5.55-.24-8.059zM56.328 13.152l-3.843-1.137-1.136-3.843-2.906 2.76-3.897-.938.937 3.897-2.76 2.906 3.843 1.136 1.137 3.843 2.906-2.76 3.896.938-.937-3.897 2.76-2.905zM64.5 33.499l-3.843-1.137-1.137-3.843-2.906 2.76-3.896-.937.937 3.897-2.76 2.905 3.843 1.137 1.137 3.843 2.906-2.76 3.896.937-.937-3.896 2.76-2.906zM35.98 4.98l-3.842-1.137L31 0l-2.906 2.76-3.896-.937.937 3.896-2.76 2.906 3.843 1.137 1.137 3.842 2.905-2.76 3.897.938-.937-3.896 2.76-2.906z"
            ></path>
        </svg>
    );
};

export default CongratulationIcon;