import React from 'react';

const ArrowLeftIcon = ({size}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 36 36"
        >
            <path
                fill="currentColor"
                d="M21.885 32.115L10.437 20.667H36v-5.334H10.437L21.885 3.885l-3.77-3.77L.229 18l17.886 17.885 3.77-3.77z"
            ></path>
        </svg>
    );
};

export default ArrowLeftIcon;