import React, { useEffect, useRef, useState } from 'react';
import { convertFromRaw, convertToRaw, Editor, EditorState, RichUtils } from 'draft-js';

import { useOutletContext } from 'react-router';

import { assetThemes } from '@app/data';

import Toolbar from './toolbar.block';

const SlideDraftEditorBlock = (props) => {
  const {
    slideText,
    onChangeText,
    themeOption,
    onboardingPermissionRef,
    // setSlideText
  } = props;
  const { context, onboardingMessage } = useOutletContext();
  const constraints =
    context?.options?.constraints &&
    context?.options?.constraints?.[assetThemes?.[themeOption?.asset_theme]?.origin];
  const [editorStateParent, setEditorStateParent] = useState('');
  // const [initalEditorState, setInitalEditorState] = useState();
  const draftEditorRef = useRef(null);
  let blocks = Array.isArray(slideText) ? slideText : [];

  if (slideText.title) {
    blocks.push({
      key: 'title',
      text: slideText.title.replaceAll('\n', ''),
      type: 'header-one',
    });
  }

  if (slideText.subtitle) {
    blocks.push({
      key: 'subtitle',
      text: slideText.subtitle.replaceAll('\n', ''),
      type: 'header-two',
    });
  }

  if (slideText.bullet) {
    slideText.bullet.forEach((bullet, i) => {
      if (constraints && constraints?.single_bullet && i < constraints?.single_bullet) {
        blocks.push({
          key: 'bullet' + i,
          text: bullet.replaceAll('\n', ''),
          type: 'unordered-list-item',
        });
      }
    });
  }
  if (slideText?.contenttitle?.length) {
    slideText.contenttitle.forEach((contenttitle, i) => {
      blocks.push({
        key: 'contenttitle' + i,
        text: contenttitle.replaceAll('\n', ''),
        type: 'header-three',
      });
      if (slideText.group_bullet[i]) {
        slideText.group_bullet[i].forEach((bullet, j) => {
          blocks.push({
            key: 'bullet_group' + i + j,
            text: bullet.replaceAll('\n', ''),
            type: 'unordered-list-item',
          });
        });
      }
    });
  } else {
    if (slideText?.group_bullet?.length) {
      slideText.group_bullet[0].forEach((bullet, j) => {
        blocks.push({
          key: 'bullet' + j,
          text: bullet.replaceAll('\n', ''),
          type: 'unordered-list-item',
        });
      });
    }
  }

  if (slideText?.note) {
    blocks.push({
      key: 'note',
      text: slideText.note.replaceAll('\n', ''),
      type: 'header-six',
    });
  }

  if (!blocks.length) {
    blocks.push({
      key: 'title',
      text: '',
      type: 'header-one',
    });
  }
  const initialState = EditorState.createWithContent(
    convertFromRaw({
      blocks: blocks,
      entityMap: {},
    }),
  );

  const [editorState, setEditorState] = useState(initialState);
  const [singleBulletsCount, setSingleBulletsCount] = useState(0);
  const editor = useRef(null);

  useEffect(() => {
    focusEditor();
    //onChangeText(convertToRaw(editorState.getCurrentContent()).blocks, 'toPlain');
    // setInitalEditorState(convertToRaw(editorState.getCurrentContent()).blocks);
  }, []);

  const focusEditor = () => {
    editor.current.focus();
  };

  const applyStyle = (e, type) => {
    e.preventDefault();
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const contentState = editorState.getCurrentContent();
    const contentStateRaw = convertToRaw(contentState);
    const currentContentBlock = contentState.getBlockForKey(anchorKey);
    const currentSelection = editorState.getSelection();

    if (type === currentContentBlock.type) return;

    if (type.match(/header-one|header-two|header-six/)) {
      contentStateRaw.blocks.forEach((item) => {
        if (item.key === currentContentBlock.key) {
          // if (type === 'header-three' && currentContentBlock.type === 'header-three') return;
          // if (type === currentContentBlock.type) type = 'header-three';
          item.type = type;
        }
      });
      setEditorStateParent(contentStateRaw.blocks);
      let newState = EditorState.push(
        editorState,
        convertFromRaw(contentStateRaw),
        'change-block-type',
      );
      newState = EditorState.forceSelection(newState, currentSelection);
      setEditorState(newState);
      setEditorStateParent(convertToRaw(newState.getCurrentContent()).blocks);
    } else {
      // const currentBlockType = RichUtils.getCurrentBlockType(editorState);
      // if (type === 'header-three' && currentBlockType === 'header-three') return;
      // if (type === currentBlockType) type = 'header-three';
      let newState = RichUtils.toggleBlockType(editorState, type);
      setEditorState(newState);
      setEditorStateParent(convertToRaw(newState.getCurrentContent()).blocks);
    }

    if (type === 'header-six') {
      let footnoteIdx = -1,
        footnote = {};
      contentStateRaw.blocks.forEach((item, i) => {
        if (item.type === 'header-six') {
          footnoteIdx = i;
          footnote = item;
        }
      });
      if (footnoteIdx !== -1) {
        contentStateRaw.blocks = [
          ...contentStateRaw.blocks.slice(0, footnoteIdx),
          ...contentStateRaw.blocks.slice(footnoteIdx + 1),
          footnote,
        ];
        let newState = EditorState.createWithContent(convertFromRaw(contentStateRaw));
        setEditorState(newState);
        setEditorState(EditorState.moveFocusToEnd(newState));
      }
    }
  };

  const onChangeHandler = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const contentStateRaw = convertToRaw(contentState);
    const currentSelection = editorState.getSelection();
    let hasChangedType = false;

    // convert unsupported type to bullet
    contentStateRaw.blocks.forEach((item) => {
      if (!item.type.match(/header-one|header-two|header-three|unordered-list-item|header-six/)) {
        item.type = 'unordered-list-item';
        hasChangedType = true;
      }
      if (editorStateParent.length) {
        if (!editorStateParent.some((i) => i.key === item.key && i.type === item.type)) {
          item.type = 'unordered-list-item';
          hasChangedType = true;
        }
      }
    });

    let newState = editorState,
      singleBulletsCount = 0,
      groupBulletsCount = 0,
      isBulletGroup = false,
      skipNewBlock = false;

    contentStateRaw.blocks.forEach((item) => {
      if (item.type === 'header-three') {
        isBulletGroup = true;
        groupBulletsCount = 0;
      } else if (item.type === 'unordered-list-item') {
        if (isBulletGroup) {
          groupBulletsCount++;
          if (groupBulletsCount > constraints?.group_size_max) skipNewBlock = true;
        } else {
          singleBulletsCount++;
          if (singleBulletsCount > constraints?.single_bullet) skipNewBlock = true;
          groupBulletsCount = 0;
        }
      } else {
        isBulletGroup = false;
        groupBulletsCount = 0;
      }
    });

    setSingleBulletsCount(singleBulletsCount);

    if (skipNewBlock) {
      newState = EditorState.undo(newState);
    } else {
      setEditorStateParent(contentStateRaw.blocks);

      if (hasChangedType) {
        newState = EditorState.push(
          editorState,
          convertFromRaw(contentStateRaw),
          'change-block-type',
        );
        newState = EditorState.forceSelection(newState, currentSelection);
      }
    }
    onChangeText(contentStateRaw.blocks, 'toPlain');
    setEditorState(newState);
  };

  return (
    <div ref={draftEditorRef} className="editor" onClick={focusEditor}>
      <div className="editor_container">
        <Editor ref={editor} editorState={editorState} onChange={onChangeHandler} />
      </div>
      <Toolbar
        onboardingMessage={onboardingMessage}
        ignoreOnboardingRefArray={[draftEditorRef, editor, onboardingPermissionRef]}
        editorState={editorState}
        setEditorState={setEditorState}
        applyStyle={applyStyle}
        constraints={constraints}
        singleBulletsCount={singleBulletsCount}
      />
    </div>
  );
};

export default SlideDraftEditorBlock;
