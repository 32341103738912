import React, { FC, useEffect, useRef } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';

import { useForm } from 'react-hook-form';
import { PasswordInput } from '@app/components/ui';
import { useChangePasswordMutation, useUpdateOptionsMutation } from '@app/store/api/user.api';

import t from '../../../../lib/lng';

type SettingsFields = {
  old_password: string;
  new_password1: string;
  new_password2: string;
  experimental_features: boolean;
};

type SettingsProps = {
  experimental_features: boolean;
  close: (status: boolean) => void;
};

const LNG = {
  errors: {
    requires_field: 'Field is required',
  },
};

export const Settings: FC<SettingsProps> = (props) => {
  const { experimental_features = false, close } = props;
  const [updateOptions, { isLoading: isLoadingUpdateOptions }] = useUpdateOptionsMutation();
  const [changePassword, { isLoading: isLoadingChangePassword }] = useChangePasswordMutation();
  const settingsRef = useRef<HTMLDivElement | null>(null);
  const {
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
    register,
  } = useForm<SettingsFields>({
    defaultValues: {
      experimental_features,
    },
  });

  useEffect(() => {
    if (settingsRef?.current && window.innerHeight < Number(settingsRef?.current?.scrollHeight)) {
      new PerfectScrollbar(settingsRef.current, {});
    }
  }, []);

  function handleSubmitChangePassword(data: SettingsFields) {
    changePassword({ body: data })
      .unwrap()
      .then((resultOfChangePassword) => {
        if (resultOfChangePassword?.status) {
          close?.(true);
        } else {
          resultOfChangePassword?.errors &&
            setError('old_password', { message: resultOfChangePassword.errors });
        }
      });
  }

  function saveDataStorage() {
    updateOptions({ body: { experimental_features: watch().experimental_features } })
      .unwrap()
      .then((resultOfUpdate) => {
        close?.(true);
      });
  }

  return (
    <div className="modal">
      <div
        className="modal_overlay"
        onClick={() => {
          close(false);
        }}
      ></div>
      <div className="m_settings" ref={settingsRef}>
        <div
          className="modal_close"
          onClick={() => {
            close(false);
          }}
        >
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path
              d="M17 1.5L1 17.5M1 1.5L17 17.5"
              stroke="#F8F8FB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <h2 className="m_settings_title">{t.Settings}</h2>

        <form onSubmit={handleSubmit(handleSubmitChangePassword)}>
          <h3 className="m_settings_subtitle">{t.changePassword}</h3>
          <div className="m_settings_change_pwd">
            <PasswordInput
              {...register('old_password', {
                required: { value: true, message: LNG.errors.requires_field },
              })}
              error={errors.old_password?.message}
              label={t.oldPassword}
              onChange={(value) => setValue('old_password', value)}
            />
            <PasswordInput
              {...register('new_password1', {
                required: {
                  value: true,
                  message: LNG.errors.requires_field,
                },
              })}
              error={errors.new_password1?.message}
              label={t.newPassword}
              onChange={(value) => setValue('new_password1', value)}
            />
            <PasswordInput
              {...register('new_password2', {
                required: {
                  value: true,
                  message: LNG.errors.requires_field,
                },
              })}
              error={errors.new_password2?.message}
              label={t.repeatPassword}
              onChange={(value) => setValue('new_password2', value)}
            />
          </div>
          <button className="about_btn m_settings_submit" type="submit">
            {t.changePassword}
          </button>
          <h3 className="m_settings_subtitle">{t.EnableExperimentalFeatures}</h3>
          <label>
            <input
              type="checkbox"
              {...register('experimental_features')}
              style={{ display: 'none' }}
              checked={watch().experimental_features}
            />
            <div className="m_settings_checkbox m_settings_checkbox-exp" />
            <div className="m_settings_checkbox_label">{t.experimentalFeaturesCheckboxLabel}</div>
          </label>
          <button
            className={'about_btn m_settings_submit' + (isLoadingUpdateOptions ? ' disabled' : '')}
            onClick={saveDataStorage}
          >
            {isLoadingUpdateOptions ? t.Saving : t.Save}
          </button>
        </form>
      </div>
    </div>
  );
};
export default Settings;
//
// export class Settings2 extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             oldPassword: '',
//             newPassword1: '',
//             newPassword2: '',
//             experimental_features: this.props.experimental_features,
//             errors: {
//                 old_password: [],
//                 new_password2: [],
//             },
//             isSaving: false
//         };
//     }
//
//     componentDidMount() {
//         if (window.innerHeight < this.settingsRef.scrollHeight) {
//             new PerfectScrollbar(this.settingsRef, {});
//         }
//     }
//
//     async changePassword(e) {
//         e.preventDefault();
//         let response = await fetch('/password/', {
//             method: 'post',
//             body: new URLSearchParams({
//                 old_password: this.state.oldPassword,
//                 new_password1: this.state.newPassword1,
//                 new_password2: this.state.newPassword2,
//             }),
//             headers: {'X-Requested-With': 'XMLHttpRequest', 'X-CSRFToken': cookie.get('csrftoken')},
//         });
//
//         response = await response.json();
//         if (response.status) {
//             this.props.close(true);
//         }
//         else if (response.errors) {
//             this.setState({
//                 errors: response.errors
//             });
//         }
//     }
//
//     async saveDataStorage() {
//         const experimental_features = this.state.experimental_features;
//         this.setState({isSaving: true});
//         await fetch('/update-options/', {
//             method: 'post',
//             body: new URLSearchParams({experimental_features}),
//             headers: {'X-Requested-With': 'XMLHttpRequest', 'X-CSRFToken': cookie.get('csrftoken')},
//         });
//         this.setState({isSaving: false});
//         this.props.updateData();
//         this.props.close(true);
//     }
//
//     render() {
//         const { close } = this.props;
//         const { errors, experimental_features } = this.state;
//
//         return (
//             <div className="modal">
//                 <div className="modal_overlay" onClick={() => {close(false)}}></div>
//                 <div className="m_settings" ref={(n) => {this.settingsRef = n}}>
//                     <div className="modal_close" onClick={() => {close(false)}}>
//                         <svg width="18" height="19" viewBox="0 0 18 19" fill="none"><path d="M17 1.5L1 17.5M1 1.5L17 17.5" stroke="#F8F8FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
//                     </div>
//
//                     <h2 className="m_settings_title">{t.Settings}</h2>
//
//                     <form onSubmit={::this.changePassword}>
//                         <h3 className="m_settings_subtitle">{t.changePassword}</h3>
//                         <div className="m_settings_change_pwd">
//                             <PasswordInput
//                                 label={t.oldPassword}
//                                 error={errors.old_password && errors.old_password[0]}
//                                 value={this.state.oldPassword}
//                                 onChange={(val)=>{
//                                     this.setState({
//                                             oldPassword: val,
//                                             errors: {...errors, old_password: []}
//                                         }
//                                     )}}
//                             />
//
//                             <PasswordInput
//                                 label={t.newPassword}
//                                 value={this.state.newPassword1}
//                                 onChange={(val)=>{
//                                     this.setState({
//                                             newPassword1: val
//                                         }
//                                     )}}
//                             />
//
//                             <PasswordInput
//                                 label={t.repeatPassword}
//                                 error={errors.new_password2 && errors.new_password2[0]}
//                                 value={this.state.newPassword2}
//                                 onChange={(val)=>{
//                                     this.setState({
//                                             newPassword2: val,
//                                             errors: {...errors, new_password2: []}
//                                         }
//                                     )}}
//                             />
//                         </div>
//                         <button className="about_btn m_settings_submit" type="submit">{t.changePassword}</button>
//                     </form>
//
//                     <h3 className="m_settings_subtitle">{t.EnableExperimentalFeatures}</h3>
//                     <label>
//                         <input type="checkbox" style={{display: 'none'}} value={experimental_features} defaultChecked={experimental_features} onChange={() => {this.setState({experimental_features: !experimental_features})}} />
//                         <div className="m_settings_checkbox m_settings_checkbox-exp" />
//                         <div className="m_settings_checkbox_label">{t.experimentalFeaturesCheckboxLabel}</div>
//                     </label>
//                     <button className={'about_btn m_settings_submit' + (this.state.isSaving ? ' disabled' : '')} onClick={::this.saveDataStorage}>{this.state.isSaving ? t.Saving : t.Save}</button>
//                 </div>
//             </div>
//         )
//     }
// }
