import React, { useEffect, useState } from 'react';
import cl from 'classnames';

import { analytics } from '@app/app/analytics';

const SlideEditPointBlock = (props) => {
  const { projectId, editData, edit, type, editing, slideIndex } = props;
  const [autoEdited, setAutoEdited] = useState(
    type === 'shape' ? !!editData.edit_type.match(/truncate|rewrite/) : false,
  );

  useEffect(() => {
    if (type === 'shape' && editData?.edit_type) {
      setAutoEdited(!!editData.edit_type.match(/truncate|rewrite/));
    }
  }, [editData.edit_type, type]);

  const onClickHandler = () => {
    edit();

    let element = '';
    if (type === 'shape') {
      element = editData.origin;
    } else {
      if (editData.image_type === 'Icon') element = 'decor_icon';
      if (editData.image_type === 'Object') element = 'object';
      if (editData.image_type === 'Photo') {
        if (editData.shape_id === -1) element = 'photo_bg';
        else element = 'photo_fig';
      }
    }

    analytics.emitEvent('design_editing', {
      GTM: {
        editing_location: 'preview_interaction',
        editable_element: element,
        processed_file_id: +projectId,
      },
      Amplitude: {
        element: element,
        'editing location': 'preview interaction',
        'processed file id': +projectId,
        'slide number': +slideIndex + 1,
      },
    });
  };

  let shape =
      editData?.origin === 'bullet'
        ? editData?.shapes.filter((i) => i.extra_info.bullet_type === 'text')[0]
        : editData,
    top = getPercentTop(shape?.shape_parameters.target_y),
    left = getPercentLeft(shape?.shape_parameters.target_x),
    height = getPercentTop(shape?.shape_parameters.target_height),
    width = getPercentLeft(shape?.shape_parameters.target_width);

  if (width === 0 && height === 0 && top === 0 && left === 0) {
    return null;
  }
  if (left + width > 100) width = width - (left + width - 100);
  if (top + height > 100) height = height - (top + height - 100);
  if (editData?.image_type && editData?.image_type === 'Icon') width += getPercentLeft(32); // 32px - width of pencil icon

  return (
    <div
      className="editpoint_field group"
      style={{ top: top + '%', left: left + '%', width: width + '%', height: height + '%' }}
      onClick={onClickHandler}
    >
      <div
        className={cl(
          'editpoint transition-all',
          autoEdited && 'editpoint-auto_edited',
          editing ? 'editing' : 'scale-0 group-hover:scale-[100%]',
        )}
      />
    </div>
  );
};
function getPercentTop(value) {
  return (value * 100) / 475;
}

function getPercentLeft(value) {
  return (value * 100) / 845;
}
export default SlideEditPointBlock;
