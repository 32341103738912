import React from 'react';
import PropTypes from "prop-types";

const HorizontalCard = (props) => {
    const { title, description, picture, isReverse=false } = props;
    return (
        <div className={`horizontal_card__wrapper ${isReverse ? 'horizontal_card__wrapper__left_image' : 'horizontal_card__wrapper__right_image'}`}>
            <div>
                <img src={picture} alt={title}/>
            </div>
            <div className="horizontal_card__about">
                <h3 className="horizontal_card__title">{title}</h3>
                <p className="horizontal_card__description">{description}</p>
            </div>
        </div>
    );
};

HorizontalCard.prototype = {
    title: PropTypes.string.isRequired,
    description: PropTypes.element.isRequired,
    picture: PropTypes.string.isRequired,
    isReverse: PropTypes.bool
}

export default HorizontalCard;