import { FC, useEffect, useState } from 'react';

import { useGetThemeInitQuery } from '@app/store/api/user.api';
import type { Theme } from '@app/types/presentation.type';
import { useLoadPresentationQuery } from '@app/store/api/presentation.api';

import UploadProcessBlock from './upload-draft/upload-process.block';

import { GenThemes } from './setup-style/gen-themes.block';
import GenPanel from './setup-style/gen-style-panel/ui/gen-style-panel.block';

type SetupStyleBlockProps = {
  projectId: string | null;
  isPPTXUpload?: boolean;
};

const SetupStyleBlock: FC<SetupStyleBlockProps> = (props) => {
  const { projectId, isPPTXUpload = false } = props;
  const [selectedTheme, setSelectedTheme] = useState<string>('');

  const { data: presentation, isLoading: isLoadingPresentation } = useLoadPresentationQuery(
    { projectId: String(projectId) },
    { skip: !projectId },
  );

  const {
    data: themesData,
    isLoading: isLoadingThemes,
    isError: isThemesError,
    refetch: refetchThemes,
  } = useGetThemeInitQuery();

  const findThemeOptionsByIdTheme = (themeID: number, themes: Theme[]): Theme | undefined => {
    return themes.find((theme) => {
      return theme.id === themeID;
    });
  };

  useEffect(() => {
    const themeID = presentation?.result?.process?.option[0]?.theme_id;
    const themes = themesData?.result.options.themes;
    let themeOptions = null;

    if (themeID && themes) themeOptions = findThemeOptionsByIdTheme(themeID, themes);

    if (themeOptions) {
      setSelectedTheme(themeOptions.name);
    }
  }, [presentation, themesData]);

  // console.debug('themeData ->', themesData);
  // console.debug('presentation ->', presentation, presentation?.result?.process?.option?.[0]);

  return (
    <div className="w-full">
      <div className="gen_main flex-col-reverse justify-end">
        <div className="mx-auto container mx-auto max-w-screen-xl">
          <GenThemes
            selectedTheme={selectedTheme}
            setSelectedTheme={setSelectedTheme}
            themes={(themesData && themesData.result.options.themes) || []}
            isLoadingThemes={isLoadingThemes}
            isThemesError={isThemesError}
            refetchThemes={refetchThemes}
          />
          {isPPTXUpload && <UploadProcessBlock />}
        </div>
        <GenPanel
          isLoadingPresentation={isLoadingPresentation}
          theme={selectedTheme}
          firstGeneratedSlide={presentation?.result?.download?.thumb[0]}
          isPPTXUpload={isPPTXUpload}
          // TODO: We should probably consider where the user opened the panel from, rather than the number of slides.
          isFirstStyleSettings={
            (presentation?.result?.source_files[0]?.slide_count === 1 &&
              presentation?.result?.slides_info?.[0].empty) ||
            isPPTXUpload
          }
          presentationOptions={presentation?.result?.process?.option?.[0]}
          themesData={themesData}
          isLoadingThemes={isLoadingThemes}
          isThemesError={isThemesError}
        />
      </div>
    </div>
  );
};

export default SetupStyleBlock;
