import React from 'react';

const TriangleIcon = ({ size }: { size: number }) => {
  return (
    <svg width={size} viewBox="0 0 10 12" fill="currentColor">
      <path d="M0 0V12L10 6L0 0Z" fill="currentColor" />
    </svg>
  );
};

export default TriangleIcon;
