import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Presentation } from '../../types/presentation.type';

type initialPresentationState = {
  projectId: number | null;
  fileUpload: {
    fileName: string | null;
    uploadProgress: number;
    process: boolean;
  };
  projectLink: string;
  presentation: Presentation['result'] | null;
};
const initialState: initialPresentationState = {
  projectId: null,
  projectLink: '',
  presentation: null,
  fileUpload: {
    fileName: null,
    uploadProgress: 0,
    process: false,
  },
};
export const presentationSlice = createSlice({
  name: 'presentationSlice',
  initialState,
  reducers: {
    uploadFileProgress: (
      state,
      action: PayloadAction<{ uploadProgress: number; fileName?: string }>,
    ) => {
      const { fileName, uploadProgress } = action.payload;
      state.fileUpload.uploadProgress = uploadProgress;
      state.fileUpload.process = uploadProgress < 100;
      if (fileName) state.fileUpload.fileName = fileName;
    },
    updatePresentation: (
      state,
      action: PayloadAction<{
        projectId: number;
        projectLink?: string;
        presentation: Presentation['result'];
      }>,
    ) => {
      const {
        payload: { projectId, projectLink, presentation },
      } = action;
      state.presentation = presentation;
      state.projectId = projectId;
      state.projectLink = projectLink ?? state.projectLink;
    },
    clearPresentation: (state) => {
      state.presentation = null;
      state.projectId = null;
      state.projectLink = '';
      state.fileUpload = {
        fileName: null,
        uploadProgress: 0,
        process: false,
      };
    },
  },
});

export const { updatePresentation, clearPresentation, uploadFileProgress } =
  presentationSlice.actions;
export default presentationSlice.reducer;
