import React, { useState, useEffect, useRef } from 'react';
import t from '../../lib/lng';
import PropTypes from "prop-types";

const SystemMessage = (props) => {
	const { systemMessage, onClick,  } = props;
	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(true);
		if (systemMessage.autoclose) {
			setTimeout(() => {
				setShow(false);
				setTimeout(() => {
					onClick && onClick();
				}, 400);
			}, systemMessage.autoclose);
		}
	}, []);

	return(
		<div className={'smsg ' + (systemMessage.type) + (show ? ' go' : '')}>
			<div className="smsg_inner">
				<div className="smsg_title">{systemMessage.msg}</div>
				{systemMessage.msg2 && <div className="smsg_text">{systemMessage.msg2}</div>}
				{systemMessage.msg3 && <div className="smsg_extra">{systemMessage.msg3}</div>}
				{systemMessage.msg4 && <div className="smsg_extra_2">{systemMessage.msg4}</div>}
				{
					systemMessage.msg == 'Oops, something went wrong' &&
					<>
						<div className="smsg_text">{t.DontWorryWeSaved}</div>
						<div className="smsg_reload" onClick={() => {location.reload()}}>{t.Refresh}</div>
					</>
				}
				{systemMessage?.endButtons
					? systemMessage.endButtons?.map(({ background = "#fff", text, onClick: clickHandler }, index) =>
					(
						<div key={index} className="ui_toast_btn__wrapper">
							<div
								className="ui_toast_btn"
								onClick={() => clickHandler?.({setShow, onClick}) }
								style={{ background }}>
								{text}
							</div>
						</div>
					))
					: <></>
				}
					<div className = "smsg_close" onClick={() => {
					setShow(false);
					setTimeout(() => {
					onClick && onClick();
					}, 400);
				}} />
			</div>
		</div>
	);
}

SystemMessage.prototype = {
	systemMessage: PropTypes.shape({
		type: PropTypes.oneOf(['warning', 'success', 'error']).isRequired,
		autoclose: PropTypes.number.isRequired,
		msg: PropTypes.string.isRequired,
		msg2: PropTypes.string,
		msg3: PropTypes.string,
		msg4: PropTypes.string,
		// added buttons on bottom of message by line
		endButtons: PropTypes.arrayOf(PropTypes.shape({ background: PropTypes.string, text: PropTypes.string, onClick: PropTypes.func })),
	}),
	onClick: PropTypes.func,
}

export default SystemMessage;