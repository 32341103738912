import { createApi } from '@reduxjs/toolkit/query/react';

import { serverPath } from '@app/utils/server-path';
//import { changeMode } from "../slice/slide.slice";

import type { SlideResponse } from '@app/types/slide.type';

import { slidesSentry } from '@app/utils/sentry/slides.sentry';
import { CommonResponse } from '@app/types/common.type';

import globalRequestBlocker from '../global-request-blocker';

import presentationApi from './presentation.api';
import { appBaseQueryWithResend } from './base-query';

export const slideApi = createApi({
  reducerPath: 'slideQuery',
  tagTypes: ['SLIDE', 'PING_RESPONSE'],
  baseQuery: appBaseQueryWithResend,
  endpoints: (build) => ({
    searchMedia: build.mutation<CommonResponse<boolean>, { body: FormData }>({
      query: ({ body }) => ({
        url: `${serverPath.searchMedia}`,
        body,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        formData: true,
      }),
    }),
    remakeSlideDesign: build.mutation<
      CommonResponse<boolean>,
      { params: { projectId: number }; body: FormData }
    >({
      query: ({ params: { projectId }, body }) => ({
        url: `${serverPath.remake}${projectId}/`,
        body,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        formData: true,
      }),
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        globalRequestBlocker({ dispatch });
        queryFulfilled
          .then(({ data }) => {
            presentationApi.util.updateQueryData(
              'loadPresentation',
              { projectId: String(patch.params.projectId) },
              (res) => {
                return res;
              },
            );
            if (data.status) {
              presentationApi.util.updateQueryData(
                'loadPresentation',
                { projectId: String(patch.params.projectId) },
                (res) => res,
              );
              //dispatch(changeMode({ mode: 'result', slideIndex: String(patch.body.get('index'))}));
            }
          })
          .catch((error) => {
            console.error('[remake slide error]: ', error);
          });
      },
      invalidatesTags: ['PING_RESPONSE'],
    }),
    addSlide: build.mutation<SlideResponse, { projectId: number; body: FormData }>({
      query: ({ projectId, body }) => ({
        url: `${serverPath.slide.addSlide}${projectId}/`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        body: body,
        formData: true,
      }),
      async onQueryStarted({ projectId }, { dispatch, queryFulfilled }) {
        globalRequestBlocker({ dispatch });
        const { data } = await queryFulfilled;
        presentationApi.endpoints.loadPresentation.initiate({ projectId: String(projectId) });
        if (data.status) {
          presentationApi.endpoints.loadPresentation.initiate({ projectId: String(projectId) });
          dispatch(
            presentationApi.util.updateQueryData(
              'loadPresentation',
              { projectId: String(projectId) },
              (res) => {
                return res;
              },
            ),
          );
        }
      },
    }),
    regenSlide: build.mutation({
      query: ({ url, body }: { url: string; projectId: number; body: { [x: string]: string } }) => {
        const form = new FormData();
        Object.entries(body).forEach((field) => {
          form.append(field[0], String(field[1]));
        });
        return {
          url,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          body: form,
          formData: true,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        globalRequestBlocker({ dispatch });
        queryFulfilled
          .then(() => {
            presentationApi.util.updateQueryData(
              'loadPresentation',
              { projectId: String(patch.projectId) },
              (res) => {
                return res;
              },
            );
          })
          .catch((error) => {
            console.error('[regen slide error]: ', error);
          });
      },
    }),
    cloneSlide: build.mutation({
      query: ({ params, body }: { params: { projectId: string }; body: FormData }) => ({
        url: `${serverPath.slide.cloneSlide}${params.projectId}/`,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        formData: true,
      }),
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        globalRequestBlocker({ dispatch });
        queryFulfilled
          .then(() => {
            presentationApi.util.updateQueryData(
              'loadPresentation',
              { projectId: String(patch.params.projectId) },
              (res) => res,
            );
          })
          .catch((error) => {
            console.error('clone slide error', error);
          });
      },
    }),
    remakeSlide: build.mutation({
      query: ({ body, params }: { body: FormData; params: { projectId: string } }) => ({
        url: `${serverPath.slide.remakeSlide}${params.projectId}/`,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        formData: true,
      }),
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        globalRequestBlocker({ dispatch });
        queryFulfilled
          .then(({ data }) => {
            if (data.status) {
              presentationApi.util.updateQueryData(
                'loadPresentation',
                { projectId: String(patch.params.projectId) },
                (res) => res,
              );
              // dispatch(changeMode({ mode: 'result', slideIndex: String(patch.body.get('index'))}));
            } else {
              slidesSentry.regenSlide({
                errors: data.errors,
                slideIdx: Number(patch.body.get('index')),
              });
            }
          })
          .catch((error) => {
            console.error('[remake slide error]: ', error);
          });
      },
    }),
    processSlide: build.mutation<{ status: boolean }, { projectId: string; body: FormData }>({
      query: ({ projectId, body }) => ({
        url: `${serverPath.slide.processSlide}${projectId}/`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        body: body,
        formData: true,
      }),
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        globalRequestBlocker({ dispatch });
        queryFulfilled
          .then(({ data }) => {
            if (data.status) {
              presentationApi.util.updateQueryData(
                'loadPresentation',
                { projectId: String(patch.projectId) },
                (res) => res,
              );
            } else {
              slideApi.endpoints.processSlide.initiate(patch);
            }
          })
          .catch((error) => {
            setTimeout(() => slideApi.endpoints.processSlide.initiate(patch), 2000);
            console.error('process slide error', error);
          });
      },
    }),
    removeSlide: build.mutation({
      query: ({
        body,
        params: { projectId },
      }: {
        body: FormData;
        params: { projectId: string };
      }) => ({
        url: `${serverPath.slide.removeSlide}${projectId}/`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        body: body,
        formData: true,
      }),
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        globalRequestBlocker({ dispatch });
        queryFulfilled
          .then(() => {
            presentationApi.util.updateQueryData(
              'loadPresentation',
              { projectId: String(patch.params.projectId) },
              (res) => res,
            );
          })
          .catch((error) => {
            console.error('remove slide error', error);
          });
      },
    }),
    refetchErroredQueries: build.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['PING_RESPONSE'],
    }),
  }),
});

export const {
  useAddSlideMutation,
  useRemakeSlideMutation,
  useSearchMediaMutation,
  useProcessSlideMutation,
  useRegenSlideMutation,
  useCloneSlideMutation,
  useRemakeSlideDesignMutation,
  useRemoveSlideMutation,
} = slideApi;
