import { useEffect, useRef, useState } from 'react';
import {
  hexToHsva,
  hexToRgba,
  hsvaToHex,
  hsvaToRgba,
  rgbaToHex,
  rgbaToHsva,
  rgbaToRgb,
  ShadeSlider,
  Wheel,
} from '@uiw/react-color';
import cl from 'classnames';
import { useAddCustomColorMutation, useDeleteCustomColorMutation } from '@app/store/api/user.api';
import { Loader } from '@app/components/ui/loader';
import { SaveIcon } from '@app/components/ui/icon/project-icons';
import { useAnimatedSymbol } from '@app/hooks/use-animated-ellipsis';
import { svgIcons } from '@app/pages/create/data/svg-icons.data';
import { useToastContext } from '@app/hooks/use-toast-provider';
import t from '@lib/lng';
import { contrast } from '@lib/utils';
import { MotionWrapper } from '@app/components/ui/motion-wrapper';

const ColorPicker = ({
  selectedColor,
  colorpickerRef,
  colors,
  baseColors,
  close,
  selectOption,
  titleSlot,
  overflowColors,
  presetColors,
  changeSelectedStyle,
  selectedThemeStyle,
  refetchThemeInit,
}) => {
  const [color, setColor] = useState(null);
  const [inputHex, setInputHex] = useState('');
  const [inputRgb, setInputRgb] = useState('');
  const [msgHeight, setMsgHeight] = useState(0);
  const [isSavingColor, setIsSavingColor] = useState(false);
  const [colorExists, setColorExists] = useState(false);
  const { getToast } = useToastContext();
  const [setColorMutation] = useAddCustomColorMutation();
  const [deletePresetQuery, { isLoading: isDeletingColor }] = useDeleteCustomColorMutation();
  const animatedSavingText = useAnimatedSymbol(t.Saving.toUpperCase(), '.', 500);
  const animatedDeletingText = useAnimatedSymbol(t.Deleting.toUpperCase(), '.', 500);
  const colorPickerMsgRef = useRef(null);

  const deleteColor = (item) => {
    deletePresetQuery({ preset_id: item.id })
      .unwrap()
      .then(() => refetchThemeInit())
      .then((deleteResult) => {
        if (deleteResult.status) {
          // TODO: мб пригодится ;)
        }
      })
      .catch(() => {
        getToast({
          systemMessage: {
            msg: 'ERROR DELETE COLOR',
            autoclose: 4000,
          },
        });
      });
  };

  useEffect(() => {
    if (colorExists && colorPickerMsgRef.current) {
      setMsgHeight(colorPickerMsgRef.current.clientHeight);
    }
  }, [colorExists]);

  useEffect(() => {
    if (!color) return;
    setInputHex(color.hex);
    setInputRgb(Object.values(color.rgb).toString());
  }, [color]);

  useEffect(() => {
    setColor({
      rgb: rgbaToRgb(hexToRgba(selectedColor)),
      hex: selectedColor,
      hsva: hexToHsva(selectedColor),
    });
  }, [selectedColor]);

  const onChangeShade = (v) => {
    const newHsva = {
      h: color.hsva.h,
      s: color.hsva.s,
      v: v.v,
      a: color.hsva.a,
    };

    setColor({
      rgb: rgbaToRgb(hsvaToRgba(newHsva)),
      hex: hsvaToHex(newHsva),
      hsva: newHsva,
    });
  };

  const onChangeHex = (e) => {
    let val = e.target.value.replaceAll('#', '');
    if (val.match(/[^#a-fA-F0-9]/gm) || val.length > 7) return;
    if (!val) setInputRgb('');
    val = '#' + val;
    setInputHex(val);
    if (!isValidHex(val)) return;
    setColor({
      rgb: rgbaToRgb(hexToRgba(val)),
      hex: val,
      hsva: hexToHsva(val),
    });
  };

  const onChangeRgb = (e) => {
    let val = e.target.value.replace(/\s+/g, '');
    let valArr = val.split(',');
    if (
      val.match(/[^\d,]/gm) ||
      val.length > 11 ||
      valArr.length > 3 ||
      valArr.some((i) => i.length > 3) ||
      valArr.some((i) => i > 255)
    )
      return;

    if (
      val.length > inputRgb.length &&
      valArr.length < 3 &&
      (valArr[valArr.length - 1].length === 3 || valArr[valArr.length - 1] > 25)
    ) {
      val += ',';
    }
    if (!val) setInputHex('');
    setInputRgb(val);
    if (!isValidRgb(val)) return;

    val = val.split(',');
    const rgb = {
      r: +val[0],
      g: +val[1],
      b: +val[2],
      a: 1,
    };

    setColor({
      rgb: rgbaToRgb(rgb),
      hex: rgbaToHex(rgb),
      hsva: rgbaToHsva(rgb),
    });
  };

  const isValidHex = (hex) => {
    return !!hex.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
  };

  const isValidRgb = (rgb) => {
    rgb = rgb.split(',');
    if (rgb.length !== 3) return false;
    let res = true;
    rgb.forEach((item) => {
      if (isNaN(parseInt(item)) || parseInt(item) < 0 || parseInt(item) > 255) res = false;
    });
    return res;
  };

  const checkColor = (i, color) => {
    if (i.hasOwnProperty('value')) {
      return i.value.toUpperCase() === color.hex.replace('#', '').toUpperCase();
    }
    return i.toUpperCase() === color.hex.replace('#', '').toUpperCase();
  };

  const saveColor = async () => {
    if (
      (overflowColors && overflowColors.some((i) => checkColor(i.replace('#', ''), color))) ||
      (presetColors && presetColors.some((i) => checkColor(i, color))) ||
      (colors && colors.some((i) => checkColor(i, color))) ||
      (baseColors && baseColors.some((i) => checkColor(i, color)))
    ) {
      setColorExists(true);
      setTimeout(() => {
        setColorExists(false);
      }, 4000);
      return;
    }
    const ratioWhite = +contrast([255, 255, 255], Object.values(color.rgb)).toFixed(2),
      ratioBlack = +contrast([0, 0, 0], Object.values(color.rgb)).toFixed(2),
      textColor = [];
    let data = {
      color: color.hex.replace('#', '').toUpperCase(),
    };

    if (ratioWhite > 2) textColor.push('FFFFFF');
    if (ratioBlack > 4.2) textColor.push('000000');
    if (ratioWhite <= 2 && ratioBlack <= 4.2) {
      if (ratioWhite >= ratioBlack) textColor.push('FFFFFF');
      else textColor.push('000000');
    }
    data.text_color = textColor;
    setIsSavingColor(true);
    setColorMutation(data)
      .unwrap()
      .then((resultOfSetColor) => {
        if (resultOfSetColor.status) {
          selectOption('color', data.color, true);
          close();
        } else {
          getToast({ systemMessage: { msg: resultOfSetColor.error } });
        }
      })
      .catch(() => {
        getToast({
          systemMessage: {
            msg: 'ERROR SAVING COLOR',
            autoclose: 4000,
          },
        });
      })
      .finally(() => {
        setIsSavingColor(false);
      });
  };

  const handleColorClick = (item) => {
    if (typeof changeSelectedStyle === 'function') {
      changeSelectedStyle({ color: item });
      close();
    }
  };

  return (
    <div
      className={cl(
        'colorPicker',
        isSavingColor && 'colorPicker--isLoading',
        isDeletingColor && 'colorPicker--isLoading',
      )}
      ref={colorpickerRef}
    >
      {(isDeletingColor || isSavingColor) && (
        <div className="absolute top-0 left-0 w-full h-full pointer-events-none z-[3] backdrop-blur-[5px]">
          <div className="flex w-full h-full justify-center items-center">
            <div className="flex flex-col items-center gap-2">
              <Loader />
              <div className="relative left-3">
                {isDeletingColor && animatedDeletingText}
                {isSavingColor && animatedSavingText}
              </div>
            </div>
          </div>
        </div>
      )}
      {color && (
        <>
          {
            <MotionWrapper
              isVisible={colorExists}
              delay={0.5}
              duration={0.3}
              animationProps={{
                initial: { scale: 0, height: 0 },
                animate: {
                  scale: 1,
                  height: msgHeight,
                },
                exit: { scale: 0 },
              }}
            >
              <div className="colorPicker__msg" ref={colorPickerMsgRef}>
                {t.colorExists}
              </div>
            </MotionWrapper>
          }
          {titleSlot}
          {
            <span
              className="absolute text-general_MI_300 right-5 top-5 cursor-pointer w-[12px] aspect-[1/1] p-2"
              onClick={close}
            >
              {svgIcons['close2']}
            </span>
          }
          <div className="flex flex-wrap gap-3 mb-3 pt-8">
            {overflowColors?.map((item, i) => (
              <div
                key={i}
                className={
                  'gen_panel__color gen_panel__color-' +
                  item.toLowerCase().replace('#') +
                  (selectedThemeStyle.color === item ? ' active' : '')
                }
                title={item.name}
                style={{ background: item }}
                onClick={() => handleColorClick(item)}
              />
            ))}
            {presetColors?.map((item, i) => (
              <div
                key={i}
                className={
                  'gen_panel__color gen_panel__color-' +
                  item.value.toLowerCase() +
                  (selectedThemeStyle.color === item.value ? ' active' : '')
                }
                title={item.name}
                style={{ background: '#' + item.value }}
                onClick={() => {
                  changeSelectedStyle({ color: item.value });
                  close();
                }}
              >
                <div
                  className="gen_panel__color_del"
                  onClick={(e) => {
                    deleteColor && deleteColor(item);
                    e.stopPropagation();
                  }}
                />
              </div>
            ))}
          </div>
          <div className="flex justify-center">
            <Wheel
              style={{ marginBottom: '12px' }}
              color={color.hex}
              onChange={(color) => {
                setColor(color);
              }}
            />
          </div>
          <ShadeSlider
            hsva={color.hsva}
            onChange={(v) => {
              onChangeShade(v);
            }}
          />
          <div className="colorPicker__inputs">
            <div className="colorPicker__inputWrap">
              <div className="colorPicker__inputLabel">HEX</div>
              <input
                className="colorPicker__input bg-general_background_MIII_600_dark"
                value={inputHex}
                onChange={onChangeHex}
              />
            </div>
            <div className="colorPicker__inputWrap">
              <div className="colorPicker__inputLabel">RGB</div>
              <input
                className="colorPicker__input bg-general_background_MIII_600_dark"
                value={inputRgb}
                onChange={onChangeRgb}
              />
            </div>
          </div>
          <div className="colorPicker__btn" onClick={saveColor}>
            <SaveIcon size={18} />
            {isSavingColor ? t.Saving : t.SaveColor}
          </div>
        </>
      )}
    </div>
  );
};

export default ColorPicker;
