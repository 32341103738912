import React from 'react';
import PropTypes from "prop-types";

const Section = (props) => {
    const { children, size='small', className="" } = props;
    const sizeList = {
        small: 'ui_section__small',
        middle: 'ui_section__middle'
    }
    return (
        <section className={`ui_section  ${sizeList[size]} ${className}`}>
            {children}
        </section>
    );
};

Section.prototype = {
    children: PropTypes.element.isRequired,
    size: PropTypes.oneOf(['small', 'middle']),
    className: PropTypes.string,
}

export default Section;