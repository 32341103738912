import React, { useState } from 'react';
import CorporateModal from '@app/modals/corporateModal';

const EnterpriseTariffBlock = () => {
  const [showCorporateModal, setShowCorporateModal] = useState(false);

  return (
    <div className="about8_ent">
      <h3 className="about8_ent__title">Enterprise plan</h3>
      <div className="about8_ent__text">
        We can customize our product to your company's needs. Here's what we can do for you:
      </div>
      <ul className="about8_ent__items">
        <li className="about8_ent__item">Add your corporate colors</li>
        <li className="about8_ent__item">Build your own library of images and objects</li>
        <li className="about8_ent__item">Add your branded fonts</li>
        <li className="about8_ent__item">Private accounts for employees</li>
        <li className="about8_ent__item">Upload your corporate templates</li>
        <li className="about8_ent__item">Personal support manager</li>
        <li className="about8_ent__item">Create exclusive corporate-styled templates</li>
        <li className="about8_ent__item">The ability to disable ChatGPT functions</li>
      </ul>
      <div
        className="about8_ent__btn about_btn"
        onClick={() => {
          setShowCorporateModal(true);
        }}
      >
        Book a demo call
      </div>
      {showCorporateModal && (
        <CorporateModal
          close={() => {
            setShowCorporateModal(false);
          }}
        />
      )}
    </div>
  );
};

export default EnterpriseTariffBlock;
