import React, { ForwardedRef, forwardRef } from 'react';

type SwitcherProps = {
  id?: string;
  name: string;
  checked: boolean;
  onCheck: (check: boolean) => void;
};
const Switcher = forwardRef(
  (props: SwitcherProps, forwardedRef: ForwardedRef<HTMLInputElement>) => {
    const { id, name, checked, onCheck, ...rest } = props;
    return (
      <label htmlFor={id}>
        <div
          className={
            checked
              ? 'ui_switcher_container ui_switcher_container__checked'
              : 'ui_switcher_container'
          }
        >
          <div
            className={
              checked ? 'ui_switcher_point ui_switcher_point__checked' : 'ui_switcher_point'
            }
          ></div>
        </div>
        <input
          type="checkbox"
          id={id}
          ref={forwardedRef}
          {...rest}
          name={name}
          checked={checked}
          onChange={(event) => onCheck?.(event.target.checked)}
          style={{ opacity: 0, display: 'none', width: '0px', height: '0px' }}
        />
      </label>
    );
  },
);

Switcher.displayName = 'Switcher';

export default Switcher;
