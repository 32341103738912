import React from 'react';
import PropTypes from "prop-types";
import {Button, Section} from "../../ui";

const InfoBox = (props) => {
    const { header, description, title, btnText, onClick, bgImageSrcSet, link, height=800 } = props;
    const btnSettings = link ? { link, isLink: true } : { onClick: () => onClick?.() }
    return (
        <div className="info_box" style={{ height: `${height}px`}}>
            <div className="info_box__wrapper">
                {header}
                <Section size="middle" className="info_box__section">
                    <h1 className="info_box__title ff-raleway">{title}</h1>
                    <p className="info_box__description">{description}</p>
                    {btnText && <div>
                        <Button label={btnText} size="extra_middle" rounded="large" {...btnSettings} />
                    </div>}
                </Section>
            </div>
            <img className="info_box__bg_image"
                 srcSet={bgImageSrcSet}
                 alt={title} width="100%" height="100%"/>
        </div>
    );
};


InfoBox.prototype = {
    header: PropTypes.element,
    title: PropTypes.element.isRequired,
    description: PropTypes.element.isRequired,
    btnText: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    link: PropTypes.string,
    bgImageSrcSet: PropTypes.string,
    height: PropTypes.number,
}

export default InfoBox;