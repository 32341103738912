import React, { useEffect, useRef } from 'react';

import { svgIcons } from '../data/icon.data';

const FileMenu = ({ onClickMenuItem, is_protected, readonly, close }) => {
  const menuRef = useRef(null);
  useEffect(() => {
    if (menuRef.current.getBoundingClientRect().bottom > window.innerHeight) {
      menuRef.current.scrollIntoView(false);
    }
  }, []);

  return (
    <div className="file_menu" ref={menuRef}>
      <div
        className={'file_menu_item' + (readonly ? ' disabled' : '')}
        onClick={() => {
          onClickMenuItem('download');
          close();
        }}
      >
        {svgIcons.download} Download .pptx
      </div>
      <div
        className="file_menu_item"
        onClick={() => {
          onClickMenuItem('copy');
          close();
        }}
      >
        {svgIcons.copy} Make a copy
      </div>
      <div
        className="file_menu_item"
        onClick={() => {
          onClickMenuItem('rename');
          close();
        }}
      >
        {svgIcons.rename} Rename
      </div>
      <div
        className="file_menu_item"
        onClick={() => {
          onClickMenuItem('share');
          close();
        }}
      >
        {svgIcons.share} Share
      </div>
      <div
        className={'file_menu_item' + (is_protected ? ' disabled hint' : '')}
        onClick={() => {
          onClickMenuItem('delete');
          close();
        }}
      >
        {is_protected && (
          <div className="hint_text">To delete the file, restrict access in sharing settings.</div>
        )}
        {svgIcons.delete} Delete
      </div>
    </div>
  );
};
export default FileMenu;
