import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import t from '@lib/lng';
import { useAppSelector } from '@app/hooks/use-store';

import Onboarding from '../onboarding.block';

const UploadDraftPanelBlock = () => {
  const [showOnboarding, setShowOnboarding] = useState(false);
  const { setup } = useAppSelector((state) => state.commonState);
  return (
    <>
      <div className="show_onboarding" onClick={() => setShowOnboarding(true)}>
        <div className="show_onboarding_main">
          <div className="show_onboarding_main_inner">
            <svg
              width="146"
              height="150"
              viewBox="0 0 146 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_3341_10207)">
                <path
                  d="M54.25 114.844L33.1562 145.312V150"
                  stroke="#2D2829"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path
                  d="M91.75 114.844L112.844 145.312V150"
                  stroke="#2D2829"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path
                  d="M73 114.844V150"
                  stroke="#2D2829"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path d="M140.969 18.75H5.03125V107.812H140.969V18.75Z" fill="#EFF4F7" />
                <path d="M145.656 9.375H0.34375V18.75H145.656V9.375Z" fill="#52545D" />
                <path d="M101.125 107.812H44.875V117.188H101.125V107.812Z" fill="#52545D" />
                <path
                  d="M94.0938 9.375H51.9062V4.6875L56.5938 0H89.4062L94.0938 4.6875V9.375Z"
                  fill="#2D2829"
                />
                <path d="M131.594 56.25H61.2812V98.4375H131.594V56.25Z" fill="#00BA88" />
                <path
                  d="M68.3125 93.75V86.7188"
                  stroke="white"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path
                  d="M77.6875 93.75V72.6562"
                  stroke="white"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path
                  d="M87.0625 93.75V77.3438"
                  stroke="white"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path
                  d="M96.4375 93.75V67.9688"
                  stroke="white"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path
                  d="M124.562 93.75V70.3125"
                  stroke="white"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path
                  d="M105.812 93.75V79.6875"
                  stroke="white"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path
                  d="M115.188 93.75V60.9375"
                  stroke="white"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path
                  d="M61.2812 49.2188H131.594"
                  stroke="#CFD3D6"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path
                  d="M61.2812 39.8438H131.594"
                  stroke="#CFD3D6"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path
                  d="M14.4062 77.3438H56.5938"
                  stroke="#CFD3D6"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path
                  d="M14.4062 86.7188H56.5938"
                  stroke="#CFD3D6"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path
                  d="M14.4062 96.0938H37.8438"
                  stroke="#CFD3D6"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path
                  d="M61.2812 30.4688H65.9688"
                  stroke="#52545D"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path
                  d="M70.6562 30.4688H75.3438"
                  stroke="#52545D"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path
                  d="M80.0312 30.4688H84.7188"
                  stroke="#52545D"
                  strokeWidth="4.6875"
                  strokeMiterlimit="10"
                />
                <path
                  d="M33.1562 32.8125V51.5625H14.4062C14.4062 41.2031 22.7969 32.8125 33.1562 32.8125Z"
                  fill="#24F1BA"
                />
                <path
                  d="M51.9062 51.5625C51.9062 56.7422 49.7969 61.4297 46.4219 64.8281L33.1562 51.5625H51.9062Z"
                  fill="#52545D"
                />
                <path
                  d="M56.5938 46.875H37.8438V28.125C48.2031 28.125 56.5938 36.5156 56.5938 46.875Z"
                  fill="#00BA88"
                />
                <path
                  d="M46.4219 64.8281C43.0234 68.2031 38.3359 70.3125 33.1562 70.3125C22.7969 70.3125 14.4062 61.9219 14.4062 51.5625H33.1562L46.4219 64.8281Z"
                  fill="#00A97B"
                />
              </g>
              <defs>
                <clipPath id="clip0_3341_10207">
                  <rect width="145.312" height="150" fill="white" transform="translate(0.34375)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div className="show_onboarding_link">Find out in 3 minutes!</div>
        <div className="show_onboarding_text">
          Go all out with Wonderslide!
          <br />
          Learn how to prepare the perfect draft and get the best design of your presentation
        </div>
      </div>

      <div className="gen_panel__main_title">{t.TipsAndTricks}</div>
      <ul>
        <li className="gen_panel__main_item">{t.TipsAndTricks1}</li>
        <li className="gen_panel__main_item">{t.TipsAndTricks2}</li>
        <li className="gen_panel__main_item">{t.TipsAndTricks3}</li>
        <li className="gen_panel__main_item">{t.TipsAndTricks4}</li>
      </ul>

      <AnimatePresence>
        {showOnboarding && !setup.showUserSurvey && !setup.isShownConfirmNotify && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1, transition: { easy: 'easyIn' } }}
            exit={{ opacity: 0, scale: 0 }}
          >
            <Onboarding
              close={() => {
                setShowOnboarding(false);
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default UploadDraftPanelBlock;
