import React, { FC, useState } from 'react';

import { Switcher } from '@app/components/ui';
import { UserData } from '@app/types/user.type';
import { ProductInit } from '@app/types/product.type';
import {
  currency,
  formatPrice,
  tariffOrderClass,
  TariffTitles,
} from '@app/pages/pricing/blocks/tariffs.block';

import t from '../../../../lib/lng';

type CombinedTariffBlockProps = {
  combined: Partial<
    Record<
      Partial<keyof ProductInit['products'][number]['duration']>,
      ProductInit['products'][number]
    >
  >;
  product_balance?: UserData['product_balance'];
  onClickTariff: (product: ProductInit['products'][number] & { productTitle: string }) => void;
};

const CombinedTariffBlock: FC<CombinedTariffBlockProps> = (props) => {
  const { combined, product_balance, onClickTariff } = props;
  const [mode, setMode] = useState<keyof ProductInit['products'][number]['duration']>('years');
  const product = combined[mode ?? 'years'];
  let productTitle = product?.name;
  if (product?.duration.months) productTitle = t.MonthlySubscription;
  if (product?.duration.years) productTitle = t.YearlySubscription;
  let isCurrent = false;
  // Если product_balance передан и активен, проверяем, совпадает ли продолжительность
  if (product_balance?.is_active) {
    if (product_balance.product.info?.duration.months && product?.duration.months) isCurrent = true;
    else if (product_balance.product.info?.duration.years && product?.duration.years)
      isCurrent = true;
  }
  // Если product_balance не передан, ставим isCurrent в false (дефолтное поведение)
  if (!product_balance) {
    isCurrent = false;
  }
  let byMonthPrice = product?.price.amount;
  if (product?.duration.years) {
    byMonthPrice = Math.floor(((byMonthPrice ?? 0) / 12) * 100) / 100;
  }
  if (!product) return <></>;
  return (
    <div
      className={'about8_item highlighted'}
      style={tariffOrderClass?.[product.name as TariffTitles]}
    >
      <div className="about8_item__title_tariff about8_item__title__line title_combine">
        <h3>{product?.name} </h3>
        <div className="tariff_checkbox_switcher">
          <Switcher
            id="sw_tarif"
            name={product?.name.toString().toLowerCase() ?? ''}
            checked={mode === 'years'}
            onCheck={(check: boolean) => {
              setMode(check ? 'years' : 'months');
            }}
          />
          <div
            className={`tariff_check_label ${mode === 'years' && 'tariff_check_label__checked'}`}
          >
            Pay annualy
          </div>
        </div>
      </div>
      <div className="tariff_subtitle">
        {product?.type === 'presentation' ? 'One-time payment' : 'Subscription'}
      </div>
      <div className="about8_item__descr">
        {product?.duration.years && (
          <>For those who love fantastic deals and want to support our team!</>
        )}
        {product?.duration.months && (
          <>A month of wonderful presentations with access to all features</>
        )}
      </div>
      <div className="about8_item__price">
        {currency[product.price.currency]}{' '}
        {!Number.isInteger(byMonthPrice)
          ? +String(byMonthPrice)
          : formatPrice(String(byMonthPrice), product.price.currency)}
        {product.duration.months && <div className="about8_item__price__small">/ month</div>}
        {product.duration.years && <div className="about8_item__price__small">/ month</div>}
        {product.count == 10 && (
          <div className="about8_item__price__small">
            / {product.count} downloads
            <div className="hint info_icon">
              <div className="hint_text hint_text-arrow">
                Get full access to all Wonderslide features
                <br />
                and the ability to download 10 presentations.
              </div>
            </div>
          </div>
        )}
        {!!product.profit && <div className="about8_item__discount">-{product.profit}%</div>}
        {product.duration.years && (
          <div className="about8_item__discount_month">
            {currency[product.price.currency]}
            {+product.price.amount} billed annually
          </div>
        )}
      </div>
      <ul className="about8_item__features">
        <li>
          <strong>Create & share unlimited number</strong> of&nbsp;presentations
        </li>
        <li>
          <strong>Unlimited number of&nbsp;presentations</strong> to&nbsp;download
        </li>
        <li>
          <strong>Up to 30 draft slides </strong>to upload for each presentation
        </li>
        <li>
          <strong>Uploading your logo</strong>
        </li>
        <li>
          <strong>Full access</strong> to&nbsp;image and&nbsp;icon library
        </li>
      </ul>
      <span
        className={
          'about8_item__btn ga_select_plan btn-outlined' +
          (product.duration.days ? ' ga_weekly_plan ' : '') +
          (product.duration.years ? ' ga_yearly_plan ' : '') +
          (product.duration.months ? ' ga_monthly_plan' : '') +
          (product.count == 10 ? ' ga_payg_plan' : '')
        }
        onClick={(e) => {
          onClickTariff({ ...product, productTitle: String(productTitle) });
        }}
      >
        {isCurrent ? 'Your current plan' : t.SelectPlan}
      </span>
    </div>
  );
};

export default CombinedTariffBlock;
