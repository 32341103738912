import { useRef, useState } from 'react';
import cl from 'classnames';
import { ReactSwiper } from '@app/components/ui/react-swiper/react-swiper';
import { Autoplay, EffectCards } from 'swiper';
import { Loader } from '@app/components/ui/loader';
import PremiumIcon from '@icons/premium.svg';
import ArrowIcon from '@icons/arrow.svg';

//TODO: на будущее отвязать стили и переехать на tailwind
const ThemeItem = (props) => {
  const { theme, isActive, select, inactive } = props;
  const swiperRef = useRef(null);
  const [isSwiperReady, setIsSwiperReady] = useState(false);
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  const minNeedCountImg = 3;

  const handleImagesReady = () => {
    //TODO: мб пригодится в будущем
  };

  const handleImageLoad = () => {
    setLoadedImagesCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount === minNeedCountImg) {
        handleImagesReady();
      }
      return newCount;
    });
  };

  const handleMove = (direction) => {
    if (swiperRef.current) {
      if (direction === 'left') {
        swiperRef.current.swiper.swiper.slidePrev();
      } else if (direction === 'right') {
        swiperRef.current.swiper.swiper.slideNext();
      }
    }
  };

  const handleSwiperReady = () => {
    setIsSwiperReady(true);
  };

  return (
    <div
      className={cl('gen_styles_item cursor-pointer', isActive && 'active', inactive && 'inactive')}
      onClick={select}
    >
      <div className="gen_styles_item__imgs">
        {!isSwiperReady && loadedImagesCount < 3 && (
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              zIndex: 2,
              translate: '-50% -50%',
            }}
          >
            <Loader />
          </div>
        )}
        <ReactSwiper
          swiperCfg={{
            slidesPerView: 1,
            grabCursor: true,
            spaceBetween: 1,
          }}
          items={theme.info.covers}
          renderFn={(imageSrc) => (
            <img src={imageSrc} alt="theme img" onLoad={handleImageLoad} loading="lazy" />
          )}
          ref={swiperRef}
          onSwiper={handleSwiperReady}
        />
        <div
          className="gen_styles_item__arr left z-[1]"
          onClick={(e) => {
            handleMove('left');
            e.stopPropagation();
          }}
        >
          {
            <ArrowIcon
              className="text-general_ico_MIV_500_default"
              width={40}
              height={40}
              style={{
                transform: 'rotate(180deg)',
                transformOrigin: '20px 18px',
              }}
            />
          }
        </div>
        <div
          className="gen_styles_item__arr right z-[1]"
          onClick={(e) => {
            handleMove('right');
            e.stopPropagation();
          }}
        >
          {<ArrowIcon className="text-general_ico_MIV_500_default" width={40} height={40} />}
        </div>
      </div>
      <div className="gen_styles_item__name">
        {theme.is_premium && (
          <PremiumIcon
            width={12}
            height={12}
            className="premium-icon text-secondary_warning_SI_400_warning"
          />
        )}
        {theme.name}
        {theme.is_new && <div className="beta">new</div>}
      </div>
    </div>
  );
};

export default ThemeItem;
