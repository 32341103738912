import React from 'react';
import PropTypes from "prop-types";

const Avatar = (props) => {
    const { picture, srcSet, alt, size="middle" } = props;
    const avatarSize = {
        middle: 'ui_avatar__size__middle',
        large: 'ui_avatar__size__large'
    }
    return (
        <div className={`ui_avatar ${avatarSize[size]}`}>
            <img src={picture} srcSet={srcSet} className="ui_avatar__image" alt={alt}/>
        </div>
    );
};

Avatar.prototype = {
    size: PropTypes.oneOf(['middle', 'large']),
    picture: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    srcSet: PropTypes.string
}

export default Avatar;