import React from 'react';

const PlusIcon = ({ size }: { size: number }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="currentColor">
      <path d="M14 6H8V0H6V6H0V8H6V14H8V8H14V6Z" />
    </svg>
  );
};

export default PlusIcon;
