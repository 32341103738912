import { useState, useEffect, useRef } from 'react';

export const useAnimatedSymbol = (text: string, symbol: string = '.', delay: number = 500) => {
  const [displayText, setDisplayText] = useState(text);
  const containerRef = useRef<HTMLDivElement>(null);

  const maxText = `${text}${symbol.repeat(3)}`;
  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayText((prev) => {
        const numSymbols = (prev.match(new RegExp(`\\${symbol}`, 'g')) || []).length;

        if (numSymbols >= 3) {
          return text + ' '.repeat(3);
        }

        return text + symbol.repeat(numSymbols + 1) + ' '.repeat(2 - numSymbols);
      });
    }, delay);

    return () => clearInterval(interval);
  }, [symbol, delay, text]);

  return (
    <div
      ref={containerRef}
      style={{
        minWidth: `${maxText.length}ch`,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'inline-block',
      }}
    >
      {displayText}
    </div>
  );
};
