import React, { useRef } from 'react';
import { useOutletContext } from 'react-router';
import { Swiper, SwiperSlide } from 'swiper/react';

import { route_path } from '@app/utils/route_path';

import { Link } from 'react-router-dom';

import { InfoBox, Message } from '../../components/template';
import Header from '../../components/template/header';
import { Button, Icon, Section } from '../../components/ui';

const INFO_BLOCK_CONTENT = {
  title: (
    <span>
      We are&nbsp;here to&nbsp;make sure your&nbsp;<span className="ff-emoji">&#128161;</span>
      &nbsp;ideas are&nbsp;seen
    </span>
  ),
  description: `Ideas rule the world. Share yours, and we will help convey it.
Have an idea, but don't want to work to get it across? Wander 
will take over the packaging of your idea!`,
  btn: 'Get started',
  bgImageSrcSet:
    '/static/i/about_page/about_header_bg.webp 1x, /static/i/about_page/about_header_bg@2x.webp 2x',
  link: route_path.create,
};

const MESSAGE_LIST_SECTION_1 = [
  {
    poster: 'Lana',
    posterTitle: 'Co-Founder',
    attachmentIndex: 1,
    attachmentRightSize: 'big',
    attachment: '/static/i/about_page/attachment_left_image.webp',
    posterAvatar: `/static/i/about_page/lana_avatar.webp 1x,
                       /static/i/about_page/lana_avatar@2x.webp 2x`,
    texts: [
      <span key="1">
        Hi, friend! Like most tech startups, our story began with a&nbsp;small but ambitious dream —
        to&nbsp;make wasting hours creating presentations a&nbsp;thing of the&nbsp;past
      </span>,
      <span key="2">
        That dream was born out of a&nbsp;common frustration: being graphic designers by trade, we
        found making good-looking presentations to&nbsp;be&nbsp;incredibly time-consuming &#128529;,
        and that was precious time that could be spent with our&nbsp;families instead. So, we got to
        work…
      </span>,
    ],
  },
  {
    type: 'incoming',
    texts: [<span key="3">So what did you do? &#128578;</span>],
  },
  {
    poster: 'Renat',
    posterTitle: 'Chief Executive Officer',
    posterAvatar:
      '/static/i/about_page/renat_avatar.webp 1x, /static/i/about_page/renat_avatar@2x.webp 2x',
    texts: [
      <span key="4">
        We started with only 3 people <span>&#128104;</span>, but have since grown to
        a&nbsp;collective of more than 12 professional presentation enthusiasts, scattered all
        throughout the&nbsp;world but united by one goal.
      </span>,
    ],
  },
];

const MESSAGE_LIST_SECTION_2 = [
  {
    poster: 'Renat',
    posterTitle: 'Chief Executive Officer',
    posterAvatar:
      '/static/i/about_page/renat_avatar.webp 1x, /static/i/about_page/renat_avatar@2x.webp 2x',
    texts: [
      <span key="5">
        Today, Wonderslide is a&nbsp;young and dynamic team, working tirelessly everyday
        to&nbsp;make it the&nbsp;best AI presentation designer around
      </span>,
    ],
  },
  {
    type: 'incoming',
    texts: [<span key="6">But what is an&nbsp;AI presentation designer, exactly? &#129320;</span>],
  },
  {
    poster: 'Dmitry',
    posterTitle: 'Co-Founder',
    posterAvatar:
      '/static/i/about_page/dmitry_avatar.webp 1x, /static/i/about_page/dmitry_avatar@2x.webp 2x',
    texts: [
      <span key="7">
        To achieve our goal of making presentations fast and easy, we saw no other way but
        to&nbsp;work with artificial intelligence. Traditional presentation solutions require lots
        of input from the&nbsp;user to produce a&nbsp;viable outcome. We aimed to do away with that,
        so that you can focus entirely on your content and leave the&nbsp;design duties for
        Wonderslide
      </span>,
    ],
  },
  {
    poster: 'Vitaly',
    posterTitle: 'Lead of Engineers',
    posterAvatar:
      '/static/i/about_page/vitaly_avatar.webp 1x, /static/i/about_page/vitaly_avatar@2x.webp 2x',
    texts: [
      <span key="8">
        At the heart of Wonderslide is a&nbsp;complex of custom-built neural networks that analyze
        and structure your content, applying graphic and visual elements on-the-fly. These neural
        networks have been trained on smart templates created by professional graphic designers,
        leveraging various design principles to&nbsp;make your presentations not just beautiful, but
        also clean and engaging
      </span>,
    ],
  },
  {
    type: 'incoming',
    texts: [<span key="111">AI? Is this a&nbsp;popular trend today?</span>],
  },
  {
    poster: 'Dmitry',
    attachmentRightSize: 'small',
    isAttachmentTop: true,
    attachment: '/static/i/about_page/typography_attachment.webp',
    posterTitle: 'Co-Founder',
    posterAvatar:
      '/static/i/about_page/dmitry_avatar.webp 1x, /static/i/about_page/dmitry_avatar@2x.webp 2x',
    texts: [
      <span key="9">
        Yes! And, best of all, they are&nbsp;constantly self-improving, making Wonderslide
        a&nbsp;better designer with each passing day &#9757;
      </span>,
    ],
  },
  {
    type: 'incoming',
    texts: [
      <span key="10">
        Cool &#128077;
        <br />
        Where are you heading now?
      </span>,
    ],
  },
  {
    poster: 'Renat',
    posterTitle: 'Chief Executive Officer',
    posterAvatar:
      '/static/i/about_page/renat_avatar.webp 1x, /static/i/about_page/renat_avatar@2x.webp 2x',
    texts: [
      <span key="11">
        Wonderslide has come a&nbsp;long way since its initial launch , but we are very far from
        done. With many cool new features already in the&nbsp;pipeline and dozens more still to
        come, we plan on making it the&nbsp;only presentation service you’ll ever need, period
      </span>,
      <span key="12">
        Here’s just a&nbsp;little taste of what’s coming your way: more and improved AI
        capabilities, better charts &#128202; and tables, expanded image &#128444; library and much
        more
      </span>,
    ],
  },
  {
    type: 'incoming',
    texts: ['Great! Where can I see updates?'],
  },
  {
    poster: 'Konstantin',
    attachmentIndex: 1,
    attachmentRightSize: 'small',
    attachment: '/static/i/about_page/attachment_document@2x.webp',
    posterTitle: 'Head of Partnerships, Communications and PR',
    posterAvatar:
      '/static/i/about_page/konstantin_avatar.webp 1x, /static/i/about_page/konstantin_avatar@2x.webp 2x',
    texts: [
      <span key="13">
        So, stay tuned for more to&nbsp;come! You can follow us on{' '}
        <a href="https://twitter.com/wonderslide" target="_blank" rel="noreferrer">
          X (Twitter)
        </a>{' '}
        to be the&nbsp;first one to&nbsp;know about major updates. There’s memes too, sometimes
        &#128513;
      </span>,
      <span key="14">
        Still not a&nbsp;Wonderslide user? Check out{' '}
        <Link to={route_path.pricing} target="_blank">
          our plans
        </Link>{' '}
        and take your presentation game to&nbsp;the&nbsp;next&nbsp;level
      </span>,
    ],
  },
  {
    type: 'incoming',
    texts: ["OK thanks. I guess I'll try it right now"],
  },
  {
    poster: 'Renat',
    posterTitle: 'Chief Executive Officer',
    posterAvatar:
      '/static/i/about_page/renat_avatar.webp 1x, /static/i/about_page/renat_avatar@2x.webp 2x',
    texts: [<span key="15">Great! Let's shape the&nbsp;future together!</span>],
  },
];

const SLIDER_LIST = [
  '/static/i/about_page/slider_image_1.webp 1x, /static/i/about_page/slider_image_1@2x.webp 2x',
  '/static/i/about_page/slider_image_2.webp 1x, /static/i/about_page/slider_image_2@2x.webp 2x',
  '/static/i/about_page/slider_image_3.webp 1x, /static/i/about_page/slider_image_3@2x.webp 2x',
  '/static/i/about_page/slider_image_4.webp 1x, /static/i/about_page/slider_image_4@2x.webp 2x',
  '/static/i/about_page/slider_image_5.webp 1x, /static/i/about_page/slider_image_5@2x.webp 2x',
  '/static/i/about_page/slider_image_6.webp 1x, /static/i/about_page/slider_image_6@2x.webp 2x',
  '/static/i/about_page/slider_image_1.webp 1x, /static/i/about_page/slider_image_1@2x.webp 2x',
  '/static/i/about_page/slider_image_2.webp 1x, /static/i/about_page/slider_image_2@2x.webp 2x',
  '/static/i/about_page/slider_image_3.webp 1x, /static/i/about_page/slider_image_3@2x.webp 2x',
  '/static/i/about_page/slider_image_4.webp 1x, /static/i/about_page/slider_image_4@2x.webp 2x',
  '/static/i/about_page/slider_image_5.webp 1x, /static/i/about_page/slider_image_5@2x.webp 2x',
  '/static/i/about_page/slider_image_6.webp 1x, /static/i/about_page/slider_image_6@2x.webp 2x',
  '/static/i/about_page/slider_image_1.webp 1x, /static/i/about_page/slider_image_1@2x.webp 2x',
  '/static/i/about_page/slider_image_2.webp 1x, /static/i/about_page/slider_image_2@2x.webp 2x',
  '/static/i/about_page/slider_image_3.webp 1x, /static/i/about_page/slider_image_3@2x.webp 2x',
  '/static/i/about_page/slider_image_4.webp 1x, /static/i/about_page/slider_image_4@2x.webp 2x',
  '/static/i/about_page/slider_image_5.webp 1x, /static/i/about_page/slider_image_5@2x.webp 2x',
  '/static/i/about_page/slider_image_6.webp 1x, /static/i/about_page/slider_image_6@2x.webp 2x',
  '/static/i/about_page/slider_image_1.webp 1x, /static/i/about_page/slider_image_1@2x.webp 2x',
  '/static/i/about_page/slider_image_2.webp 1x, /static/i/about_page/slider_image_2@2x.webp 2x',
  '/static/i/about_page/slider_image_3.webp 1x, /static/i/about_page/slider_image_3@2x.webp 2x',
  '/static/i/about_page/slider_image_4.webp 1x, /static/i/about_page/slider_image_4@2x.webp 2x',
  '/static/i/about_page/slider_image_5.webp 1x, /static/i/about_page/slider_image_5@2x.webp 2x',
  '/static/i/about_page/slider_image_6.webp 1x, /static/i/about_page/slider_image_6@2x.webp 2x',
  '/static/i/about_page/slider_image_1.webp 1x, /static/i/about_page/slider_image_1@2x.webp 2x',
  '/static/i/about_page/slider_image_2.webp 1x, /static/i/about_page/slider_image_2@2x.webp 2x',
  '/static/i/about_page/slider_image_3.webp 1x, /static/i/about_page/slider_image_3@2x.webp 2x',
  '/static/i/about_page/slider_image_4.webp 1x, /static/i/about_page/slider_image_4@2x.webp 2x',
  '/static/i/about_page/slider_image_5.webp 1x, /static/i/about_page/slider_image_5@2x.webp 2x',
  '/static/i/about_page/slider_image_6.webp 1x, /static/i/about_page/slider_image_6@2x.webp 2x',
  '/static/i/about_page/slider_image_1.webp 1x, /static/i/about_page/slider_image_1@2x.webp 2x',
  '/static/i/about_page/slider_image_2.webp 1x, /static/i/about_page/slider_image_2@2x.webp 2x',
  '/static/i/about_page/slider_image_3.webp 1x, /static/i/about_page/slider_image_3@2x.webp 2x',
  '/static/i/about_page/slider_image_4.webp 1x, /static/i/about_page/slider_image_4@2x.webp 2x',
  '/static/i/about_page/slider_image_5.webp 1x, /static/i/about_page/slider_image_5@2x.webp 2x',
  '/static/i/about_page/slider_image_6.webp 1x, /static/i/about_page/slider_image_6@2x.webp 2x',
];

const AboutPage = () => {
  const { context, updateData } = useOutletContext();
  const sw = useRef(null);

  function swipeNavigationHandler(navigate) {
    if (sw?.current) {
      if (navigate > 0) {
        sw.current.slideNext();
      } else {
        sw.current.slidePrev();
      }
    }
  }

  return (
    <div className="about_page">
      <InfoBox
        header={<Header context={context} updateData={updateData} isTransparent />}
        title={INFO_BLOCK_CONTENT.title}
        description={INFO_BLOCK_CONTENT.description}
        btnText={INFO_BLOCK_CONTENT.btn}
        bgImageSrcSet={INFO_BLOCK_CONTENT.bgImageSrcSet}
        link={INFO_BLOCK_CONTENT.link}
      />
      <Section size="small" className="about_page_chat_section about_page_chat_section__first">
        {MESSAGE_LIST_SECTION_1.map((props, index) => (
          <Message key={index} {...props} />
        ))}
      </Section>
      <div className="about_page_swiper">
        <div
          className="about_page_swiper__navigation about_page_swiper__navigation__prevEl"
          onClick={swipeNavigationHandler.bind(null, -1)}
        >
          <Icon name="ArrowLeftIcon" size={32} className="about_page_swiper__navigation__icon" />
        </div>
        <div
          className="about_page_swiper__navigation about_page_swiper__navigation__nextEl"
          onClick={swipeNavigationHandler.bind(null, 1)}
        >
          <Icon name="ArrowRightIcon" size={32} className="about_page_swiper__navigation__icon" />
        </div>
        <Swiper
          ref={sw}
          spaceBetween={32}
          slidesPerView={3}
          centeredSlides={true}
          loop={true}
          onSwiper={(swiper) => (sw.current = swiper)}
        >
          {SLIDER_LIST.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="about_page_swiper_slide">
                <img srcSet={image} alt="about" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Section size="small" className="about_page_chat_section about_page_chat_section__second">
        <div className="about_page_bg_section">
          <img src="/static/i/about_page/about_bg.svg" alt="about" />
        </div>
        {MESSAGE_LIST_SECTION_2.map((props, index) => (
          <Message key={index} {...props} />
        ))}
      </Section>
      <Section className="bottom_about">
        <Button label="Get started" size="middle" rounded="large" link={route_path.create} isLink />
      </Section>
    </div>
  );
};

export default AboutPage;
