import { useEffect } from 'react';
import type { Font } from '@app/types/presentation.type';

const fontCache = new Set<string>();

export const useLazyLoadFonts = (fonts: Font[], baseUrl: string | undefined) => {
  useEffect(() => {
    if (!fonts || fonts.length === 0 || !baseUrl) return;

    fonts.forEach((font) => {
      if (!font.name || !font.file) return;

      if (fontCache.has(font.name)) return;

      const fontFileUrl = `${baseUrl}${font.file}`;

      const fileExtension = font.file.split('.').pop()?.toLowerCase();
      let format = 'truetype';
      let type = 'font/ttf';

      if (fileExtension === 'woff') {
        format = 'woff';
        type = 'font/woff';
      } else if (fileExtension === 'woff2') {
        format = 'woff2';
        type = 'font/woff2';
      }

      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'font';
      link.href = fontFileUrl;
      link.type = type;
      link.crossOrigin = 'anonymous';
      document.head.appendChild(link);

      const fontFace = `
        @font-face {
          font-family: '${font.name}';
          src: url('${fontFileUrl}') format('${format}');
        }
      `;

      const style = document.createElement('style');
      style.textContent = fontFace;
      document.head.appendChild(style);

      fontCache.add(font.name);
    });
  }, [fonts, baseUrl]);
};
