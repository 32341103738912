import React, { useEffect, useRef, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useOutletContext } from 'react-router';
import cl from 'classnames';

import { useLazyDeleteLogoQuery } from '@app/store/api/presentation.api';
import { useClickOutside } from '@app/hooks/use-click-outside';
import t from '@lib/lng';

import UploadLogo from './upload-logo.block';

const fontsDescrs = {
  'Alumni Sans': 'digital, natural',
  Comfortaa: 'gentle, soft',
  'Exo 2': 'futuristic, technologic',
  'IBM Plex Sans': 'corporate, premium',
  Montserrat: 'solid, modern',
  Nunito: 'friendly, neutral',
  'Open Sans': 'universal, neutral',
  Oswald: 'official, scientific',
  Play: 'reserved, financial',
  Roboto: 'universal, neutral',
  Mulish: 'corporate, premium',
  Bellota: 'friendly, neutral',
  'Noto Sans': 'friendly, neutral',
  'Advent Pro': 'futuristic, technologic',
  'Playfair Display': 'friendly, elegant',
  Ubuntu: 'reserver, financial',
  Lora: 'classic, elegant',
  Jost: 'solid, modern',
  'Cormorant Infant': 'friendly, elegant',
  'Sofia Sans': 'digital, natural',
};

const GenSelectBlock = (props) => {
  const {
    options,
    activeItem,
    onSelect,
    name,
    preset,
    isCorporate,
    isLocked,
    onSetUploadedLogo,
    scrollExtraClasses,
  } = props;
  const isRemovable = true;
  const { updateData, setTrialPopup } = useOutletContext();
  const [deleteLogoQuery] = useLazyDeleteLogoQuery();
  const [isShowUploadLogo, setShowUploadLogo] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const genSelectRef = useRef(null);
  useEffect(() => {
    function closeOnClick(event) {
      if (isOpen && event.target.closest('.gen_select') !== genSelectRef.current) {
        setIsOpen(false);
      }
    }
    window.addEventListener('click', closeOnClick);

    return () => {
      window.removeEventListener('click', closeOnClick);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && genSelectRef?.current?.getBoundingClientRect().bottom > window.innerHeight) {
      genSelectRef.current.scrollIntoView(false);
    }
  }, [isOpen]);

  useClickOutside(genSelectRef, () => setIsOpen(false));

  function showUploadLogo() {
    if (isLocked) {
      setTrialPopup({ type: 'logo' });
    } else {
      setShowUploadLogo(true);
    }
  }

  function deleteLogoHandler(id) {
    // if (this.props.context['user-preset'].logo.filter(i => i.id == id)[0].value == this.state.options.logo) {
    //     this.state.options.logo = '';
    //     updateData();
    // }
    deleteLogoQuery({ id })
      .unwrap()
      .then((deleteLogoResponse) => {
        if (deleteLogoResponse?.status) {
          updateData();
        }
      })
      .catch((error) => {
        console.error('[DELETE LOGO ERROR]: ', error);
      });
    // let response = await fetch('/logo-delete/'+id, {
    //     headers: xhrHeaders()
    // });
    // if (response.status) {
    //     this.props.updateData();
    // }
  }

  // function deleteItem(...params){
  //     console.log('deleteItems', params);
  // }

  let activeItemName = options?.filter((item) => item.value === activeItem)[0],
    fromPreset = false,
    colorBg = '';

  if (!activeItemName && preset) {
    activeItemName = preset?.filter((item) => item.value === activeItem)[0];
    fromPreset = true;
  }

  if (activeItemName) {
    if (activeItemName.color) colorBg = activeItemName.color;
    if (name === 'font' || name === 'logo') {
      activeItemName = activeItemName.value;
    } else {
      activeItemName = activeItemName.name;
    }
  } else if (name === 'logo') {
    activeItemName = t.NoLogo;
  } else {
    activeItemName = t.Choose;
  }

  if (name === 'logo') {
    return (
      <div
        className={cl('gen_select gen_select-logos', isOpen && 'open', activeItem && 'selected')}
        style={{ backgroundColor: colorBg ? colorBg : '' }}
        ref={(n) => {
          genSelectRef.current = n;
        }}
      >
        <div
          className="gen_select__current"
          onClick={() => {
            setIsOpen((prevState) => !prevState);
          }}
        >
          {!activeItem && activeItemName}
          {activeItem && fromPreset && (
            <img
              className="gen_select__img"
              src={
                activeItemName.match(/^\//) || activeItemName.match('https://')
                  ? activeItemName
                  : '/' + activeItemName
              }
              alt=""
            />
          )}
          {activeItem && !fromPreset && (
            <img className="gen_select__img" src={activeItemName} alt="" />
          )}
        </div>
        {
          <div className="gen_select__options" hidden={!isOpen}>
            <div className={cl('gen_select__options__scroll', scrollExtraClasses)}>
              <div className="gen_select__logo_btns contents">
                <div
                  className={'gen_select__nologo' + (!activeItem ? ' active' : '')}
                  onClick={() => {
                    onSelect('', '');
                    setIsOpen((prevState) => !prevState);
                  }}
                >
                  {t.NoLogo}
                </div>
                {!isCorporate && (
                  <div className="gen_select__upload_logo" onClick={showUploadLogo}></div>
                )}
              </div>
              {preset && (
                <ul className="contents">
                  {preset.map((item, i) => (
                    <li
                      key={i}
                      style={{
                        backgroundColor: item.color ? item.color : '#000',
                      }}
                      className={'gen_select__logo' + (item.value === activeItem ? ' active' : '')}
                      onClick={() => {
                        onSelect(item.value);
                        setIsOpen((prevState) => !prevState);
                      }}
                    >
                      <img
                        className="gen_select__logo_img"
                        src={
                          item.value.match(/^\//) || item.value.match('https://')
                            ? item.value
                            : '/' + item.value
                        }
                      />
                      {isRemovable && (
                        <div
                          className="gen_select__logo_delete"
                          onClick={(e) => {
                            deleteLogoHandler(item.id);
                            e.stopPropagation();
                          }}
                        ></div>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        }
        <AnimatePresence>
          {isShowUploadLogo && <UploadLogo onCloseUpload={() => setShowUploadLogo(false)} />}
        </AnimatePresence>
      </div>
    );
  }

  return (
    <div
      className={cl(
        'gen_select hover:z-[100] ',
        name && ' gen_select-' + name,
        isOpen ? ' open' : '',
      )}
      onClick={() => {
        setIsOpen((prevState) => !prevState);
      }}
      ref={(n) => {
        genSelectRef.current = n;
      }}
    >
      <div
        className="gen_select__current"
        style={
          name === 'font'
            ? {
                fontFamily:
                  options.find(
                    (font) => font.name === activeItemName && font.name !== t.AutomaticSelection,
                  )?.value || 'Roboto',
              }
            : { fontFamily: 'Roboto' }
        }
      >
        {activeItemName}
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            variants={{
              initial: { opacity: 0, top: 41 },
              animate: { opacity: 1, top: 47 },
              exit: { opacity: 0, top: 41 },
            }}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{
              delay: 0,
              duration: 0.3,
            }}
            className="absolute"
          >
            <div className="gen_select__options">
              <div className="gen_select__options__scroll">
                <div>
                  {preset && (
                    <div className="gen_select__options__preset">
                      {/* <div className="gen_select__options__preset_name">{t.Corporative} {presetNames[name]}</div> */}
                      {preset.map((item, i) => {
                        if (name === 'font' && item.face !== 'regular') return;
                        return (
                          <div
                            key={i}
                            className={
                              'gen_select__option' + (item.value === activeItem ? ' active' : '')
                            }
                            onClick={() => {
                              onSelect(name, item.value, true);
                            }}
                          >
                            {name === 'font' ? item.value : item.name}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {options.map((item, i) => (
                    <div
                      key={i}
                      className={
                        'gen_select__option' +
                        (item.value === activeItem ? ' active' : '') +
                        (name && name.match(/font/) && item.value === t.AutomaticSelection
                          ? ' dedicated'
                          : '')
                      }
                      onClick={() => {
                        onSelect(item.value);
                      }}
                      style={
                        name === 'font' && item.value !== t.AutomaticSelection
                          ? {
                              fontFamily: item.value === 'Exo 2' ? 'Exo-2' : item.value,
                            }
                          : {}
                      }
                    >
                      {item.name}
                      {name === 'font' && item.value !== t.AutomaticSelection && (
                        <span className="gen_select__option_descr">
                          {fontsDescrs[item.value]}
                          {/* TODO:(pavel) в будущем на беке будут */}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {}
      <AnimatePresence>
        {isShowUploadLogo && (
          <UploadLogo
            onSetUploadedLogo={onSetUploadedLogo}
            onCloseUpload={() => setShowUploadLogo(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default GenSelectBlock;
