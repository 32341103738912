import React, { useContext, useEffect, useState } from 'react';
import { rgbaToHex } from '@uiw/react-color';
import cl from 'classnames';

import { AnimatePresence, motion } from 'framer-motion';

import { useAppDispatch, useAppSelector } from '@app/hooks/use-store';
import t from '@lib/lng';
import { contrast } from '@lib/utils';
import { themeColors } from '@app/data';
import { useRemakeSlideDesignMutation } from '@app/store/api/slide.api';
import { useToastContext } from '@app/hooks/use-toast-provider';
import { setGlobalRequest } from '@app/store/slice/common.slice';
import { analytics } from '@app/app/analytics';

import { SlideEditContent } from '../project-id.page';

import Dropdown from '../../../common/dropdown';
import { svgIcons } from '../../create/data/svg-icons.data';

import SlideEditPanel from './slide-edit-panel.block';
import DebugInfo from './debug-info.block';
import EditPoint from './slide-edit-point.block';
import SlideMenu from './slide-menu.block';

import SlideLoaderBlock from './slide-loader.block';

const LNG = {
  edit_content: 'Edit content',
};

const SlideResultItemBlock = (props) => {
  const {
    slideIndex,
    arrayIdx,
    addRef,
    slideIsLoading,
    onToggleEditMode,
    themeOption,
    shapes,
    projectId,
    isEditable,
    noClone,
    noDelete,
    setLastOperation,
  } = props;
  const [showDebugInfo, setShowDebugInfo] = useState(null);
  const {
    setIsEditPointOpen,
    refetch: presentationRefetch,
    presentation,
  } = useContext(SlideEditContent);
  const [editContent, setEditContent] = useState({});
  const dispatch = useAppDispatch();
  const isBlured = false;
  let hasRewrittenByGPT = false;
  const readonly = false;
  const { isGlobalRequest } = useAppSelector((state) => state.commonState);
  const [remakeSlideDesignMutation, { isFetching: isFetchDesignRemake }] =
    useRemakeSlideDesignMutation();
  const [postfix, setPostfix] = useState('');
  const [thumbSrc, setThumbSrc] = useState('');
  const { getToast } = useToastContext();
  let bgImage = null;

  useEffect(() => {
    if (presentation.download && presentation.download.thumb[slideIndex]) {
      setThumbSrc(presentation.download.thumb[slideIndex]);
    }
  }, [presentation, slideIndex]);

  if (presentation.images_info && presentation.images_info.slide[slideIndex]) {
    presentation.images_info.slide[slideIndex].shapes.forEach((image) => {
      if (image.image_type === 'Photo' && image.shape_id === -1) {
        bgImage = image;
      }
    });
  }

  function changeEditModeHandle(status) {
    if (isGlobalRequest) return;
    onToggleEditMode?.(status);
  }
  function editShape({ slideInfo, slideIndex, index, type }) {
    setEditContent({});
    setIsEditPointOpen(false);
    setEditContent({ slideInfo, slideIndex, index, type });
    setIsEditPointOpen(true);
  }
  useEffect(() => {
    setPostfix('?' + Date.now());
  }, [presentation]);

  function sendAnalytic(type) {
    analytics.emitEvent('design_editing', {
      GTM: {
        editing_location: 'under_preview_interaction',
        editable_element: getElementForAmplitude(type),
        processed_file_id: +projectId,
      },
      Amplitude: {
        element: getElementForAmplitude(type),
        'editing location': 'under preview interaction',
        'processed file id': +projectId,
        'slide number': +slideIndex + 1,
      },
    });
  }
  function changeSlideDesignHandler(changeType) {
    dispatch(setGlobalRequest({ requesting: true }));
    sendAnalytic(changeType);
    const formData = new FormData();
    setLastOperation(changeType);
    formData.append('action_type', changeType);
    formData.append('wonder_file_id', presentation?.remake?.slide?.[slideIndex]?.wonder_file_id);
    const ratioWhite = +contrast([255, 255, 255], themeOption.color_value).toFixed(2),
      ratioBlack = +contrast([0, 0, 0], themeOption.color_value).toFixed(2);
    let textColor = [];

    if (ratioWhite > 2) textColor.push('FFFFFF');
    if (ratioBlack > 4.2) textColor.push('000000');
    if (ratioWhite <= 2 && ratioBlack <= 4.2) {
      if (ratioWhite >= ratioBlack) textColor.push('FFFFFF');
      else textColor.push('000000');
    }
    const hexThemeColor = rgbaToHex(themeOption.color_value);
    if (
      themeColors.includes(hexThemeColor.toLowerCase()) ||
      (hexThemeColor.toLowerCase() === 'ed2626' && textColor.length > 1)
    )
      textColor = ['FFFFFF'];
    formData.append('text_color', JSON.stringify(textColor));
    remakeSlideDesignMutation({ body: formData, params: { projectId } })
      .unwrap()
      .then((resultOfRemake) => {
        if (!resultOfRemake.status) {
          console.error('[REMAKE ERROR]:', resultOfRemake?.error ?? '');
          getToast({ systemMessage: { msg: 'REMAKE DESIGN ERROR' } });
        } else {
          presentationRefetch();
          // useLoadQuery({ projectId }).unwrap().then((presentation) => {
          //     presentationQuery(presentation.result);
          // });
          analytics.emitEvent('design_editing_done', {
            Amplitude: {
              'editing location': 'under preview interaction',
              element: getElementForAmplitude(changeType),
              'slide number': +slideIndex + 1,
              'processed file id': +projectId,
            },
          });
        }
      });
  }

  function getElementForAmplitude(typeEvent) {
    const currentType = {
      change_color: 'color',
      force_white_bg: 'background_white',
      undo: 'undo',
      redo: 'redo',
      change_layout: 'layout',
      image: 'background_image',
    };
    return currentType[typeEvent];
  }

  function showSettingsOut() {
    // console.log('showSettingsOut');
  }
  if (!presentation || !thumbSrc) return <SlideLoaderBlock slideIndex={slideIndex} />;
  return (
    <>
      <div
        className={'gen_result__item' + (isBlured ? ' blur' : '')}
        ref={(n) => {
          addRef(slideIndex, n);
        }}
      >
        <div className="create_slide_number gen_result__show_debug">
          <span
            onClick={() => {
              setShowDebugInfo(slideIndex);
            }}
          >
            {t.slide} {+arrayIdx + 1}
          </span>
          {isEditable && (
            <SlideMenu
              slideIndex={slideIndex}
              projectId={projectId}
              noDelete={!isEditable || noDelete}
              noClone={!isEditable || noClone}
            />
          )}
        </div>
        <div className="gen_result__wrap rounded-[8px]">
          {
            <>
              <img
                className="gen_result__img rounded-[8px]"
                src={thumbSrc + postfix}
                alt=""
                onError={() => setThumbSrc(null)}
              />
              {!isBlured && !readonly && (
                <div className="editpoints">
                  {shapes.shapesInfo &&
                    shapes.shapesInfo.map((item, j) => {
                      return (
                        <EditPoint
                          key={j}
                          editData={item}
                          slideIndex={slideIndex}
                          editing={editContent.type === 'shape' && editContent.index === j}
                          edit={() => {
                            editShape({ slideInfo: item, slideIndex, index: j, type: 'shape' });
                          }}
                          type="shape"
                          projectId={presentation.process.file_info.project_id}
                        />
                      );
                    })}
                  {shapes?.imageInfo?.shapes.map((item, j) => {
                    if (shapes.shapesInfo.some((s) => s?.icon === item?.inner_id)) return;
                    if (item?.image_type === 'Photo' && item?.shape_id === -1) return;
                    return (
                      <EditPoint
                        key={j}
                        editData={item}
                        editing={editContent.type === 'image' && editContent.index === j}
                        // edit={() => {editImageShape(slideIndex, j)}}
                        edit={() => {
                          editShape({ slideInfo: item, slideIndex, index: j, type: 'image' });
                        }}
                        type="image"
                        projectId={presentation.process.file_info.project_id}
                      />
                    );
                  })}
                </div>
              )}
            </>
          }
        </div>
        {!isBlured && !readonly && (
          <div className="create_slide__cbar">
            {presentation.download && thumbSrc && (
              <>
                <div className="create_slide__cbar_btns">
                  <div
                    className={cl(
                      'create_slide__cbar_btn about_plain_link',
                      (slideIsLoading || isGlobalRequest || !isEditable) && 'disabled',
                    )}
                    onClick={() => changeEditModeHandle(true)}
                  >
                    {svgIcons.arrowLeft} {LNG.edit_content}
                  </div>
                  <div className="create_slide__cbar_actions">
                    <div
                      className={cl(
                        'create_slide__cbar_btn about_plain_link',
                        (!presentation.remake ||
                          !presentation.remake.slide[slideIndex] ||
                          !presentation.remake.slide[slideIndex].actions.includes('undo') ||
                          slideIsLoading ||
                          isGlobalRequest) &&
                          'disabled',
                      )}
                      onClick={() => {
                        changeSlideDesignHandler('undo');
                      }}
                    >
                      {svgIcons.undo}
                      {t.Undo}
                    </div>
                    <Dropdown
                      className={
                        isFetchDesignRemake || slideIsLoading || isGlobalRequest ? ' disabled' : ''
                      }
                      name="Change design"
                      items={[
                        (function () {
                          if (bgImage === null) {
                            return {
                              name: t.MakeWhiteBackground,
                              onClick: () => {
                                changeSlideDesignHandler('force_white_bg');
                              },
                              disabled:
                                !presentation.remake ||
                                !presentation.remake.slide[slideIndex] ||
                                !presentation.remake.slide[slideIndex].actions.includes(
                                  'force_white_bg',
                                ),
                            };
                          } else {
                            return {
                              name: t.ChangeBgPhoto,
                              onClick: () => {
                                editShape({
                                  slideInfo: bgImage,
                                  slideIndex,
                                  index: bgImage.id,
                                  type: 'image',
                                });
                                sendAnalytic('image');
                              },
                            };
                          }
                        })(),
                        {
                          name: t.changeColors,
                          onClick: () => {
                            changeSlideDesignHandler('change_color');
                          },
                          disabled:
                            !presentation.remake ||
                            !presentation.remake.slide[slideIndex] ||
                            !presentation.remake.slide[slideIndex].actions.includes('change_color'),
                        },
                        // { change_color: 'color', force_white_bg: 'background_white', undo: 'undo', change_layout: 'layout', image: 'background_image' }
                        // {
                        //     name: t.ReplaceImages,
                        //     onClick: () => {changeSlideDesignHandler('change_visual_asset')},
                        //     disabled: (!presentation.remake || !presentation.remake.slide[slideIndex] || !presentation.remake.slide[slideIndex].actions.includes('change_visual_asset')),
                        // },
                        {
                          name: t.ChangeLayout,
                          onClick: () => {
                            changeSlideDesignHandler('change_layout');
                          },
                          disabled:
                            !presentation.remake ||
                            !presentation.remake.slide[slideIndex] ||
                            !presentation.remake.slide[slideIndex].actions.includes(
                              'change_layout',
                            ),
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="gen_warnings">
                  {hasRewrittenByGPT && (
                    <div className="gen_warning gen_warning-gpt">
                      <div className="gen_warning__content">
                        {t.chatgptInfo}
                        <div className="gen_result__info_sign_wrap">
                          <div className="gen_result__info_sign">
                            <div className="gen_result__info_sign_text">
                              {t.toDisableGptGoTo}
                              <span className="about_plain_link" onClick={showSettingsOut}>
                                {t.settings}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!!presentation.warning[slideIndex].length && (
                    <div className="gen_warning">
                      <div className="gen_warning__content">
                        {presentation.warning[slideIndex][0]}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {presentation && Object.keys(editContent).length ? (
        <SlideEditPanel
          allImages={shapes?.imageInfo?.all_images ?? {}}
          shapes={shapes?.imageInfo?.shapes ?? {}}
          remake={presentation?.remake?.slide?.[slideIndex]}
          slideIndex={slideIndex}
          projectId={projectId}
          option={presentation.process?.option?.[0]}
          closeEditPanel={() => {
            setEditContent({});
            setIsEditPointOpen(false);
          }}
          editContent={editContent}
        />
      ) : (
        <></>
      )}

      {showDebugInfo !== null && (
        <AnimatePresence>
          <motion.div
            className="w-full h-full inset-0 fixed z-[9999]"
            initial={{ scale: 0 }}
            animate={{ scale: '100%' }}
            exit={{ scale: 0 }}
          >
            <DebugInfo
              queue={presentation?.queue}
              slideIndex={slideIndex}
              close={() => setShowDebugInfo(null)}
            />
          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
};

export default SlideResultItemBlock;
