import React from 'react';
import cl from 'classnames';

const ProcessLoader = () => {
  return (
    <div
      className={cl(
        'fixed',
        'right-[40px] ',
        'bottom-[20px]',
        'z-20',
        'p-[10px]',
        'rounded-[5px]',
        'w-[100px] h-[100px]',
        'bg-general_background_MIII_500_dark',
        'text-general_text_MII_secondary_i',
      )}
    >
      LOADING ...
    </div>
  );
};

export default ProcessLoader;
