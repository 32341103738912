import { useRef, forwardRef, useImperativeHandle } from 'react';
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { defaultCfg } from './config';
import type { ReactSwiperProps } from './react-swiper.types';
import 'swiper/swiper-bundle.css';

export const ReactSwiper = forwardRef(
  (
    {
      swiperCfg,
      items,
      renderFn,
      navigationSlot,
      modules: overflowModules = [],
      className = '',
      onSwiper,
    }: ReactSwiperProps,
    ref,
  ) => {
    const swiperRef = useRef(null);

    useImperativeHandle(ref, () => ({
      swiper: swiperRef.current,
    }));

    const swiperConfig = {
      ...defaultCfg,
      ...swiperCfg,
      onSwiper,
    };

    return (
      <>
        <Swiper
          className={className}
          ref={swiperRef}
          modules={[Pagination, Navigation, ...overflowModules]}
          {...swiperConfig}
        >
          {items.map((props, index) => (
            <SwiperSlide key={index}>{renderFn && renderFn(props)}</SwiperSlide>
          ))}
        </Swiper>
        {navigationSlot && navigationSlot}
      </>
    );
  },
);
