import React, { useEffect, useState } from 'react';
import cl from 'classnames';
import { Link } from 'react-router-dom';
import { redirect, useOutletContext } from 'react-router';
import { useForm } from 'react-hook-form';

import { useLazyGetUserInitQuery, useLoginMutation } from '@app/store/api/user.api';
import { route_path } from '@app/utils/route_path';
import t from '@lib/lng';

import { analytics } from '@app/app/analytics';

import { CustomerCategory } from '@app/app/analytics/types';

import { CommonOutletContext } from '../common.outlet';

const LNG = {
  welcome: 'Welcome back!',
  see_you: 'Glad to See You Again',
  header_desc: 'Your workspace is ready for you',
  errors: {
    required: 'field is required',
  },
};

type LoginFields = {
  username: string;
  password: string;
};

const LoginPage = () => {
  const [authType, setAuthType] = useState('google');
  const [showPassword, setShowPassword] = useState(false);
  const [getUserInit, { isLoading: isUserInitLoading }] = useLazyGetUserInitQuery();
  const { context } = useOutletContext<CommonOutletContext>();
  const [loginMutation, { isLoading: isLoginLoading }] = useLoginMutation();
  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<LoginFields>({});
  useEffect(() => {
    if (typeof context !== 'undefined') redirect(route_path.main);
  }, [context]);
  function submitHandler(data: LoginFields) {
    const formData = new FormData();
    Object.entries(data).forEach(([field, value]) => {
      formData.append(field, value);
    });
    loginMutation({ body: formData })
      .unwrap()
      .then((resultOfLogin) => {
        if (resultOfLogin.status) {
          getUserInit(null)
            .unwrap()
            .then((userData) => {
              if (userData?.result) {
                const context = userData.result;
                let customer_category = '';
                if (
                  context.product_balance &&
                  context.product_balance.product &&
                  !context.product_balance.product?.is_trial
                ) {
                  if (context.product_balance.is_active) {
                    if (context.product_balance.product.count) customer_category = 'PAYG_client';
                    else customer_category = 'subscriber';
                  } else {
                    if (context.product_balance.product.count) customer_category = 'PAYG_exclient';
                    else customer_category = 'exsubscriber';
                  }
                }
                analytics.emitEvent('user_data', {
                  GTM: {
                    user_id: context?.user_ctx.id,
                    // TODO: Add field
                    user_presentations_number: 1,
                    // TODO: Fix as
                    customer_category: customer_category as CustomerCategory,
                  },
                });
              }

              window.sessionStorage.setItem('login', '1');
              if (location.search.includes('?next=')) {
                const url = new URL(location.href);
                const next = url.searchParams.get('next');
                url.searchParams.delete('next');
                url.pathname = next ?? '';
                location.href = url.pathname;
                // use location.href because urls like /purchase/ are not handled by react
              } else {
                window.location.href = '/create/';
              }
            });
        }
      })
      .catch((error) => {
        setError('password', { type: 'serverError', message: error.message });
      });
  }
  // async function submitForm(e) {
  //     e.preventDefault();
  //     setIsLoading(true);
  //     let body = new FormData(e.target);
  //
  //     try {
  //         let response = await fetch('/login/', {
  //             method: 'POST',
  //             body: body,
  //             headers: {'X-Requested-With': 'XMLHttpRequest', 'X-CSRFToken': cookie.get('csrftoken')}
  //         });
  //
  //         let result = await(response.json());
  //
  //         if (result.status) {
  //             props.updateData((context) => {
  //                 setIsLoading(false);
  //                 window.sessionStorage.setItem('login', '1');
  //                 if (location.search.includes('?next=')) {
  //                     let url = new URL(location.href);
  //                     let next = url.searchParams.get('next');
  //                     url.searchParams.delete('next');
  //                     url.pathname = next;
  //                     location.href = url.pathname;
  //                     // use location.href because urls like /purchase/ are not handled by react
  //                 }
  //                 else {
  //                     location.href = '/create/';
  //                     //navigate('/create/', { replace: true });
  //                 }
  //             });
  //         }
  //         else {
  //             if (result.errors) {
  //                 setErrors(result.errors);
  //                 setIsLoading(false);
  //             }
  //         }
  //     }
  //     catch(e) {
  //         console.error(e);
  //         setIsLoading(false);
  //     }
  // }

  return (
    <div className="auth_wrap auth_wrap-new">
      <div className="auth_info_wrap">
        <div className="auth_info">
          <img className="auth_info_img" src="/static/i/w16.webp" alt="W" />
          <div className="auth_info_title">
            {LNG.welcome}
            <br />
            {LNG.see_you}
          </div>
          <div className="auth_info_text">{LNG.header_desc}</div>
        </div>
      </div>

      <div className={'auth auth-login' + (authType === 'email' ? ' auth-email' : '')}>
        <div className="auth_block">
          <div className="auth_menu">
            <span className="auth_menu_item active">{t.Login}</span>
            <Link
              className="auth_menu_item"
              to={'/register/' + (location.search.includes('?next=') ? location.search : '')}
            >
              {t.Signup}
            </Link>
          </div>

          {authType === 'email' && (
            <form className="auth_form" onSubmit={handleSubmit(submitHandler)}>
              <div className="auth_row auth_row-email">
                <input
                  className="auth_input"
                  {...register('username', {
                    required: { value: true, message: LNG.errors.required },
                  })}
                  type="email"
                  placeholder={t.Email}
                  required
                  autoFocus
                />
              </div>

              <div className="auth_row auth_row-pwd">
                <input
                  className="auth_input"
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', {
                    required: { value: true, message: LNG.errors.required },
                  })}
                  placeholder={t.Password}
                />
                <span
                  className="auth_show_pwd"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                ></span>
                {(errors.username || errors.password) && (
                  <div className="auth_error auth_error-all">
                    {errors.username?.message ?? errors.password?.message}
                  </div>
                )}
              </div>

              <button
                className={cl(
                  'auth_submit gen_btn_main',
                  isLoginLoading && isUserInitLoading && 'opacity-80 animate-pulse',
                )}
                disabled={isLoginLoading || isUserInitLoading || isSubmitting}
                type="submit"
              >
                {t.Login}
              </button>
            </form>
          )}

          <a
            className="auth_google_btn"
            href={'/social/login/google-oauth2/' + location.search}
            onClick={() => {
              window.sessionStorage.setItem('auth_by_google', '1');
            }}
          >
            {t.ContinueWithGoogle}
          </a>

          {authType === 'google' && (
            <span
              className="auth_by_email about_plain_link"
              onClick={() => {
                setAuthType('email');
              }}
            >
              {t.LoginByEmail}
            </span>
          )}

          {authType === 'email' && (
            <Link className="auth_forgot_link about_plain_link" to="/password-reset/">
              {t.IForgotMyPassword}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
