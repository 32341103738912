import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cl from 'classnames';
import { useOutletContext } from 'react-router';

import { cookie } from '@app/app/lib/cookie';
import { isEmpty } from '@lib/utils';
import { userLocalParams } from '@lib/utils';
import { useLazyCreateDraftQuery } from '@app/store/api/presentation.api';
import { useAppDispatch } from '@app/hooks/use-store';
import { updateSetup } from '@app/store/slice/common.slice';
import { useAddSlideMutation } from '@app/store/api/slide.api';
import { xhrHeaders } from '@lib/utils';
import { clearPresentation } from '@app/store/slice/presentation.slice';
import { route_path } from '@app/utils/route_path';

import { RegisterQuizFullwidth } from '../../components/template/';
import { analytics } from '@app/app/analytics';
// import prompts from '../data/prompt.data';

const ONBOARDING_TYPE = 'presentation';
// const ONBOARDING_MESSAGE = {
//     title: 'Get a quick tour!',
//     description: 'Learn how to use Wonderslide effectively.\n' +
//         'Pick “Create from scratch” to start.',
//     position: 'horizontal',
//     alignment: 30,
//     boardId: 1,
//     onBoardType: ONBOARDING_TYPE,
//     nowrapDisabled: true,
// }

const StartPage = () => {
  const { context, setTrialPopup } = useOutletContext();
  // const [onBoardingForceShow, setOnboardingForceShow] = useState(false);
  const dispatch = useAppDispatch();
  const [hideDemoBtn, setHideDemoBtn] = useState(userLocalParams.get('userId', 'hideDemoBtn'));
  const [isQuizStart, changeIsQuizStart] = useState(false);
  const [createDraftQuery, { refetch: createDraftRefetch, isLoading: isLoadingCreate }] =
    useLazyCreateDraftQuery();
  const [addSlideMutation, { isLoading: isLoadingAddSlide }] = useAddSlideMutation();
  // const [currentOnboardingStep, changeOnboardingStep] = onboardingMessage(ONBOARDING_TYPE);
  const isOnboardingProcess = localStorage.getItem(`${ONBOARDING_TYPE}_process`) === 'finish';
  const navigate = useNavigate();
  const [initPage, setInitPage] = useState(true);
  const [filesCount, setFilesCount] = useState(0);
  const hasFilesLimit =
    context?.product_balance?.product?.is_trial || !context?.product_balance?.is_active;

  function clearPresentationCache() {
    dispatch(clearPresentation());
  }

  function createScratchHandler() {
    clearPresentationCache();
    analytics.emitEvent('scratch_create_open', {
      GTM: {},
      Amplitude: {},
    });
    onSelectFromScratch();
    // changeOnboardingStep({ freeze: 2, step: 2 });
  }

  function onSelectFromScratch() {
    createDraftQuery()
      .unwrap()
      .then((createdPresentation) => {
        if (!createdPresentation.status) return;
        const fd = new FormData();
        fd.append('index', 0);
        addSlideMutation({
          projectId: Number(createdPresentation.result.project_id),
          body: fd,
        })
          .unwrap()
          .then(() => {
            // let text = '';
            const userId = context?.user_ctx.id;
            const promptsShown = userLocalParams.get(userId, 'prompts');
            let promptOnSlide = {};
            if (!promptsShown) {
              // text = prompts[0];
              promptOnSlide.number = 1;
              userLocalParams.set(userId, 'prompts', '1');
            } else if (promptsShown == '1') {
              // text = prompts[1];
              promptOnSlide.number = 2;
              userLocalParams.set(userId, 'prompts', '2');
            } else if (promptsShown == '2') {
              // text = prompts[2];
              promptOnSlide.number = 3;
              userLocalParams.set(userId, 'prompts', '3');
            }
            dispatch(
              updateSetup({
                projectId: createdPresentation.result.project_id,
                fileSelected: true,
                showResult: true,
                isFromScratch: true,
                styleSaved: false,
                promptOnSlide: promptOnSlide,
              }),
            );
            if (!isEmpty(context?.TAG)) {
              navigate(`/project/${createdPresentation.result.project_id}/`);
            } else {
              navigate(`/create/setup-style/${createdPresentation.result.project_id}/`);
            }
          })
          .catch((error) => {
            console.error('[onSelectFromScratch]: add slide error', error);
          });
      })
      .catch((error) => {
        console.error('[create draft error]: ', error);
        createDraftRefetch();
      });
  }

  useEffect(() => {
    if (hasFilesLimit) {
      setInitPage(true);
      fetch('/projects/', { headers: xhrHeaders() })
        .then((resp) => {
          resp.json().then((respJson) => {
            if (respJson.files) {
              setFilesCount(respJson.files.length);
            }
          });
        })
        .finally(() => setInitPage(false));
    } else {
      setInitPage(false);
    }
  }, [hasFilesLimit]);

  // useEffect(() => {
  //     if(localStorage.getItem(`${ONBOARDING_TYPE}_process`) !== 'finish'){
  //         if(Number(currentOnboardingStep()) === 1){
  //             setTimeout(() => {
  //                 setOnboardingForceShow(true);
  //             }, 2000);
  //         }
  //     }
  // }, []);
  useEffect(() => {
    if (cookie.get('oauth_reg')) {
      changeIsQuizStart(true);
    }
  }, []);

  function onCloseQuizHandler() {
    changeIsQuizStart(false);
  }

  function showTrialPopup() {
    setTrialPopup({ type: 'trial limit' });
  }
  function startDemoHandler(event) {
    event.preventDefault();
    event.stopPropagation();
    if (!context?.product_balance?.is_active) {
      setTrialPopup({ type: 'download expired' });
    } else {
      navigate(`${route_path.uploadDraft}?demo`);
    }
  }

  if (initPage) return <div></div>;
  return (
    <div className="gen_main startpage">
      {isQuizStart && (
        <RegisterQuizFullwidth
          method="google"
          userId={context.user_ctx.id}
          onClose={onCloseQuizHandler}
        />
      )}
      <h1 className="startpage_title">Create new presentation here</h1>
      <div className="startpage_info">Choose the way to design your slides below</div>
      <div className="startpage_btns">
        {hasFilesLimit && filesCount > 2 && (
          <div className="startpage_btns">
            <div>
              <div
                className={cl(
                  'startpage_btn hint',
                  (isLoadingCreate || isLoadingAddSlide) && 'opacity-80',
                )}
                id="link_pptx_draft"
                onClick={showTrialPopup}
              >
                <img className="startpage_btn__img" src="/static/i/create_draft.webp" alt="" />
                <div className="startpage_btn__title">Upload pptx draft</div>
                <div className="startpage_btn__text">
                  Transform your PPTX draft into an editable PowerPoint masterpiece with your text,
                  charts and tables
                </div>
              </div>
            </div>
            {/*<OnboardingMessage boardId={0} forceShow={onBoardingForceShow} isActivate={currentOnboardingStep()} {...ONBOARDING_MESSAGE}>*/}
            <div
              to="/create/"
              className={cl(
                'startpage_btn hint',
                (isLoadingCreate || isLoadingAddSlide) && 'animate-pulse',
              )}
              id="link_from_scratch"
              onClick={showTrialPopup}
            >
              <img className="startpage_btn__img" src="/static/i/create_scratch.webp" alt="" />
              <div className="startpage_btn__title">Create from scratch</div>
              <div className="startpage_btn__text">
                Create eye-catching designs with AI text processing — no&nbsp;PowerPoint needed
              </div>
              <div className="beta">
                beta
                {isOnboardingProcess && (
                  <div className="hint_text hint_text-arrow">
                    This is a beta version that works reliably in English
                    <br />
                    but may have issues with other languages
                  </div>
                )}
              </div>
            </div>
            {/*</OnboardingMessage>*/}
          </div>
        )}
        {(!hasFilesLimit || filesCount < 3) && (
          <div className="startpage_btns">
            <div>
              <Link
                to="/create/upload-draft/"
                className={cl(
                  'startpage_btn hint',
                  (isLoadingCreate || isLoadingAddSlide) && 'opacity-80',
                )}
                onClick={clearPresentationCache}
                id="link_pptx_draft"
              >
                <img className="startpage_btn__img" src="/static/i/create_draft.webp" alt="" />
                <div className="startpage_btn__title">Upload pptx draft</div>
                <div className="startpage_btn__text">
                  Transform your PPTX draft into an editable PowerPoint masterpiece with your text,
                  charts and tables
                </div>
              </Link>
            </div>
            {/*<OnboardingMessage boardId={0} forceShow={onBoardingForceShow} isActivate={currentOnboardingStep()} {...ONBOARDING_MESSAGE}>*/}
            <Link
              to="/create/"
              className={cl(
                'startpage_btn hint',
                (isLoadingCreate || isLoadingAddSlide) && 'animate-pulse',
              )}
              id="link_from_scratch"
              onClick={createScratchHandler}
            >
              <img className="startpage_btn__img" src="/static/i/create_scratch.webp" alt="" />
              <div className="startpage_btn__title">Create from scratch</div>
              <div className="startpage_btn__text">
                Create eye-catching designs with AI text processing — no&nbsp;PowerPoint needed
              </div>
              <div className="beta">
                beta
                {isOnboardingProcess && (
                  <div className="hint_text hint_text-arrow">
                    This is a beta version that works reliably in English
                    <br />
                    but may have issues with other languages
                  </div>
                )}
              </div>
            </Link>
            {/*</OnboardingMessage>*/}
          </div>
        )}
      </div>
      {!hideDemoBtn && (
        <>
          <div className="startpage_how">Learn how to use Wonderslide</div>
          <Link
            className="startpage_demo btn-outlined"
            to="/create/upload-draft/?demo"
            onClick={startDemoHandler}
          >
            {svgIcons.triangle}
            Start demo
            <div
              className="startpage_demo__close"
              onClick={(e) => {
                e.preventDefault();
                userLocalParams.set(context?.user_ctx?.id, 'hideDemoBtn', true);
                setHideDemoBtn(true);
              }}
            >
              {svgIcons.btn_close}
            </div>
          </Link>
        </>
      )}
    </div>
  );
};

export default StartPage;

const svgIcons = {
  triangle: (
    <svg width="11" height="12" viewBox="0 0 11 12" fill="currentColor">
      <path d="M0.5 0V12L10.5 6L0.5 0Z" />
    </svg>
  ),
  btn_close: (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        d="M10.7947 4.72937L7.96599 7.55737L5.13799 4.72937L4.19533 5.67204L7.02333 8.50004L4.19533 11.328L5.13799 12.2707L7.96599 9.4427L10.7947 12.2707L11.7373 11.328L8.90933 8.50004L11.7373 5.67204L10.7947 4.72937Z"
        fill="#292929"
      />
    </svg>
  ),
};
