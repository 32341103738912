import React from 'react';
import PropTypes from "prop-types";
import {Avatar} from "../../ui";

const messageWhoIcon = <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="66.571 126.613 109.95 96.432"
>
    <path
        fill="#EFEFEF"
        stroke="#EFEFEF"
        d="M176.681 124.5l-.064 5.17c-.065 5.17-.193 15.511-27.033 29.785-26.84 14.273-80.39 32.48-80.702 43.343-.312 10.862 52.615 14.382 79.078 16.141l26.463 1.76"
    ></path>
</svg>

const Message = (props) => {
    const {type = "outgoing",
        attachmentIndex=0,
        isAttachmentTop= false,
        attachmentRightSize= "small",
        attachment,
        poster,
        texts,
        posterTitle,
        posterAvatar} = props;
    const messageTypesList = {
        outgoing: 'message_component__wrapper__outgoing',
        incoming: 'message_component__wrapper__incoming'
    }
    const isOutgoing = type === 'outgoing';
    const renderMessage = texts?.map((text, index) => (
        <div key={index} className={`message_component_wrapper ${messageTypesList[type]}`}>
            {attachment && attachmentIndex === index &&
                <div className={isAttachmentTop ?
                    "message_component__attachment__top"
                    : (attachmentRightSize ==='small'
                        ? "message_component__attachment__right__small"
                        : "message_component__attachment__right_big")}>
                    <img src={attachment} alt={poster} />
                </div>}
            {isOutgoing && index === (texts.length - 1) &&
                <div className="message_component__wrapper__outgoing__after">{messageWhoIcon}</div>
            }
            {isOutgoing && index === 0 &&
                <h3 className="message_component__title">{poster},
                    <span className="message_component__title__job">{posterTitle}</span></h3>
            }
            <p className={`message_component__text ${isOutgoing ? 'message_component__text__outgoing' : 'message_component__text__incoming'}`}>{text}</p>
        </div>
    ))
    return (
        <div className={`${isOutgoing ? "message_component__outgoing" : 'message_component__incoming'} message_component`}>
            {isOutgoing &&
                <div className="message_component__avatar"><Avatar alt={poster} picture={posterAvatar?.split(' ')[0]} srcSet={posterAvatar} size="large"/>
                </div>}
            <div className="message_component__list">{renderMessage}</div>
        </div>
    );
};

Message.prototype = {
    type: PropTypes.oneOf(['incoming', 'outgoing']),
    poster: PropTypes.string,
    texts: PropTypes.arrayOf(PropTypes.element).isRequired,
    posterTitle: PropTypes.string,
    posterAvatar: PropTypes.string,
    attachment: PropTypes.string,
    attachmentIndex: PropTypes.number,
    attachmentRightSize: PropTypes.oneOf(['small', 'big']),
    isAttachmentTop: PropTypes.bool,
}

export default Message;