/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, ReactNode, useRef, useEffect } from 'react';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import cn from 'classnames';

import { ArrowSelect } from '../icons';

interface CustomSelectProps {
  headerSlot: ReactNode;
  items: ReactNode[];
  isOpen?: boolean;
  onSelect?: (value: any) => void;
  headerClassName?: string;
  contentClassName?: string;
  layoutClassName?: string;
  animationProps?: Variants;
  delay?: number;
  duration?: number;
  isHiddenToggle?: boolean;
}

const defaultAnimation: Variants = {
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 },
};

export const CustomSelect: React.FC<CustomSelectProps> = ({
  headerSlot,
  items = [],
  isOpen = false,
  headerClassName,
  contentClassName,
  layoutClassName,
  animationProps = defaultAnimation,
  delay = 0,
  duration = 0.3,
  onSelect,
  isHiddenToggle = false,
}) => {
  const [open, setOpen] = useState(isOpen);
  const selectRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const e = event as MouseEvent | TouchEvent;
      if (selectRef.current && !selectRef.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleSelect = (value: any) => {
    if (onSelect) onSelect(value);
    setOpen(false);
  };

  return (
    <div className={cn('flex items-center relative gap-4', layoutClassName)} ref={selectRef}>
      <div
        className={cn('select-header', headerClassName)}
        style={{ cursor: 'pointer' }}
        onClick={toggleOpen}
      >
        {headerSlot}
      </div>

      {!isHiddenToggle && (
        <div
          onClick={toggleOpen}
          className={cn(
            'p-2 flex justify-center items-center transition-all cursor-pointer text-general_text_MII_secondary_i',
            open ? 'rotate-180' : '',
          )}
        >
          <ArrowSelect width={11} height={7} />
        </div>
      )}

      <AnimatePresence>
        {open && (
          <motion.div
            variants={animationProps}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{
              delay,
              duration,
            }}
            className={cn(
              'absolute top-full left-0 mt-2 p-4 shadow-custom rounded-lg',
              'bg-general_background_MIII_400_dark',
            )}
          >
            <div className={contentClassName}>
              {items?.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center cursor-pointer"
                  onClick={() => handleSelect(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
