import {
  HeartIcon,
  RectangleIcon,
  TriangleIcon,
  CircleIcon,
  RoundedRectangleIcon,
  LeafIcon,
  LightingIcon,
  RhombIcon,
  HexagonIcon,
  ParallelogramIcon,
} from '@app/components/ui/icons';

export const shapesSVG = {
  1: <RectangleIcon width={32} height={32} />,
  7: <TriangleIcon width={32} height={32} />,
  9: <CircleIcon width={32} height={32} />,
  10: <HexagonIcon width={32} height={32} />,
  5: <RoundedRectangleIcon width={32} height={32} />,
  2: <ParallelogramIcon width={32} height={32} />,
  4: <RhombIcon width={32} height={32} />,
  21: <HeartIcon width={32} height={32} />,
  102: <LightingIcon width={32} height={32} />,
  104: <LeafIcon width={32} height={32} />,
};

export const GEN_STYLE_TEXTS = {
  generateDesign: 'Generate design',
  back: 'Back',
};

export const USER_AMPLITUDE_DATA = {
  event: 'click generation start',
  'style status': 'unchanged',
  'color status': 'unchanged',
  'shape status': 'unchanged',
  'font status': 'unchanged',
  'logo status': 'unchanged',
  logo: 'no logo',
};
export const USER_AMPLITUDE_KEYS = {
  color: 'color status',
  shape: 'shape status',
  font: 'font status',
};
