import { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useNavigate, useOutletContext } from 'react-router';
import { useClickOutside } from '@app/hooks/use-click-outside';
import { Button, Skeleton } from '@app/components/ui';
import { useGetThemeInitQuery } from '@app/store/api/user.api';
import { useToastContext } from '@app/hooks/use-toast-provider';
import { serverPath } from '@app/utils/server-path';
import {
  useLazyDeleteLogoQuery,
  useLazyLoadPresentationQuery,
  useUpdatePresentationStyleMutation,
} from '@app/store/api/presentation.api';
import { useAppSelector } from '@app/hooks/use-store';

import t from '@lib/lng';

import { Loader } from '@app/components/ui/loader';
import { MotionWrapper } from '@app/components/ui/motion-wrapper';
import { useSlideDown, useSlideUp } from '@app/hooks/motion-animations/animations';
import { getRandomValueFromArray, isEmpty } from '@lib/utils';
import { ArrowSelect, BackArrowIcon, CreateIcon, CrossIcon } from '@app/components/ui/icons';

import { CustomSelect } from '@app/components/ui/custom-select/custom-select';

import { AnimatePresence } from 'framer-motion';

import { UploadLogoItem } from '@app/components/ui/upload-logo-item/upload-logo-item';

import {
  shapesSVG,
  GEN_STYLE_TEXTS,
  USER_AMPLITUDE_DATA,
  USER_AMPLITUDE_KEYS,
} from '../config/constants';
import UploadLogo from '../../upload-logo.block';

import GenSelect from '../../gen-select.block';
import ColorPicker from '../../gen-color-picker/gen-color-picker.block';
import { analytics } from '@app/app/analytics';
import { AmplitudeStylesPayload } from '@app/app/analytics/types';

const renderFiltersSkeletons = () => {
  return (
    <div className="flex gap-14">
      <div className="flex flex-col gap-2 basis-80">
        <Skeleton height={24} className="max-w-36" />
        <Skeleton height={56} className="max-w-80" />
      </div>
      <div className="flex flex-col gap-2 basis-80">
        <Skeleton height={24} className="max-w-36" />
        <Skeleton height={56} className="max-w-80" />
      </div>
      <div className="flex flex-col gap-2 basis-80">
        <Skeleton height={24} className="max-w-24" />
        <Skeleton height={56} className="max-w-80" />
      </div>
    </div>
  );
};

const GenStylePanelBlock = (props) => {
  const {
    theme,
    isLoadingPresentation,
    isFirstStyleSettings,
    isPPTXUpload,
    isFirstGeneratedSlide,
    themesData,
    isLoadingThemes,
    isThemesError,
    presentationOptions,
  } = props;
  const {
    context: { product_balance },
    setTrialPopup,
  } = useOutletContext();
  const { getToast } = useToastContext();
  const { refetch: refetchThemeInit } = useGetThemeInitQuery();
  const [deleteLogoMutation] = useLazyDeleteLogoQuery();
  const {
    projectId,
    fileUpload: { process },
  } = useAppSelector((state) => state.presentationState);
  const [updatePresentationStyle, { isLoading: isUpdatePresentationStyleLoading }] =
    useUpdatePresentationStyleMutation();
  const [loadPresentationQuery, { isLoading: isLoadingUpdatePresentation }] =
    useLazyLoadPresentationQuery();
  const navigate = useNavigate();

  const [showColorpicker, setShowColorpicker] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [userActionsAmplitude, updateUserActionsAmplitude] = useState(USER_AMPLITUDE_DATA);
  const [isShowUploadLogo, setShowUploadLogo] = useState(false);

  // Флаг, который отслеживает, был ли установлен шрифт
  // const [isFontSet, setIsFontSet] = useState(false);

  const [selectedThemeStyle, setSelectedThemeStyle] = useState({}); //Стейт для хранения стилей текущей выбранной темы (шрифт лого цвет и тд.)
  const lastSelectedTheme = useRef({}); //Стейт для хранения стилей последней выбранной темы (шрифт лого цвет и тд.)

  useEffect(() => {
    if (Object.keys(lastSelectedTheme.current).length === 0) {
      lastSelectedTheme.current = selectedThemeStyle;
    }
  }, [selectedThemeStyle]);

  const prevSelectedThemeStyle = useRef({});
  const colorpickerRef = useRef(null);
  const colorpickerRefContainer = useRef(null);
  const colorpickerTrigger = useRef(null);
  const colorpickerTrigger2 = useRef(null);
  const colorpickerTrigger3 = useRef(null);
  const loadingRefs = useRef({}); //храним рефы лоадеров для логотипов. Нужен для удаления логотипа и отображения Loader в момент удаления

  const currentThemeData = themesData?.result?.options?.themes.find(
    (el) => el.name.toLowerCase() === theme?.toLowerCase(),
  );
  const currentTheme = currentThemeData?.info; //Текущая выбранная тема пользователем
  const currentThemeId = currentThemeData?.id;

  const isLoadingStyleProcess =
    isLoadingUpdatePresentation || isLoadingPresentation || isUpdatePresentationStyleLoading;

  const isLockedLogoHandler = !product_balance?.is_active || product_balance?.product?.is_trial;

  const isNextButtonDisabled =
    Object.values(selectedThemeStyle).includes(null) ||
    isLoadingThemes ||
    isLoadingStyleProcess ||
    showColorpicker ||
    process ||
    !currentTheme ||
    !projectId;

  const colors = currentTheme?.colors || [];
  const userPreset = themesData?.result?.['user-preset'];
  const visibleColors = colors.slice(0, 0); //показываем только первые 4 (изменили на 0) цвета , остальное внутри colorPicker
  const overflowColors = colors.slice(0);

  const shapes = currentTheme?.shapes || [];
  const visibleShapes = shapes.slice(0, 5); // первые 5 элементов для отображения в шапке (shapes)
  const hiddenShapes = shapes.slice(5);

  const changeSelectedThemeStyle = useCallback(
    (params = {}) => {
      const [key] = Object.keys(params); // Получаем первый ключ из params, если он есть
      if (!params?.logo && key && USER_AMPLITUDE_KEYS[key]) {
        changeUserSettingsAmplitudeHandler(USER_AMPLITUDE_KEYS[key]);
      }

      setSelectedThemeStyle((prevState) => {
        const { color, shape, font, logo } = params;
        const updatedColor = color ?? prevState.color ?? currentTheme?.colors[0];
        const updatedShape = shape ?? prevState.shape ?? currentTheme?.shapes[0];
        // const updatedFont =
        //   font === t.AutomaticSelection ? currentTheme?.automatic_font : (font ?? prevState.font);
        const updatedFont =
          font === t.AutomaticSelection ? t.AutomaticSelection : (font ?? prevState.font);
        const updatedLogo = logo !== undefined ? logo : (prevState.logo ?? '');

        return {
          color: updatedColor,
          shape: updatedShape,
          font: updatedFont,
          logo: updatedLogo,
          id: currentThemeId,
        };
      });
    },
    [theme, currentThemeId, currentTheme],
  );

  useEffect(() => {
    if (currentTheme && !isEmpty(currentTheme)) {
      setShowFilters(true);
      prevSelectedThemeStyle.current = { ...selectedThemeStyle };
    }
  }, [selectedThemeStyle]);

  const tryToGetCollisionValueOnChangeStyle = (paramName, value) => {
    if (!value) return false;
    let logo;
    switch (paramName) {
      case 'shape':
        if (currentTheme?.shapes?.includes(value)) {
          return value;
        }
        return false;
      case 'color':
        if (
          currentTheme?.colors?.find((item) => item.replace('#', '') === value.replace('#', '')) ||
          userPreset?.color?.find((item) => item.value.replace('#', '') === value.replace('#', ''))
        ) {
          return value.replace('#', '');
        }
        return false;
      case 'logo':
        logo = userPreset?.logo?.find((item) => item.id === value || item.value === value);
        if (logo) {
          return logo.id;
        }
        return false;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (currentTheme && !isEmpty(currentTheme)) {
      // const newFont = isFontSet
      //   ? currentTheme.automatic_font // Если шрифт уже был установлен
      //   : presentationOptions?.font?.value || currentTheme.automatic_font;
      // const newFont = isFontSet
      //   ? prevSelectedThemeStyle.current?.font || t.AutomaticSelection
      //   : presentationOptions?.font?.value || t.AutomaticSelection;
      // if (!isFontSet && presentationOptions?.font?.value) {
      //   setIsFontSet(true); // При первом выборе шрифта из presentationOptions, устанавливаем флаг
      // }
      setSelectedThemeStyle({
        color:
          tryToGetCollisionValueOnChangeStyle('color', prevSelectedThemeStyle.current?.color) ||
          tryToGetCollisionValueOnChangeStyle('color', presentationOptions?.color_value) ||
          getRandomValueFromArray(currentTheme.colors, 3) ||
          '',
        shape:
          tryToGetCollisionValueOnChangeStyle('shape', prevSelectedThemeStyle.current?.shape) ||
          tryToGetCollisionValueOnChangeStyle('shape', presentationOptions?.shape_value) ||
          getRandomValueFromArray(currentTheme.shapes, 3) ||
          '',
        font:
          // prevSelectedThemeStyle.current?.font ||
          prevSelectedThemeStyle.current?.font ||
          presentationOptions?.font?.value ||
          t.AutomaticSelection,
        logo:
          tryToGetCollisionValueOnChangeStyle('logo', prevSelectedThemeStyle.current?.logo) ||
          tryToGetCollisionValueOnChangeStyle('logo', presentationOptions?.logo) ||
          '',
        id: currentThemeId,
      });
    }
  }, [currentTheme, presentationOptions]);

  const analyticCatchError = (error, httpCode) => {
    analytics.emitEvent('service_error_system', {
      Amplitude: {
        message: error || t.oopsSomethingBroke,
        httpCode: httpCode,
        action: 'sendOptions',
      },
    });
    analytics.emitEvent('track_error', {
      GTM: {
        error_type: 'system_error',
      },
    });

    Sentry.captureException({
      function: 'sendOptions',
      msg: error || t.oopsSomethingBroke,
      text: '',
      index: 0,
    });
  };

  const changeUserSettingsAmplitudeHandler = (typeAction, value = 'changed') => {
    updateUserActionsAmplitude((prevState) => ({
      ...(prevState ?? {}),
      [typeAction]: value,
    }));
  };

  function sendOptions() {
    const formData = new FormData();

    formData.set('color', selectedThemeStyle.color.replace('#', ''));
    formData.set('shape', selectedThemeStyle.shape);
    if (selectedThemeStyle.font === t.AutomaticSelection) {
      formData.set(
        'font',
        currentTheme?.fonts[Math.floor(Math.random() * currentTheme.fonts.length)], //если выбран automatic выбираю рандомное число из набора шрифтов темы
      );
    } else {
      formData.set('font', selectedThemeStyle.font);
    }
    formData.set('logo', selectedThemeStyle.logo ?? '');
    formData.set('theme_id', selectedThemeStyle.id);

    /** @type {AmplitudeStylesPayload} */
    const stylesPayload = {
      style: theme,
      font: selectedThemeStyle['font'],
      shape: selectedThemeStyle['shape'],
      color: selectedThemeStyle['color'],
      // TODO: Logo in not loading
      logo: selectedThemeStyle['logo'],
      'color status':
        lastSelectedTheme.current['color'] === selectedThemeStyle['color']
          ? 'unchanged'
          : 'changed',
      'font status':
        lastSelectedTheme.current['font'] === selectedThemeStyle['font'] ? 'unchanged' : 'changed',
      'style status':
        lastSelectedTheme.current['id'] === selectedThemeStyle['id'] ? 'unchanged' : 'changed',
      'shape status':
        lastSelectedTheme.current['shape'] === selectedThemeStyle['shape']
          ? 'unchanged'
          : 'changed',
      // TODO: Add logo status
      'logo status': '',
    };

    if (isFirstStyleSettings) {
      analytics.emitEvent('click_generation_start', {
        GTM: {
          style_name: theme,
        },
        Amplitude: stylesPayload,
      });
    } else {
      analytics.emitEvent('style_change_save', {
        GTM: {
          style_name: theme,
        },
        Amplitude: stylesPayload,
      });
    }

    if (isPPTXUpload) {
      analytics.emitEvent('file_generation_start', {
        GTM: {
          processed_file_id: +projectId,
        },
      });
    }

    updatePresentationStyle({ projectId, body: formData })
      .unwrap()
      .then((updatedPresentationInfo) => {
        if (!updatedPresentationInfo.status) {
          throw {
            error: updatedPresentationInfo.errors,
            httpCode: '500',
          };
        }
        loadPresentationQuery({ projectId })
          .unwrap()
          .then((presentationUpdate) => {
            if (presentationUpdate.status) {
              if (!Object.keys(presentationUpdate.result.process.option[0]).length)
                //TODO: пока не понятно, оставляю как было
                setTimeout(() => sendOptions(), 2000);
              else {
                try {
                  // TODO: пока не ясна эта логика, надо будет переделывать скорее всего. Оставил как есть. Мб это уже будет не нужно
                  if (isPPTXUpload && isFirstStyleSettings) {
                    const errorList =
                      presentationUpdate?.result?.queue[0]?.reduce((slideErrors, slide) => {
                        if (Object.keys(slide?.error).length > 0) {
                          slideErrors.push(slide?.error?.message);
                        }
                        return slideErrors;
                      }, []) ?? [];
                    const lessInfo = (count, total, prefix) => {
                      const percentOfPrefix = (count / total) * 100;
                      switch (percentOfPrefix) {
                        case percentOfPrefix < 20: {
                          return `${prefix}_status_less_20%`;
                        }
                        case percentOfPrefix > 20 && percentOfPrefix < 80: {
                          return `${prefix}_status_20_80%`;
                        }
                        case percentOfPrefix >= 80 && percentOfPrefix < 100: {
                          return `${prefix}_status_from_80%`;
                        }
                        default: {
                          return `${prefix}_status_missing`;
                        }
                      }
                    };
                    analytics.emitEvent('file_generation_done', {
                      GTM: {
                        generation_slides_number:
                          presentationUpdate?.result?.source_files?.[0]?.total_slide_count ?? 1,
                        generation_error_status: errorList.length
                          ? lessInfo(errorList.length, presentationUpdate.result.queue[0], 'error')
                          : 'error_status_missing',
                        generation_warning_status: presentationUpdate?.result.warning.length
                          ? lessInfo(
                              presentationUpdate?.result.warning.length,
                              presentationUpdate.result.queue[0],
                              'warning',
                            )
                          : 'warning_status_missing',
                        processed_file_id: +projectId,
                      },
                      Amplitude: {
                        'slides in presentation':
                          presentationUpdate?.result?.source_files?.[0]?.total_slide_count ?? 1,
                        'slide errors': errorList.length,
                        'slide error rate': errorList.length
                          ? Math.round(
                              (errorList.length / presentationUpdate.result.queue[0].length) * 100,
                            )
                          : 0,
                        'slide error names': errorList.length,
                        'slide warnings': presentationUpdate.result.warning.length,
                        'slide warning rate': presentationUpdate.result.warning.length
                          ? Math.round(
                              (presentationUpdate.result.warning.length /
                                presentationUpdate.result.queue[0].length) *
                                100,
                            )
                          : 0,
                        'slide warning names': presentationUpdate.result.warning,
                        'processed file id': +projectId,
                      },
                    });
                  }
                } catch (error) {
                  console.error('[ANALYTIC ERROR]', error);
                }
                navigate(serverPath.project + projectId);
              }
            } else {
              getToast({
                systemMessage: {
                  msg: 'CREATE PRESENTATION ERROR',
                  autoclose: 4000,
                },
              });
            }
          })
          .catch((error) => {
            console.error('[ERROR]', error);
            getToast({
              systemMessage: {
                msg: `PRESENTATION UPDATE ERROR`,
                autoclose: 4000,
              },
            });
          });
      })
      .catch((error, httpCode) => {
        console.error('UPDATE PRESENTATION STYLE ERROR]: ', error);
        analyticCatchError(error, httpCode);
        getToast({
          systemMessage: {
            msg: t.requestError,
            autoclose: 4000,
          },
        });
      });
  }

  const handleLogoDelete = async (id) => {
    if (loadingRefs.current[id]) {
      loadingRefs.current[id].style.display = 'flex';
    }

    try {
      await deleteLogoMutation({ id });
      refetchThemeInit();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      getToast({
        systemMessage: {
          msg: 'DELETE LOGO ERROR',
          autoclose: 4000,
        },
      });
    } finally {
      if (loadingRefs.current[id]) {
        loadingRefs.current[id].style.display = 'none';
      }
    }
  };

  const toggleColorpicker = () => {
    setShowColorpicker((prevState) => !prevState);
  };

  const handleClickOutsideColorPicker = () => {
    if (showColorpicker) toggleColorpicker();
  };

  const handleClickTriggerColorPicker = () => {
    if (!showColorpicker) {
      setShowColorpicker(true);
    } else {
      setShowColorpicker(false);
    }
  };

  const handleShowUploadLogo = () => {
    if (isLockedLogoHandler) {
      setTrialPopup({ type: 'logo' });
    } else {
      setShowUploadLogo(true);
    }
  };

  const handleSelectLogo = ({ logoItem: data, typeSelect = 'select' } = {}) => {
    if (!data) {
      changeSelectedThemeStyle({
        logo: '', //without logo
      });
    }
    if (data?.length && product_balance?.product?.is_trial) {
      setTrialPopup({
        type: 'logo',
      });
    } else {
      changeUserSettingsAmplitudeHandler('logo');
      if (typeSelect === 'select') {
        changeSelectedThemeStyle({
          logo: data?.props?.value?.id ?? '',
        });
      }
      if (typeSelect === 'upload') {
        changeSelectedThemeStyle({
          logo: data?.id ?? '',
        });
      }
    }
  };

  useClickOutside(
    [
      colorpickerRef,
      colorpickerRefContainer,
      colorpickerTrigger,
      colorpickerTrigger2,
      colorpickerTrigger3,
    ],
    handleClickOutsideColorPicker,
  );

  const NoLogoItem = ({ isNeedStopPropagation = true, extraClasses = ['w-[72px] h-[40px]'] }) => (
    <div
      className={cn(
        'flex items-center justify-center  rounded bg-general_background_MIII_500 text-general_text_MII_secondary_i text-center',
        selectedThemeStyle.logo === ''
          ? 'outline outline-2 outline-secondary_success_SI_600_success'
          : '',
        [...extraClasses],
      )}
      onClick={(e) => {
        if (isNeedStopPropagation) {
          e.stopPropagation();
        }
        handleSelectLogo();
      }}
    >
      <span className="max-w-9 text-[10px] leading-[11px]">without logo</span>
    </div>
  );

  // console.group('Info');
  // console.debug('themeData ->', themesData);
  // console.debug('currentTheme ->', currentTheme);
  // console.debug('selectedThemeStyle ->', selectedThemeStyle);
  // console.debug('isLoadingThemes ->', isLoadingThemes);
  // console.debug('isLoadingStyleProcess ->', isLoadingStyleProcess);
  // console.debug('showColorpicker ->', showColorpicker);
  // console.debug('process ->', process);
  // console.debug('projectId ->', projectId);
  // console.debug('presentationOption ->', presentationOptions);
  // console.debug('prevSelectedThemeStyle ->', prevSelectedThemeStyle);
  // console.debug('userPreset ->', userPreset);
  // console.groupEnd();

  const slideDownAnim = useSlideDown({ distance: 20 });

  return (
    <div className="p-2 mb-8 mx-auto container mx-auto max-w-screen-xl sticky top-0 bg-general_background_MIII_500_dark z-[99]">
      {isLoadingThemes || isLoadingPresentation ? (
        renderFiltersSkeletons()
      ) : !isThemesError ? (
        <div className="flex flex-wrap gap-14">
          <MotionWrapper isVisible={showFilters} animationProps={slideDownAnim}>
            <div className="flex flex-col relative z-[100] min-h-16">
              <div className="text-[14px] text-general_text_MII_secondary_ii pb-3">{t.Color}</div>
              {visibleColors && (
                <div className="flex grow items-center">
                  <div className="flex gap-3 h-10">
                    <div
                      className={'gen_panel__color gen_panel__color-multicoloured'}
                      ref={colorpickerTrigger2}
                      onClick={handleClickTriggerColorPicker}
                    />
                    <div
                      className={'gen_panel__color active'}
                      title={selectedThemeStyle?.color}
                      style={{ background: '#' + selectedThemeStyle?.color?.replace('#', '') }}
                      ref={colorpickerTrigger3}
                      onClick={handleClickTriggerColorPicker}
                    ></div>
                    {visibleColors.map((item, i) => (
                      <div
                        key={i}
                        className={
                          'gen_panel__color gen_panel__color-' +
                          item.toLowerCase() +
                          (selectedThemeStyle.color?.toLowerCase() === item?.toLowerCase()
                            ? ' active'
                            : '')
                        }
                        title={`color with hex ${item}`}
                        style={{
                          background: item,
                        }}
                        onClick={() => {
                          changeSelectedThemeStyle({
                            color: item,
                          });
                        }}
                      />
                    ))}
                    {currentTheme && (
                      <div
                        className="gen_panel__add_color self-center"
                        ref={colorpickerTrigger}
                        onClick={handleClickTriggerColorPicker}
                      >
                        <div
                          className={cn(
                            'p-2 flex justify-center items-center transition-all cursor-pointer text-general_text_MII_secondary_i',
                            showColorpicker ? 'rotate-180 pointer-events-none' : '',
                          )}
                        >
                          <ArrowSelect width={11} height={7} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {
                <div
                  ref={colorpickerRefContainer}
                  className="absolute"
                  style={{ top: 'calc(100% + 5px)' }}
                >
                  <MotionWrapper
                    isVisible={showColorpicker && currentTheme}
                    animationProps={{
                      initial: {
                        scale: 0,
                        opacity: 0,
                      },
                      animate: {
                        scale: 1,
                        opacity: 1,
                      },
                      exit: { scale: 0, opacity: 0 },
                    }}
                    delay={0}
                    duration={0.3}
                  >
                    <div className="absolute bg-general_background_MIII_400_dark pd-1 p-6 min-w-80 rounded-2xl sm:min-w-52 lg:min-w-80 top-1.5 shadow-dark1 max-h-[calc(100vh-265px)] overflow-y-auto scrollbar-thin scrollbar-thumb-[#222] scrollbar-track-transparent">
                      <ColorPicker
                        refetchThemeInit={refetchThemeInit}
                        selectedColor={selectedThemeStyle.color || currentTheme?.colors[0]}
                        colorpickerRef={colorpickerRef}
                        close={() => toggleColorpicker(false)}
                        baseColors={visibleColors}
                        overflowColors={overflowColors}
                        presetColors={userPreset?.color ?? []}
                        changeSelectedStyle={changeSelectedThemeStyle}
                        selectedThemeStyle={selectedThemeStyle}
                        selectOption={(type, value) =>
                          changeSelectedThemeStyle({
                            [type]: value,
                          })
                        }
                      />
                    </div>
                  </MotionWrapper>
                </div>
              }
            </div>
          </MotionWrapper>
          <MotionWrapper
            isVisible={showFilters && currentTheme?.shapes?.length > 1}
            animationProps={slideDownAnim}
            duration={0.2}
          >
            <div className={`${selectedThemeStyle.color ? '' : 'hidden'}`}>
              <div className="flex items-center">
                <div className="text-[14px] text-general_text_MII_secondary_ii pb-3">Shape</div>
              </div>
              <CustomSelect
                headerSlot={
                  <div className="flex gap-3">
                    {visibleShapes.map((shape, i) => (
                      <div
                        key={i}
                        className={cn(
                          'text-general_text_MII_secondary_i',
                          selectedThemeStyle.shape === shape
                            ? 'text-secondary_success_SI_500_success'
                            : '',
                        )}
                        onClick={(e) => {
                          e.stopPropagation();
                          changeSelectedThemeStyle({ shape });
                        }}
                      >
                        {shapesSVG[shape]}
                      </div>
                    ))}
                  </div>
                }
                items={hiddenShapes.map((shape, i) => (
                  <div
                    key={i}
                    className={cn(
                      'text-general_text_MII_secondary_i',
                      selectedThemeStyle.shape === shape
                        ? 'text-secondary_success_SI_500_success'
                        : '',
                    )}
                    onClick={() => changeSelectedThemeStyle({ shape })}
                  >
                    {shapesSVG[shape]}
                  </div>
                ))}
                contentClassName="grid grid-cols-4 gap-2 max-w-max"
                isHiddenToggle={hiddenShapes.length <= 0}
              />
            </div>
          </MotionWrapper>

          {/* {Не выбрана тема - показываем подксказку} */}
          {/* {!currentTheme && (
            <div className="gen_bubble_wrap w-full">
              <div className="gen_bubble">{t.selectStyle}</div>
            </div>
          )} */}

          {/* Тема выбрана, но не выбран цвет или не выбрана форма -> показываем подсказку */}
          {showFilters && (!selectedThemeStyle.color || !selectedThemeStyle.shape) && (
            <div className="gen_bubble_wrap">
              <div className="gen_bubble">
                {selectedThemeStyle.color
                  ? t.formatString(t.chooseShapeMsg, <br />)
                  : t.ChooseYourBrandColor}
              </div>
            </div>
          )}
          <MotionWrapper
            isVisible={showFilters && !!selectedThemeStyle.color && !!selectedThemeStyle.shape}
            animationProps={slideDownAnim}
            duration={0.2}
          >
            <div className="min-w-72">
              <div className="text-[14px] text-general_text_MII_secondary_ii pb-3">{t.Font}</div>
              <GenSelect
                name="font"
                options={
                  currentTheme?.fonts && themesData?.result?.options?.font
                    ? [
                        {
                          name: t.AutomaticSelection,
                          value: t.AutomaticSelection,
                        },
                        ...themesData.result.options.font,
                      ]
                    : []
                }
                activeItem={selectedThemeStyle.font}
                onSelect={(fontValue) =>
                  changeSelectedThemeStyle({
                    font: fontValue,
                  })
                }
                preset={userPreset && userPreset.font ? userPreset.font : null}
              />
            </div>
          </MotionWrapper>
          <MotionWrapper
            isVisible={showFilters && !!selectedThemeStyle.color && !!selectedThemeStyle.shape}
            animationProps={slideDownAnim}
            duration={0.1}
          >
            <div className="min-w-80">
              <div className="text-[14px] text-general_text_MII_secondary_ii pb-3">{t.Logo}</div>
              <CustomSelect
                onSelect={(value) => handleSelectLogo({ logoItem: value })}
                headerSlot={
                  <div className="flex gap-3">
                    {selectedThemeStyle?.logo ? (
                      <div
                        className={cn(
                          'w-[72px] h-[40px] flex items-center justify-center relative group rounded outline outline-2 outline-secondary_success_SI_600_success',
                        )}
                      >
                        <img
                          src={
                            userPreset?.logo.find((logo) => logo.id === selectedThemeStyle?.logo)
                              ?.value
                          }
                          alt={`logo for `}
                          className="object-contain w-full h-full block rounded group-hover:outline group-hover:outline-2 group-hover:outline-secondary_success_SI_600_success"
                          loading="lazy"
                        />
                      </div>
                    ) : (
                      <NoLogoItem isNeedStopPropagation={false} />
                    )}
                    <UploadLogoItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShowUploadLogo();
                      }}
                    />
                  </div>
                }
                items={[
                  ...(userPreset?.logo
                    ? [
                        <div key={'noLogo'} value={{ id: '', value: '' }} className="h-full w-full">
                          <NoLogoItem isNeedStopPropagation={false} extraClasses={['h-full']} />
                        </div>,
                        ...userPreset.logo.map((item) => (
                          <div
                            key={item.id}
                            className={cn(
                              'w-[72px] h-[40px] flex items-center justify-center relative group rounded',
                              selectedThemeStyle.logo === item.id
                                ? 'outline outline-2 outline-secondary_success_SI_600_success'
                                : '',
                            )}
                            value={{ id: item.id, value: item.value }}
                          >
                            <img
                              src={item.value}
                              alt={`logo for ${item.value}`}
                              className="object-contain w-full h-full block rounded group-hover:outline group-hover:outline-2 group-hover:outline-secondary_success_SI_600_success"
                              loading="lazy"
                            />
                            <div
                              ref={(el) => {
                                if (!loadingRefs.current[item.id]) {
                                  loadingRefs.current[item.id] = el;
                                }
                              }}
                              className="absolute inset-0 flex items-center justify-center bg-opacity-50 bg-black rounded pointer-events-none"
                              style={{ display: 'none' }}
                            >
                              <Loader />
                            </div>
                            <div
                              className="absolute top-[-10px] p-1 right-[-8px] rounded-full text-general_background_MIII_600_dark opacity-0 group-hover:opacity-100 cursor-pointer transition-opacity bg-secondary_success_SI_600_success"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleLogoDelete(item.id);
                              }}
                            >
                              <CrossIcon width={10} height={10} />
                            </div>
                          </div>
                        )),
                      ]
                    : []),
                ]}
                contentClassName="grid grid-cols-4 gap-2 max-w-max"
                isHiddenToggle={!Array.isArray(userPreset?.logo) || userPreset.logo.length === 0}
              />
            </div>
          </MotionWrapper>
          <AnimatePresence>
            {isShowUploadLogo && (
              <UploadLogo
                onError={(data) => {
                  getToast({
                    systemMessage: {
                      msg: data,
                      autoclose: 4000,
                    },
                  });
                }}
                onCloseUpload={(value) => {
                  setShowUploadLogo(false);
                  refetchThemeInit()
                    .then((res) => {
                      const logoItem = res?.data?.result?.['user-preset']?.logo.find((item) =>
                        item.value.includes(value),
                      );
                      if (logoItem) {
                        handleSelectLogo({ logoItem, typeSelect: 'upload' });
                      }
                    })
                    .catch((e) => {
                      console.error(e);
                    });
                }}
                close={() => setShowUploadLogo(false)}
              />
            )}
          </AnimatePresence>
        </div>
      ) : (
        ''
      )}
      <div
        className="container mx-auto max-w-screen-xl fixed bottom-0 right-0 left-0 bg-general_background_MIII_500_dark flex pt-1 pb-3 justify-center flex-wrap outline  outline-general_background_MIII_500_dark outline-8 gap-2"
        style={{}}
      >
        <MotionWrapper isVisible={true} animationProps={useSlideUp()}>
          <Button
            variant="noBorder"
            label={GEN_STYLE_TEXTS.back}
            onClick={() => navigate(-1)}
            leftIconSlot={<BackArrowIcon width={13} height={13} />}
            extraClasses={[
              'ui_button--isBoxSizingBorderBox',
              'ui_button--isHeight40',
              'text-general_text_MII_secondary_i gap-3',
            ]}
          />
        </MotionWrapper>
        <MotionWrapper isVisible={true} animationProps={useSlideUp()}>
          <Button
            label={
              process ? (
                <span>{t.waitForUploading}</span>
              ) : (
                <span>{GEN_STYLE_TEXTS.generateDesign}</span>
              )
            }
            leftIconSlot={
              isUpdatePresentationStyleLoading ? <Loader /> : <CreateIcon width={18} height={18} />
            }
            isDisabled={isNextButtonDisabled}
            onClick={() => !isUpdatePresentationStyleLoading && sendOptions()}
            isFullWidth={true}
            extraClasses={[
              'ui_button--isBoxSizingBorderBox',
              'ui_button--isHeight40',
              'rounded-md min-w-[296px]',
            ]}
          />
        </MotionWrapper>
      </div>
    </div>
  );
};

export default GenStylePanelBlock;
