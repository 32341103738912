import React, { useState } from 'react';

const RenameModal = ({close, renameFile, fileName, projectId, names, needRequest}) => {
	const [value, setValue] = useState(fileName.replace(/.pptx$|.json$/, ''));
	const [nameExists, setNameExists] = useState(false);
	const [saveAvail, setSaveAvail] = useState(false);

	const onChangeHandler = e => {
		let val = e.target.value;
		if (val.toLowerCase() !== fileName.toLowerCase().replace(/.pptx$|.json$/, '') && names.includes(val.toLowerCase())) setNameExists(true);
		else setNameExists(false);
		if (val.toLowerCase() === fileName.toLowerCase().replace(/.pptx$|.json$/, '') || !val.trim()) setSaveAvail(false);
		else setSaveAvail(true);
		setValue(val);
	}

	return(
		<div className="modal">
			<div className="modal_overlay" onClick={close}></div>
			<div className="files_modal files_modal-rename">
				<div className="files_modal_inner">
					<div className="modal_close" onClick={close}>{svgIcons.close}</div>
					<div className="files_modal_title">{svgIcons.rename} Rename the file?</div>
					<div className="files_modal_text">Enter a new name</div>
					<input className={'files_modal_input' + (nameExists ? ' error' : '')} value={value} onChange={onChangeHandler} onKeyDown={e => {(e.code === 'Enter' && !nameExists && saveAvail) && renameFile(projectId, value)}} autoFocus={true} />
					{
						needRequest && !nameExists &&
						<div className="files_modal_tip">Request a PIN from the presentation author</div>
					}
					{
						nameExists &&
						<div className="files_modal_tip error">File with this name already exists</div>
					}
					<div className={'files_modal_btn gen_btn_main' + (nameExists || !saveAvail ? ' disabled' : '')} onClick={() => {renameFile(projectId, value)}}>Yes, rename</div>
					<div className="files_modal_cancel" onClick={close}>Cancel and return</div>
				</div>
			</div>
		</div>
	);
}

export default RenameModal;

const svgIcons = {
	rename: <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M19.045 7.401C19.423 7.023 19.631 6.521 19.631 5.987C19.631 5.453 19.423 4.951 19.045 4.573L17.459 2.987C17.081 2.609 16.579 2.401 16.045 2.401C15.511 2.401 15.009 2.609 14.632 2.986L4 13.585V18H8.413L19.045 7.401ZM16.045 4.401L17.632 5.986L16.042 7.57L14.456 5.985L16.045 4.401ZM6 16V14.415L13.04 7.397L14.626 8.983L7.587 16H6ZM4 20H20V22H4V20Z"/></svg>,
	close: <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor"><path d="M10.192 0.343994L5.94897 4.58599L1.70697 0.343994L0.292969 1.75799L4.53497 5.99999L0.292969 10.242L1.70697 11.656L5.94897 7.41399L10.192 11.656L11.606 10.242L7.36397 5.99999L11.606 1.75799L10.192 0.343994Z"/></svg>,
};