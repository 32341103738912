import React, { useContext, useState } from 'react';
import { useOutletContext } from 'react-router';

import { SlideEditContent } from '../project-id.page';

import Slide from './slide.block';

const SlideListBlock = (props) => {
  const { slidesRefs } = props;
  const { presentation } = useContext(SlideEditContent);
  const { onboardingMessage } = useOutletContext();
  const [lastOperation, setLastOperation] = useState('create');
  if (!presentation) return <></>;

  const isFirstGeneration = !Object.values(presentation.queue_ready).some((obj) => obj.ready);

  return (
    <div className="create_slides_wrap">
      {Object.entries(presentation?.slides_info ?? {}).map(([slideIndex, slideInfo], index) => {
        return (
          <Slide
            key={slideIndex}
            shapes={{
              imageInfo: presentation?.images_info?.slide[slideIndex],
              shapesInfo: presentation?.shapes_info?.slide[slideIndex],
            }}
            slideIndex={+slideIndex}
            projectId={presentation.process.project_id}
            themeOption={presentation.process?.option[0]}
            onboardingMessage={onboardingMessage}
            arrayIdx={index}
            draftInfo={presentation?.draft_info?.[slideIndex]}
            genListInfo={Object.values(presentation.queue?.[slideIndex])}
            slideQuery={presentation.queue_ready[slideIndex]}
            slideInfo={slideInfo}
            isFirstGeneration={isFirstGeneration}
            isNewSlide={
              !presentation.queue_ready ||
              !presentation.queue_ready[slideIndex] ||
              !presentation.queue_ready[slideIndex].ready
            }
            slideContent={presentation?.draft_info?.[slideIndex] ?? { source_text: '' }} // from draft redux
            noDelete={
              Object.keys(presentation.slides_info).length === 1 && Number(slideIndex) === 0
            }
            noClone={
              !presentation.queue ||
              !presentation.queue[slideIndex] ||
              presentation.slides_info[slideIndex].slide_type !== 'draft'
            }
            setLastOperation={setLastOperation}
            lastOperation={lastOperation}
            addRef={(slideIdx, n) => {
              slidesRefs[slideIdx] = n;
            }}
          />
        );
      })}
    </div>
  );
};

export default SlideListBlock;
