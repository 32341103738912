import React, { useEffect, useState } from 'react';

import { useToastContext } from '@app/hooks/use-toast-provider';
import { analytics } from '@app/app/analytics';

import SlideResultItemBlock from './slide-result-item.block';
import SlideLoader from './slide-loader.block';
import SlideDraftMode from './slide-draft-mode.block';
import SlideError from './slide-error.block';

const SlideBlock = (props) => {
  const {
    isNoneGeneratedSlides,
    slideInfo,
    genListInfo,
    slideQuery,
    slideIndex,
    addRef,
    draftInfo,
    projectId,
    isNewSlide,
    themeOption,
    noClone,
    noDelete,
    shapes,
    setLastOperation,
    lastOperation,
  } = props;
  const { getToast } = useToastContext();
  const [slideIsLoading, setSlideLoading] = useState(true);
  const [errors, setErrorList] = useState(null);
  const [isEditMode, toggleEditMode] = useState(false);

  useEffect(() => {
    setSlideLoading(!slideInfo.empty && !slideQuery.ready);
    const errorsList = [];
    let catchErrorState = null;
    for (const messagesList of genListInfo) {
      if (messagesList?.error?.message) {
        errorsList.push(messagesList?.error?.message);
        catchErrorState = messagesList.error;
        getToast({
          systemMessage: {
            msg: 'slide ' + (Number(slideIndex) + 1) + ' : ' + messagesList?.error?.message,
            autoclose: 4000,
          },
        });
      }
    }

    const infoWithErrorList = genListInfo.reduce(
      (acc, info) => (Object.keys(info.error).length > 0 ? [...acc, info] : acc),
      [],
    );
    if (infoWithErrorList.length > 0) {
      analytics.emitEvent('track_error', {
        GTM: {
          error_type: 'preview_error',
        },
      });

      infoWithErrorList.forEach(({ debug, error }) => {
        analytics.emitEvent('service_error_preview', {
          Amplitude: {
            message: error.message,
            action: lastOperation,
            class: error.class,
            inner_message: error.inner_message,
            trace: debug.trace,
          },
        });
      });
    }
  }, [slideQuery.ready, slideInfo.empty]);

  if (slideIsLoading) return <SlideLoader slideIndex={slideIndex} />;
  if (!slideQuery?.status && errors)
    return (
      <SlideError
        slideIndex={slideIndex}
        errors={errors}
        projectId={projectId}
        noDelete={noDelete}
      />
    );

  if ((slideInfo.empty || isEditMode) && (draftInfo || isNewSlide)) {
    return (
      <SlideDraftMode
        addRef={addRef}
        isNoneGeneratedSlides={isNoneGeneratedSlides}
        isNewSlide={isNewSlide}
        slideIndex={slideIndex}
        projectId={projectId}
        themeOption={themeOption}
        draftInfo={draftInfo}
        toggleEditMode={toggleEditMode}
        noClone={noClone}
        noDelete={noDelete}
        setLastOperation={setLastOperation}
      />
    );
  }
  if (!slideInfo.empty) {
    return (
      <SlideResultItemBlock
        slideIsLoading={slideIsLoading}
        slideIndex={slideIndex}
        themeOption={themeOption}
        addRef={addRef}
        arrayIdx={slideIndex}
        shapes={shapes}
        isEditable={!!draftInfo}
        projectId={projectId}
        onToggleEditMode={toggleEditMode}
        noClone={noClone}
        noDelete={noDelete}
        setLastOperation={setLastOperation}
      />
    );
  }
  return <SlideLoader slideIndex={slideIndex} />;
};

export default SlideBlock;
