import React, { useRef, useState } from 'react';
import { useOutletContext } from 'react-router';
import cl from 'classnames';

import { useNavigate, useParams } from 'react-router-dom';

import { useAppSelector } from '@app/hooks/use-store';
import { route_path } from '@app/utils/route_path';
// import {
//   useLazyDownloadPresentationPingQuery,
//   useLazyDownloadPresentationQuery,
//   useLazyLoadPresentationQuery,
// } from '../../../store/api/presentation.api';
// import { setGlobalRequest } from '../../../store/slice/common.slice';
import { useToastContext } from '@app/hooks/use-toast-provider';
import { assetThemes } from '@app/data';

// import { useRemakeSlideDesignMutation } from '../../../store/api/slide.api';
import { usePresentationDownload } from '@app/hooks/use-presentation-download';

import { useGetThemeInitQuery } from '@app/store/api/user.api';

import { useAnimatedSymbol } from '@app/hooks/use-animated-ellipsis';

import { Icon } from '../../../components/ui';
import t from '../../../../lib/lng';
import { OnboardingMessage } from '../../../components/template';
import { ONBOARDING_MESSAGE_FINISH, ONBOARDING_TYPE } from '../../create/data/onboarding.data';

const PANEL_PROJECT_LNG = {
  title: 'Presentation style',
  select_logo: 'Select logo',
  no_logo: 'Without logo',
  change_settings: 'Change  settings',
  gen_info:
    'Artificial intelligence designed your presentation and arranged the\n' +
    '                    elements in order. Select another layout if you don’t like the design.',
  download_info: 'Download your presentation to use it with PowerPoint and Google Slides',
  create_one_slide: 'Create at least one slide to download or&nbsp;share',
  download: 'Download',
};

// const DOWNLOAD_PROBLEMS_MESSAGES = [
//   {
//     msg: 'Please, wait a little longer',
//     msg4: 'The generation is taking slightly longer than usual, but it will be ready soon',
//     type: 'warning',
//     autoclose: 10000,
//   },
//   {
//     msg: 'Check your email!',
//     msg4: "To save your time, we'll email you the completed presentation after generation done",
//     type: 'warning',
//     autoclose: 10000,
//   },
// ];
const ProjectPanelBlock = (props) => {
  const {
    isEditPointOpen,
    presentationOption,
    projectId,
    // queueLength,
    // presentationName,
    onShowShareModal,
    isNotShareAvailable,
    presentation,
  } = props;
  const genPanelRef = useRef(null);
  // const dispatch = useAppDispatch();
  const { isGlobalRequest } = useAppSelector((state) => state.commonState);
  const { onboardingMessage, context, setTrialPopup, setResendNotify } = useOutletContext();
  const { getToast } = useToastContext();
  const navigate = useNavigate();
  const params = useParams();
  // const [isShowCongratulations, setShowCongratulations] = useState(false);
  const [isDownloadDisabled, setDownloadDisabled] = useState(false);
  // const [ reloadPresentationQuery ] = useLazyLoadPresentationQuery();
  // const [ remakeSlideDesignMutation ] = useRemakeSlideDesignMutation();
  // const [ downloadPresentationPing ] = useLazyDownloadPresentationPingQuery();
  // const [ downloadPresentationQuery ] = useLazyDownloadPresentationQuery();

  const [currentOnboardingStep, changeCurrentOnboardingStep] = onboardingMessage(ONBOARDING_TYPE);
  const { font, logo } = presentationOption;

  const { data: themesData } = useGetThemeInitQuery();

  const themeStyle = themesData?.result?.options?.themes?.find(
    (theme) => theme.id === presentation?.process?.option[0]?.theme_id,
  )?.name;

  const loadingAnimText = useAnimatedSymbol('Loading', '.', 500);

  const allPresetsByOne = false;

  const { downloadHandler: downloadHook } = usePresentationDownload({
    projectId: projectId,
    getToast,
    setTrialPopup,
    setResendNotify,
    context,
    setDownloadDisabled,
  });

  function downloadHandler(retry = 0, ignore_code = false) {
    downloadHook({ presentation, ignore_code, retry, interactionLocation: 'file_page' });
  }

  function showStyleChange() {
    navigate(`${route_path.setupStyle}${params.projectId}`);
  }
  function finishOnboarding() {
    // console.log('finishOnboarding');
  }
  // function showFont() {
  //   console.log('showFont');
  // }
  if (!presentationOption || !context || !presentation) return <></>;
  return (
    <div
      ref={genPanelRef}
      className={cl('gen_panel a_shake_bubble relative min-w-[385px]')}
      style={currentOnboardingStep() > 2 ? { zIndex: 20 } : { zIndex: 0 }}
    >
      {isEditPointOpen ? (
        <div className="absolute backdrop-blur-sm bg-[#202020]/20 left-[-10px] top-[-10px] w-full h-[calc(100vh+75px)] z-50" />
      ) : (
        <></>
      )}
      <div className="gen_panel__maintitle">{PANEL_PROJECT_LNG.title}</div>
      <div className={'gen_panel__current' + (allPresetsByOne ? ' nochange' : '')}>
        <div className="gen_panel__current_items">
          <div className="gen_panel__current_row">
            <div className="gen_panel__current_item">{t.ShapeAndColor}</div>
            <div
              className="gen_panel__current_value about_plain_link"
              onClick={showStyleChange}
              style={{
                color: Array.isArray(presentation.process.option[0].color_value)
                  ? `rgb(${presentation.process.option[0].color_value.join(',')})`
                  : `#${presentation.process.option[0].color_value}`,
              }}
            >
              {shapesSVG[presentation.process.option[0].shape_value]}
            </div>
          </div>
          {context?.TAG && (
            <div className="gen_panel__current_row">
              <div className="gen_panel__current_item">{t.Style}</div>
              <div
                className="gen_panel__current_value about_plain_link"
                onClick={() => {
                  if (themeStyle) {
                    showStyleChange();
                  }
                }}
              >
                {themeStyle ?? loadingAnimText}
              </div>
            </div>
          )}
          <div className="gen_panel__current_row">
            <div className="gen_panel__current_item">{t.Font}</div>
            <div className="gen_panel__current_value about_plain_link" onClick={showStyleChange}>
              {font?.value}
            </div>
          </div>
          <div className="gen_panel__current_row">
            <div className="gen_panel__current_item">{t.Logo}</div>
            <div
              className="gen_panel__current_value about_plain_link"
              onClick={logo ? showStyleChange : undefined}
            >
              {logo && (
                <img
                  className="gen_panel__current_logo"
                  src={logo.match(/^\//) || logo.match('https://') ? logo : '/' + logo}
                />
              )}
              {!logo && <div>{PANEL_PROJECT_LNG.no_logo}</div>}
            </div>
          </div>
        </div>
        {!allPresetsByOne && (
          <div className="gen_panel__makeagain" onClick={showStyleChange}>
            {PANEL_PROJECT_LNG.change_settings} {svgIcons.arrowRight}
          </div>
        )}
        <div className="gen_panel__info">{PANEL_PROJECT_LNG.gen_info}</div>
        {!context?.product_balance &&
          presentation.queue &&
          Object.keys(presentation.queue).length > 3 && (
            <div className="gen_panel__upgrade_msg">{t.toSeePresentationUpgrade}</div>
          )}
      </div>
      <div className="gen_panel__footer z-[1]">
        <>
          <OnboardingMessage
            boardId={11}
            isActivate={currentOnboardingStep()}
            forceShow={!isGlobalRequest}
            zIndex={20}
            // isShowCongratulationForce={
            //   localStorage.getItem(`${ONBOARDING_TYPE}_process`) !== 'finish' &&
            //   // isShowCongratulations
            // }
            onFinish={finishOnboarding}
            onClose={() => {
              localStorage.setItem(`${ONBOARDING_TYPE}_process`, 'finish');
              changeCurrentOnboardingStep({ step: 12, freeze: 12 });
            }}
            isFullWidthChild={true}
            isFinishDisabled={isGlobalRequest || !presentation.download}
            {...ONBOARDING_MESSAGE_FINISH}
          >
            <div
              className={cl(
                'gen_panel_download',
                isGlobalRequest || !presentation.download || isDownloadDisabled
                  ? ' gen_btn_second grayscale animate-pulse disabled'
                  : ' gen_btn_main',
              )}
              id={isGlobalRequest || !presentation.download ? '' : 'download_btn'}
              onClick={() => downloadHandler(0)}
            >
              {isDownloadDisabled ? (
                <div className="flex items-center justify-center gap-[15px]">
                  <Icon className="text-wf" name="LoadingIcon" size={20} />
                  <span>{t.preparingForDownload}</span>
                </div>
              ) : (
                <>
                  {svgIcons.download}
                  <span>{PANEL_PROJECT_LNG.download}</span>
                </>
              )}
            </div>
          </OnboardingMessage>
          <div
            className={
              'gen_panel__share btn-outlined' +
              (isGlobalRequest || isNotShareAvailable ? ' disabled' : '')
            }
            onClick={() => onShowShareModal()}
          >
            {svgIcons.share}
            {t.ShareAllSlides}
          </div>
          <div className="gen_panel__download_info">
            {presentation.download ? (
              <span className="text-general_text_MII_disabled  whitespace-pre-wrap">
                {PANEL_PROJECT_LNG.download_info}
              </span>
            ) : (
              <span className="text-general_text_MII_disabled whitespace-pre-wrap">
                {PANEL_PROJECT_LNG.create_one_slide}
              </span>
            )}
          </div>
        </>
      </div>
    </div>
  );
};

const svgIcons = {
  arrowRight: (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M5.293 12.293L6.707 13.707L13.414 6.99997L6.707 0.292969L5.293 1.70697L9.586 5.99997H0V7.99997H9.586L5.293 12.293Z"
        fill="#00BA88"
      />
    </svg>
  ),
  download: (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="currentColor">
      <path d="M19.448 11.112C19.011 7.67 16.063 5 12.504 5C9.74803 5 7.35403 6.611 6.26103 9.15C4.11303 9.792 2.50403 11.82 2.50403 14C2.50403 16.757 4.74703 19 7.50403 19H8.50403V17H7.50403C5.85003 17 4.50403 15.654 4.50403 14C4.50403 12.596 5.70303 11.243 7.17703 10.984L7.75803 10.882L7.95003 10.324C8.65303 8.273 10.398 7 12.504 7C15.261 7 17.504 9.243 17.504 12V13H18.504C19.607 13 20.504 13.897 20.504 15C20.504 16.103 19.607 17 18.504 17H16.504V19H18.504C20.71 19 22.504 17.206 22.504 15C22.5028 14.1035 22.2011 13.2333 21.6471 12.5285C21.0932 11.8237 20.3189 11.3249 19.448 11.112Z" />
      <path d="M13.504 14V10H11.504V14H8.50403L12.504 19L16.504 14H13.504Z" />
    </svg>
  ),
  preparingDoanload: (
    <svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M1.41992 10C1.41992 14.6946 5.22537 18.5 9.91992 18.5C14.6145 18.5 18.4199 14.6946 18.4199 10C18.4199 5.30545 14.6145 1.5 9.91992 1.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  share: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
      <path d="M9.07003 11.7738C8.23943 11.7688 7.44304 11.4387 6.848 10.8529C5.66398 9.71059 4.56884 7.67946 4.79104 5.71893C4.84541 5.04055 5.08497 4.39074 5.48304 3.84183C5.89566 3.27158 6.43552 2.80775 7.05877 2.488C7.68203 2.16826 8.3711 2.00162 9.07003 2.00162C9.76896 2.00162 10.458 2.16826 11.0813 2.488C11.7045 2.80775 12.2444 3.27158 12.657 3.84183C13.0581 4.39087 13.2999 5.04197 13.3554 5.72214V5.73819C13.5395 7.72439 12.4539 9.73946 11.2952 10.8721C10.696 11.4511 9.89895 11.7741 9.07003 11.7738ZM9.07003 3.60438C8.61816 3.60467 8.1728 3.71331 7.77053 3.92136C7.36825 4.12942 7.02054 4.43097 6.75595 4.80124C6.52481 5.12088 6.3883 5.50035 6.36233 5.89541C6.21314 7.23987 7.04164 8.82177 7.94949 9.69133C8.0972 9.84317 8.27334 9.96375 8.46763 10.0461C8.66192 10.1284 8.87048 10.1708 9.08114 10.1708C9.29181 10.1708 9.50036 10.1284 9.69465 10.0461C9.88894 9.96375 10.0651 9.84317 10.2128 9.69133C11.254 8.687 11.9015 7.12435 11.8 5.889C11.7755 5.49511 11.64 5.11653 11.4095 4.79803C11.1386 4.42617 10.7846 4.12424 10.3764 3.91681C9.96825 3.70939 9.51737 3.60234 9.06051 3.60438H9.07003Z" />
      <path d="M13.1998 21.984H4.94658C4.5603 21.9848 4.17765 21.9087 3.82049 21.7599C3.46334 21.6112 3.13869 21.3927 2.86511 21.1171C2.59153 20.8414 2.37438 20.514 2.22607 20.1534C2.07777 19.7929 2.00122 19.4064 2.00081 19.0159V18.3741C1.98586 17.5279 2.17904 16.6912 2.56299 15.939C2.94694 15.1869 3.50966 14.5429 4.20061 14.0648C5.03196 13.5124 6.00549 13.219 7.00037 13.2209H11.127C11.7958 13.2132 12.4593 13.3425 13.0773 13.6009C13.6954 13.8593 14.2553 14.2415 14.7234 14.7245C15.1914 15.2075 15.5579 15.7813 15.8007 16.4113C16.0435 17.0413 16.1575 17.7144 16.1361 18.3902V19.0319C16.1311 19.8161 15.8196 20.5664 15.2696 21.1194C14.7195 21.6724 13.9756 21.9831 13.1998 21.984ZM7.00037 14.8253C6.31458 14.8231 5.64333 15.0251 5.07038 15.4061C4.60085 15.7431 4.22021 16.1914 3.96177 16.7116C3.70334 17.2319 3.57499 17.8082 3.58797 18.3902V19.0319C3.58881 19.3933 3.73121 19.7397 3.98403 19.9953C4.23686 20.2508 4.57951 20.3948 4.93706 20.3956H13.1903C13.5481 20.3956 13.8913 20.252 14.1443 19.9962C14.3973 19.7405 14.5394 19.3936 14.5394 19.0319V18.3902C14.5524 17.8082 14.424 17.2319 14.1656 16.7116C13.9072 16.1914 13.5265 15.7431 13.057 15.4061C12.484 15.0251 11.8128 14.8231 11.127 14.8253H7.00037Z" />
      <path d="M19.7866 14.0648C18.9552 13.5124 17.9817 13.219 16.9868 13.2209H14.9521C15.5295 13.6615 16.0223 14.2052 16.4059 14.8253H16.9868C17.4465 14.8186 17.9027 14.9072 18.3272 15.0855C18.7518 15.2638 19.1357 15.5281 19.4554 15.8621C19.7751 16.196 20.0237 16.5926 20.1861 17.0274C20.3484 17.4622 20.4209 17.926 20.3992 18.3902V19.0319C20.3984 19.3933 20.256 19.7397 20.0031 19.9953C19.7503 20.2508 19.4077 20.3948 19.0501 20.3956H17.1804C16.9793 20.9978 16.6461 21.5462 16.2059 22H19.0628C19.8413 21.9992 20.5877 21.6862 21.1381 21.1297C21.6886 20.5733 21.9982 19.8189 21.9991 19.0319V18.3902C22.0153 17.5401 21.8214 16.6993 21.4351 15.9441C21.0487 15.1888 20.4821 14.5429 19.7866 14.0648Z" />
      <path d="M19.2088 5.73498V5.71893C19.1533 5.03876 18.9115 4.38766 18.5105 3.83862C18.0937 3.26667 17.5494 2.80211 16.9218 2.48266C16.2941 2.1632 15.6008 1.99785 14.8981 2.00002C14.2758 2.00322 13.6612 2.13993 13.0951 2.40111C13.3032 2.60729 13.4953 2.82934 13.6696 3.06532C13.8267 3.27663 13.9658 3.50097 14.0855 3.73594C14.3492 3.65041 14.6243 3.60605 14.9013 3.60438C15.3546 3.60649 15.8012 3.71583 16.2052 3.92366C16.6092 4.13149 16.9595 4.43203 17.2281 4.80124C17.4626 5.11957 17.6014 5.49943 17.628 5.89541C17.7391 7.13077 17.0947 8.69342 16.0409 9.69775C15.8931 9.84958 15.717 9.97017 15.5227 10.0525C15.3284 10.1348 15.1199 10.1772 14.9092 10.1772C14.6985 10.1772 14.49 10.1348 14.2957 10.0525C14.1014 9.97017 13.9253 9.84958 13.7776 9.69775L13.7363 9.65604C13.4841 10.1343 13.1899 10.5885 12.857 11.0133C13.461 11.5316 14.2349 11.8029 15.0267 11.7738C15.8184 11.7447 16.5708 11.4174 17.136 10.8561C18.3073 9.74588 19.3929 7.72118 19.2088 5.73498Z" />
    </svg>
  ),
};

const shapesSVG = {
  /*rectangle*/ 1: (
    <svg className="svg_shape" width="24" height="24" viewBox="0 0 24 24">
      <mask id="path-1-inside-1_4711_6971" fill="white">
        {' '}
        <rect width="24" height="24" rx="1" />{' '}
      </mask>{' '}
      <rect width="24" height="24" rx="1" strokeWidth="4" mask="url(#path-1-inside-1_4711_6971)" />{' '}
    </svg>
  ),
  /*triangle*/ 7: (
    <svg className="svg_shape" width="24" height="23" viewBox="0 0 24 23">
      <path d="M1.61803 22L12 1.23607L22.382 22H1.61803Z" strokeWidth="2" />{' '}
    </svg>
  ),
  /*circle*/ 9: (
    <svg className="svg_shape" width="24" height="24" viewBox="0 0 24 24">
      <rect x="1" y="1" width="22" height="22" rx="11" strokeWidth="2" />{' '}
    </svg>
  ),
  /*hexagon*/ 10: (
    <svg className="svg_shape" width="21" height="24" viewBox="0 0 21 24">
      <path
        d="M10.5 1.15175L20 6.58032V17.4197L10.5 22.8482L1 17.4197L1 6.58032L10.5 1.15175L10.0039 0.283508L10.5 1.15175Z"
        strokeWidth="2"
      />{' '}
    </svg>
  ),
  /*rounded_rectangle*/ 5: (
    <svg className="svg_shape" width="24" height="24" viewBox="0 0 24 24">
      <rect x="1" y="1" width="22" height="22" rx="7" strokeWidth="2" />{' '}
    </svg>
  ),
  /*parallelogram*/ 2: (
    <svg className="svg_shape" width="27" height="24" viewBox="0 0 27 24">
      <path d="M13.6694 1L25.3164 0.999999L13.3306 23H1.68359L13.6694 1Z" strokeWidth="2" />{' '}
    </svg>
  ),
  /*rhombus*/ 4: (
    <svg className="svg_shape" width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M1.41421 12L12 1.41421L11.2929 0.707107L12 1.41421L22.5858 12L12 22.5858L1.41421 12L0.707107 12.7071L1.41421 12Z"
        strokeWidth="2"
      />{' '}
    </svg>
  ),
  /*heart*/ 21: (
    <svg className="svg_shape" width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M11.0722 4.87234L12.017 7.22144L12.9324 4.86075C13.5944 3.15341 15.2525 1 18 1C20.5984 1 23 3.59737 23 7.2C23 8.81306 22.3452 10.6154 21.2616 12.4693C20.1856 14.3105 18.7363 16.1188 17.2634 17.7227C15.7938 19.3231 14.3208 20.6984 13.2137 21.6745C12.7262 22.1043 12.311 22.4555 12 22.7132C11.689 22.4555 11.2738 22.1043 10.7863 21.6745C9.67917 20.6984 8.20619 19.3231 6.73656 17.7227C5.26371 16.1188 3.81442 14.3105 2.73836 12.4693C1.65483 10.6154 1 8.81306 1 7.2C1 3.59737 3.40155 1 6 1C6.96462 1 8.02674 1.43977 8.98392 2.19218C9.93742 2.94171 10.6932 3.92992 11.0722 4.87234Z"
        strokeWidth="2"
      />{' '}
    </svg>
  ),
  /*lightning*/ 102: (
    <svg className="svg_shape" width="20" color="currentColor" height="20" viewBox="0 0 20 20">
      <path
        d="M1.83804 11.5014L11.5286 1.35595L10.4196 6.03764C10.122 7.29381 11.0748 8.49863 12.3657 8.49863L18.9063 8.49863L9.17095 18.6561L10.2828 13.9624C10.5803 12.7062 9.62758 11.5014 8.33665 11.5014H1.83804Z"
        strokeWidth="2"
      />{' '}
    </svg>
  ),
  /*leaf*/ 104: (
    <svg className="svg_shape" width="23" height="24" viewBox="0 0 23 24">
      <path
        d="M1 22.5C1 10.9611 10.0901 1.54523 21.5 1.02284V1.5C21.5 13.0389 12.4099 22.4548 1 22.9772V22.5Z"
        strokeWidth="2"
      />{' '}
    </svg>
  ),
};
export default ProjectPanelBlock;
