import { createApi } from '@reduxjs/toolkit/query/react';
import { appBaseQueryWithResend } from '@app/store/api/base-query';
import { serverPath } from '@app/utils/server-path';
import { CommonResponse } from '@app/types/common.type';
import { Blog } from '@app/types/blog.type';

const commonApi = createApi({
  reducerPath: 'commonApi',
  tagTypes: ['FEED'],
  baseQuery: appBaseQueryWithResend,
  endpoints: (build) => ({
    feed: build.query<CommonResponse<Array<Blog>>, null>({
      query: () => ({
        url: `${serverPath.feed}`,
      }),
      providesTags: ['FEED'],
    }),
  }),
});

export const { useLazyFeedQuery } = commonApi;
export default commonApi;
