import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';

import { setGlobalRequest } from './slice/common.slice';

const globalRequestBlocker = ({
  dispatch,
  status = true,
}: {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* eslint-disable-next-line "@typescript-eslint/no-explicit-any" */
  dispatch: ThunkDispatch<any, any, UnknownAction>;
  status?: boolean;
}) => {
  dispatch(setGlobalRequest({ requesting: status }));
};

export default globalRequestBlocker;
