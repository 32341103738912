import React from 'react';

const ArrowRightIcon = ({size}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 36 36"
        >
            <path
                fill="currentColor"
                d="M14.115 32.115l3.77 3.77L35.771 18 17.885.115l-3.77 3.77 11.448 11.448H0v5.334h25.563L14.115 32.115z"
            ></path>
        </svg>
    );
};

export default ArrowRightIcon;