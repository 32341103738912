import React from 'react';

const X2Icon = ({size}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 12 12"
        >
            <path
                fill="currentColor"
                d="M10.192.344L5.949 4.586 1.707.344.293 1.758 4.535 6 .293 10.242l1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L7.364 6l4.242-4.242L10.192.344z"
            ></path>
        </svg>
    );
};

export default X2Icon;