export default function useSendOptionsBuild() {
  return ({ slide, setup }) => {
    const formData = new FormData();
    for (const [styleFieldKey, styleFieldValue] of Object.entries(setup)) {
      formData.set(styleFieldKey, styleFieldValue);
    }
    if (slide) {
      if (typeof slide.to !== 'undefined') {
        formData.append('index', slide.to);
        if (typeof slide.text === 'object') {
          formData.append('marked_text', JSON.stringify(slide.text));
        } else {
          formData.append('text', String(slide.text ?? ''));
        }
      }
    }

    return formData;
  };
}
