//Автоматическая сборка всех p|s.css файлов с проекта и подгрузка их через модули
import '../css/style.css';

const files = await import.meta.webpackContext('../', {
  regExp: /^(?!.*\/trash\/).*\.((p|s)?css)$/,
  mode: 'eager',
});

const priorityOrder = [];

const allFiles = files.keys();

const prioritizedStyles = priorityOrder.filter((name) => allFiles.includes(name));

const otherStyles = allFiles.filter((path) => !prioritizedStyles.includes(path));

const orderedFiles = [...prioritizedStyles, ...otherStyles];

const styles = await Promise.all(orderedFiles.map((path) => files(path)));

export { styles };
