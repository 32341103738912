import { Outlet, useOutletContext } from 'react-router';
import PropTypes from 'prop-types';
import { Header } from '@app/components/template';
import type { CommonOutletContext } from '@app/pages/common.outlet';

const DefaultLayout = () => {
  const context = useOutletContext<CommonOutletContext>();
  return (
    <>
      <Header isTransparent={false} />
      <main className="min-h-[calc(100vh-100px)]">
        <Outlet context={context} />
      </main>
    </>
  );
};

DefaultLayout.prototype = {
  context: PropTypes.object,
};

export default DefaultLayout;
