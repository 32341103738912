import React, { useEffect, useState } from 'react';

import t from '@lib/lng';

const SlideLoaderBlock = (props) => {
  const { slideIndex } = props;
  const loaderItems = [1, 2, 3, 4, 5, 6, 7];
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    const changeItem = setInterval(() => {
      setCurrent((prevState) => (prevState === 7 ? 1 : prevState + 1));
    }, 3500);
    return () => {
      clearInterval(changeItem);
    };
  }, []);

  return (
    <div className="create_slide">
      <div className="create_slide_header">
        <div className="create_slide_number">
          {t.slide} {Number(slideIndex + 1)}
        </div>
      </div>
      <div className="create_slide_inner">
        <div className={'gen_result__loader item' + current}>
          {loaderItems.map((item) => (
            <div className="gen_result__loader_item" key={item}>
              <img
                className="gen_result__loader_icon"
                src={'/static/i/gif/processing_icon' + item + '.gif'}
              />
              <div className="gen_result__loader_title">{t['resultLoaderTitle' + item]}</div>
              <div className="gen_result__loader_text">{t['resultLoaderText' + item]}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SlideLoaderBlock;
