import React, {useState} from 'react';

const DeleteModal = ({close, deleteElement, type}) => {
	const [deleting, setDeleting] = useState(false);

	const onClickDelete = () =>{
		setDeleting(true);
		deleteElement();
	}

	return(
		<div className="modal">
			<div className="modal_overlay" onClick={close}></div>
			<div className="files_modal files_modal-plain">
				<div className="modal_close" onClick={close}>{svgIcons.close}</div>
				<div className="files_modal_title">{svgIcons.delete} Delete this {type === 'slide' ? 'slide' : 'presentation'}?</div>
				<div className="files_modal_text">It will not be possible to restore it, but you can create a new one</div>
				<div className={'files_modal_btns' + (deleting ? ' disabled' : '')}>
					<div className="files_modal_btn btn-outlined" onClick={onClickDelete}>Yes, delete</div>
					<div className="files_modal_btn gen_btn_main" onClick={close}>Cancel</div>
				</div>
			</div>
		</div>
	);
}

const svgIcons = {
	delete: <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M5 20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V8H21V6H17V4C17 3.46957 16.7893 2.96086 16.4142 2.58579C16.0391 2.21071 15.5304 2 15 2H9C8.46957 2 7.96086 2.21071 7.58579 2.58579C7.21071 2.96086 7 3.46957 7 4V6H3V8H5V20ZM9 4H15V6H9V4ZM8 8H17V20H7V8H8Z"/><path d="M9 10H11V18H9V10ZM13 10H15V18H13V10Z"/></svg>,
	close: <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor"><path d="M10.192 0.343994L5.94897 4.58599L1.70697 0.343994L0.292969 1.75799L4.53497 5.99999L0.292969 10.242L1.70697 11.656L5.94897 7.41399L10.192 11.656L11.606 10.242L7.36397 5.99999L11.606 1.75799L10.192 0.343994Z"/></svg>,
};

export default DeleteModal;