import React, {useState, useRef, useEffect} from 'react';

const Main3_slider4 = ({isActive}) => {
	const [rendered, setRendered] = useState(false);
	const [animClass, setAnimClass] = useState('');
	const [show, setShow] = useState(false);
	const imgRef = useRef(null);

	const animate = () => {
		setRendered(true);
		setShow(true);
		setTimeout(() => {
			setAnimClass('a1');
		}, 200);
	}

	useEffect(() => {
		if (isActive && !rendered) animate();
	}, [isActive]);

	return(
		<div className={'main3_slide_img main3_slide_img4 ' + animClass} ref={imgRef}>
			{
				show &&
				<svg width="600" height="512" viewBox="0 0 600 512" fill="none" xmlns="http://www.w3.org/2000/svg">
					<foreignObject x="0" y="0" width="600" height="512">
						<img srcSet="/static/i/main3_slide4_1.webp, /static/i/main3_slide4_1@2x.webp 2x" loading="lazy" />
					</foreignObject>
					<g className="el2" style={{mixBlendMode: 'lighten'}}>
						<path d="M419.143 434.543C409.915 434.535 400.695 433.978 391.532 432.874C343.565 427.118 300.155 406.992 269.286 376.193C238.417 345.394 223.657 307.41 228.004 269.456C231.851 235.909 250.429 204.42 280.328 180.796C318.922 150.307 371.266 136.447 424.03 142.822C447.918 145.631 471.139 152.555 492.663 163.288C512.697 173.203 530.85 186.533 546.307 202.681C561.296 218.461 572.549 235.919 579.714 254.576C587.119 273.853 589.707 293.369 587.558 312.596C585.674 328.885 580.343 344.586 571.919 358.655C563.297 372.937 552.118 385.508 538.942 395.74C520.574 410.25 497.75 421.322 472.907 427.768C455.345 432.294 437.279 434.571 419.143 434.543ZM398.957 144.081C355.617 144.081 314.015 157.742 282.037 182.995C252.727 206.159 234.52 236.958 230.762 269.776C226.515 306.86 240.885 343.945 271.235 374.214C301.584 404.484 344.494 424.4 391.832 430.086C446.525 436.652 499.509 423.331 537.213 393.541C550.106 383.517 561.041 371.203 569.471 357.216C577.707 343.486 582.913 328.153 584.74 312.247C586.889 293.479 584.311 274.402 577.066 255.555C570.07 237.238 558.988 220.09 544.248 204.59C529.011 188.684 511.123 175.55 491.384 165.777C470.161 155.186 447.265 148.346 423.71 145.56C415.496 144.577 407.23 144.083 398.957 144.081Z" fill="url(#paint1_linear_2281_100)"/>
					</g>
					<g className="el1">
						<path d="M425.939 304.152H343.465L324.778 354.398H317.962L380.919 186.223H388.544L451.501 354.398H444.696L425.939 304.152ZM423.62 297.916L384.737 193.728L345.763 297.916H423.62Z" fill="url(#paint2_linear_2281_100)"/>
						<path d="M484.439 185.813C485.051 186.404 485.536 187.114 485.864 187.9C486.192 188.685 486.357 189.529 486.347 190.38C486.361 191.223 486.198 192.059 485.869 192.835C485.541 193.611 485.053 194.31 484.439 194.887C483.841 195.484 483.13 195.955 482.347 196.272C481.565 196.59 480.726 196.747 479.882 196.736C479.046 196.741 478.217 196.58 477.443 196.263C476.67 195.945 475.967 195.477 475.376 194.886C474.785 194.295 474.317 193.592 473.999 192.818C473.682 192.045 473.521 191.216 473.526 190.38C473.513 189.534 473.67 188.694 473.988 187.909C474.305 187.125 474.777 186.412 475.375 185.813C475.951 185.198 476.649 184.71 477.426 184.383C478.203 184.056 479.039 183.896 479.882 183.914C480.731 183.903 481.573 184.065 482.357 184.392C483.141 184.718 483.849 185.202 484.439 185.813ZM476.764 354.398V229.423H483V354.398H476.764Z" fill="url(#paint2_linear_2281_100)"/>
					</g>
					<defs>
					<radialGradient id="paint0_radial_2281_100" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(367.598 -154.564) scale(144.457 3.31133)">
					<stop stopColor="#18AB86" stopOpacity="0.4"/>
					<stop offset="0.29" stopColor="#409D8F" stopOpacity="0.31"/>
					<stop offset="0.87" stopColor="#A87AA5" stopOpacity="0.06"/>
					<stop offset="1" stopColor="#C271AB" stopOpacity="0"/>
					</radialGradient>
					<linearGradient id="paint1_linear_2281_100" x1="368.624" y1="429.514" x2="460.869" y2="149.777" gradientUnits="userSpaceOnUse">
						<stop offset="0" stopColor="#fff">
							<animate attributeName="stop-color" values="#1E2B5F;#fff;" dur="1s" repeatCount="1" fill="freeze" />
						</stop>
						<stop offset="0.5" stopColor="#fff">
							<animate attributeName="stop-color" values="#fff;#1E2B5F;#fff" dur="1.5s" repeatCount="1" fill="freeze" />
						</stop>
						<stop offset="1" stopColor="#fff">
							<animate attributeName="stop-color" values="#fff;#fff;#1E2B5F;#fff" dur="2s"  repeatCount="1" fill="freeze" />
						</stop>

					</linearGradient>
					<linearGradient id="paint2_linear_2281_100" x1="287.333" y1="165.937" x2="453.28" y2="428.817" gradientUnits="userSpaceOnUse">
						<stop offset="0" stopColor="#fff">
							<animate attributeName="stop-color" values="#fff;#fff;#fff;#fff;#0099FF;#fff;" dur="3s" repeatCount="1" fill="freeze" />
						</stop>
						<stop offset="0.5" stopColor="#fff">
							<animate attributeName="stop-color" values="#fff;#fff;#fff;#fff;#fff;#0099FF;#fff" dur="3.5s" repeatCount="1" fill="freeze" />
						</stop>
						<stop offset="1" stopColor="#fff">
							<animate attributeName="stop-color" values="#fff;#fff;#fff;#fff;#fff;#fff;#0099FF;#fff" dur="4s"  repeatCount="1" fill="freeze" />
						</stop>
					</linearGradient>
					<linearGradient id="paint3_linear_2281_100" x1="325.108" y1="80.7348" x2="520.175" y2="318.282" gradientUnits="userSpaceOnUse">
					<stop offset="0" stopColor="#0E47C0">
						<animate attributeName="stop-color" values="#0E47C0;white;#0E47C0" dur="2s" repeatCount="1" fill="freeze" />
					</stop>
					<stop offset="1" stopColor="#0E47C0">
						<animate attributeName="stop-color" values="white;#0E47C0;white" dur="2s" repeatCount="1" fill="freeze" />
					</stop>
					</linearGradient>
					</defs>
				</svg>
			}
		</div>
	);
}
export default Main3_slider4;