import { createApi } from '@reduxjs/toolkit/query/react';

import { serverPath } from '@app/utils/server-path';

import { appBaseQuery } from './base-query';

const presetApi = createApi({
  reducerPath: 'presetApi',
  baseQuery: appBaseQuery(),
  tagTypes: ['PRESET'],
  endpoints: (build) => ({
    deletePreset: build.query({
      query: ({ presetId }: { presetId: string }) => ({
        url: `${serverPath.preset.deletePreset}${presetId}`,
      }),
    }),
    setColor: build.mutation({
      query: (body: { color: string; 'text-color': Array<string> }) => ({
        url: `${serverPath.setColor}`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useLazyDeletePresetQuery, useDeletePresetQuery, useSetColorMutation } = presetApi;

export default presetApi;
