import React from 'react';

const ArrowIcon = ({ size }: { size: number }) => {
  return (
    <svg width={size} viewBox="0 0 12 8" fill="currentColor">
      <path d="M0.292969 6.293L1.70697 7.707L5.99997 3.414L10.293 7.707L11.707 6.293L5.99997 0.585999L0.292969 6.293Z" />
    </svg>
  );
};

export default ArrowIcon;
