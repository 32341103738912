import React from 'react';

const UploadIcon = ({ size }: { size: number }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor">
      <path d="M11 15H13V9H16L12 4L8 9H11V15Z" />
      <path d="M20 18H4V11H2V18C2 19.103 2.897 20 4 20H20C21.103 20 22 19.103 22 18V11H20V18Z" />
    </svg>
  );
};

export default UploadIcon;
