import React from 'react';
import PropTypes from "prop-types";
import Icon from "../icon";

const Modal = (props) => {
    const { title, children, onClose, isOpen } = props;
    return (
        <>{ isOpen && <div className="ui_modal" >
            <div className="ui_modal_background" onClick={() => onClose?.()} />
            <div className="ui_modal__container">
                <div className="ui_modal__close" onClick={() => onClose?.()}>
                    <Icon name="X2Icon" size={11}/>
                </div>
                <div className="ui_modal__wrapper">
                    <h3>{title}</h3>
                    <div>
                        {children}
                    </div>
                </div>
            </div>
        </div>}
        </>
    );
};

Modal.prototype = {
    title: PropTypes.string,
    children: PropTypes.element,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
}

export default Modal;